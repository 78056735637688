export enum View {
  actions = "actions",
  journeys = "journeys",
  missions = "missions",
  impact = "impact",
}

export enum BodySliceType {
  BodyTextSlice = "BodyTextSlice",
  BodyImageSlice = "BodyImageSlice",
  BodyQuoteSlice = "BodyQuoteSlice",
  BodyEmbedSlice = "BodyEmbedSlice",
}

export enum FootprintSector {
  home = "home",
  shopping = "shopping",
  transport = "transport",
  food = "food",
}

// missions
export enum MissionType {
  ACT = "ACT",
  LEARN = "LEARN",
  MEASURE = "MEASURE",
  SURVEY = "SURVEY",
  CHECKIN = "CHECKIN",
  TICKET = "TICKET",
}

export enum MissionSubType {
  ALL = "ALL",
  ENERGY = "ENERGY",
  FOOD = "FOOD",
  MOBILITY = "MOBILITY",
  PURCHASE = "PURCHASE",
  SOCIAL = "SOCIAL",
  TRAVEL = "TRAVEL",
  WASTE = "WASTE",
  ENVIRONMENT = "ENVIRONMENT",
  WATER = "WATER",
  JOURNEY = "JOURNEY",
  DAILYEPISODE = "DAILYEPISODE",
  TYPEFORM_BASE = "TYPEFORM_BASE",
  TYPEFORM_REFINE = "TYPEFORM_REFINE",
  FIXED = "FIXED",
  CHECKIN = "CHECKIN",
}

export enum MissionPeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  CUSTOM = "CUSTOM",
}

export enum EventStatus {
  LOCKED = "LOCKED",
  HIGHLIGHTED = "HIGHLIGHTED",
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
}

export enum OrderStatus {
  IDLE = "IDLE",
  PENDING = "PENDING",
  PAYED = "PAYED",
  FULFILLED = "FULFILLED",
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  REFUNDED = "REFUNDED",
  ERROR = "ERROR",
}
