import { Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerLogoAlt from "../../assets/images/partner-logo.svg"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import AvatarButton from "../settings/common/AvatarButton"
import prizesImage from "../../assets/images/prizes.png"
import prizesDesktopImage from "../../assets/images/prizes-desktop.png"

const AvatarSelectionMobile = ({
  selectedAvatar,
  setSelectedAvatar,
  currentPage,
  setCurrentPage,
  height,
  continueButtonWidth,
}: {
  selectedAvatar: string
  setSelectedAvatar: Dispatch<SetStateAction<string>>
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const {
    windowWidth,
    windowHeight,
    isMobile,
    avatars,
    changeAvatar,
    setViewAvatarSelection,
  } = useContext(MainContext)

  // loading for team selection
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      {currentPage === 0 ? (
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: windowHeight,
            minHeight: 560,
            overflowY: "scroll",
            paddingTop: 26,
            backgroundColor: "#F8F3E9",
            position: "relative",
          }}
        >
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <img
                src={partnerLogoAlt}
                style={{ height: 35 }}
                alt={t("partner_logo_alt")}
              />
            </FadeFromTop>
            <FadeFromTop
              delay={0.05}
              style={{
                width: "100%",
                marginTop: 30,
                paddingInline: isMobile ? 16 : 60,
              }}
            >
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
              >
                <Trans i18nKey="win_prizes" />
              </Title>
            </FadeFromTop>
            <FadeFromTop
              delay={0.1}
              style={{
                marginTop: 12,
                paddingInline: isMobile ? 16 : 60,
              }}
            >
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                fontWeight={500}
                component="h2"
                style={{ maxWidth: 350 }}
              >
                <Trans i18nKey="win_prizes_description" />
              </Text>
            </FadeFromTop>
            <FadeFromTop
              delay={0.15}
              style={{
                marginTop: 68,
                paddingInline: isMobile ? 16 : 60,
              }}
            >
              <div
                style={{
                  fontSize: isMobile ? 18 : 20,
                  fontWeight: 300,
                  marginLeft: -40,
                }}
                dangerouslySetInnerHTML={{ __html: t("win_prizes_list") }}
              />
            </FadeFromTop>
            {windowWidth < 500 ? (
              <img
                src={prizesImage}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  marginTop: 22,
                }}
                alt=""
              />
            ) : (
              <img
                src={prizesDesktopImage}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  bottom: 0,
                }}
                alt=""
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: windowHeight,
            minHeight: 550,
            overflowY: "scroll",
            paddingTop: 26,
          }}
        >
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
              paddingInline: isMobile ? 16 : 60,
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <img
                src={partnerLogoAlt}
                style={{ height: 35 }}
                alt={t("partner_logo_alt")}
              />
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
              >
                {t("your_avatar")}
              </Title>
            </FadeFromTop>
            <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                fontWeight={500}
                component="h2"
              >
                {t("choose_your_avatar")}
              </Text>
            </FadeFromTop>
            <FadeFromTop
              delay={0.15}
              style={{
                width: "100%",
                maxWidth: onboardingElementsMaxWidth,
                marginTop: 60,
              }}
            >
              {isMobile ? (
                <Grid
                  container
                  columns={3}
                  columnSpacing="16px"
                  rowSpacing="16px"
                  style={{ paddingInline: 16, marginBottom: 110 }}
                >
                  {avatars.map((avatar, index) => (
                    <Grid key={index} item xs={1}>
                      <FadeFromTop delay={index * 0.025}>
                        <AvatarButton
                          avatar={avatar}
                          selected={selectedAvatar === avatar.url}
                          current={false}
                          disabled={loading}
                          onClick={() => {
                            setSelectedAvatar(avatar.url)
                          }}
                        />
                      </FadeFromTop>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: colors.backgroundWhite,
                    borderRadius: 16,
                    boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                    paddingTop: 22,
                    paddingBottom: 22,
                    paddingInline: 28,
                  }}
                >
                  <Title fontSize={20} component="h2">
                    {t("avatar")}
                  </Title>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(0, 5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={false}
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={false}
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
            </FadeFromTop>
          </Stack>
        </Stack>
      )}
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "100%",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          loading={loading}
          title={currentPage === 0 ? t("continue") : t("lets_start_simple")}
          width={continueButtonWidth ?? "calc(100% - 32px)"}
          onClick={async () => {
            if (currentPage === 0) {
              setCurrentPage(1)
            } else {
              setLoading(true)

              await changeAvatar(selectedAvatar)

              setViewAvatarSelection(false)
            }
          }}
        >
          {currentPage === 0 ? t("continue") : t("lets_start_simple")}
        </Button>
      </FadeFromBottom>
    </Stack>
  )
}

export default AvatarSelectionMobile
