import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import FootprintMobile from "../../components/footprint/FootprintMobile"
import FootprintDesktop from "../../components/footprint/FootprintDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Footprint = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("footprint")
  }, [])

  return isMobile ? <FootprintMobile /> : <FootprintDesktop />
}

export default Footprint
