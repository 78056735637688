import FadeFromTop from "../../components/animations/FadeFromTop"
import Center from "../../components/global/common/Center"
import Text from "../../components/global/common/Text"
import { colors } from "../../services/config/colors"
import partnerLogo from "../../assets/images/partner-logo.svg"
import { Stack } from "@mui/material"
import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { useTranslation } from "react-i18next"
import Button from "../../components/global/common/Button"

const TicketError = () => {
  const { t } = useTranslation()
  const {
    isMobile,
    setVisualizingErrorPage,
    setTicketError,
    setLoading,
    setViewOnboarding,
    setViewAmplifySignIn,
    setViewTicketSelection,
    setViewAvatarSelection,
  } = useContext(MainContext)

  // tell other components this page is being visualized
  useEffect(() => {
    setVisualizingErrorPage(true)

    return () => {
      setVisualizingErrorPage(false)
    }
  }, [])

  return (
    <Center
      style={{
        backgroundColor: colors.backgroundWhite,
        paddingInline: 16,
        position: "relative",
      }}
    >
      <FadeFromTop>
        <img
          src={partnerLogo}
          style={{ width: isMobile ? 120 : 128 }}
          alt={t("aworld_logo_alt")}
        />
      </FadeFromTop>
      <FadeFromTop delay={0.025} style={{ marginTop: 36 }}>
        <Stack alignItems="center">
          <Text
            fontSize={isMobile ? 20 : 22}
            fontWeight={600}
            textAlign="center"
          >
            {t("sign_in_warning")}
          </Text>
        </Stack>
      </FadeFromTop>
      <FadeFromTop
        delay={0.05}
        className="center"
        style={{ width: "100%", marginTop: 32 }}
      >
        <Button
          title={t("try_again")}
          style={{ maxWidth: 330 }}
          onClick={() => {
            localStorage.clear()
            setTicketError(false)
            setLoading(false)
            setViewOnboarding(true)
            setViewAmplifySignIn(true)
            setViewTicketSelection(true)
            setViewAvatarSelection(true)
          }}
        >
          {t("go_back")}
        </Button>
      </FadeFromTop>
    </Center>
  )
}

export default TicketError
