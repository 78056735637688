import { Stack } from "@mui/material"
import Product from "../../../models/product"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"
import { currency } from "../../../services/config/constants"
import { useMemo } from "react"
import { t } from "i18next"
import { OrderStatus } from "../../../services/config/enum"

const TransactionCardDesktop = ({
  product,
  updatedAt,
  status,
}: {
  product: Product
  updatedAt: string
  status: OrderStatus
}) => {
  // redeem date
  const date = useMemo(() => {
    if (new Date(updatedAt).toDateString() === new Date().toDateString()) {
      return t("today").toLowerCase()
    } else if (
      new Date(updatedAt).toDateString() ===
      new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
    ) {
      return t("yesterday").toLowerCase()
    }

    return t("on_date") + " " + new Date(updatedAt).toLocaleDateString()
  }, [updatedAt])

  // current order status
  const orderStatus: "processing" | "error" | "completed" | "refunded" =
    useMemo(() => {
      if (
        status === OrderStatus.IDLE ||
        status === OrderStatus.PENDING ||
        status === OrderStatus.PAYED ||
        status === OrderStatus.FULFILLED ||
        status === OrderStatus.CONFIRMED
      ) {
        return "processing"
      } else if (status === OrderStatus.ERROR) {
        return "error"
      } else if (status === OrderStatus.REFUNDED) {
        return "refunded"
      }

      return "completed"
    }, [status])

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{ width: "100%", height: 140, gap: 30, position: "relative" }}
    >
      <img
        src={product.image}
        style={{
          width: 60,
          height: 60,
          borderRadius: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
        alt=""
      />
      <Stack gap={1}>
        <Text fontSize={20} lineHeight="28px" fontWeight={400}>
          {product.document.title}
        </Text>
        <Text fontSize={18} lineHeight="25px" fontWeight={400} color="#EB5F11">
          {orderStatus === "completed"
            ? t("prize_obtained", {
                date: date,
                time: new Date(updatedAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              })
            : orderStatus === "error"
            ? `${t("status")}: ${t("error").toLowerCase()}`
            : orderStatus === "refunded"
            ? `${t("status")}: ${t("refunded")}`
            : `${t("status")}: ${t("processing")}`}
        </Text>
      </Stack>
      <PointsBadge
        points={
          product.prices.find((price) => price.currency === currency)!.amount
        }
        style={{ position: "absolute", right: 0 }}
      />
    </Stack>
  )
}

export default TransactionCardDesktop
