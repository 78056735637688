import { useContext, useEffect, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import WalletMobile from "../../components/store/WalletMobile"
import WalletDesktop from "../../components/store/WalletDesktop"
import { MarketplaceContext } from "../../controllers/marketplace"
import { OrderStatus } from "../../services/config/enum"

const Wallet = () => {
  const { isMobile } = useContext(MainContext)
  const { orders, getOrders } = useContext(MarketplaceContext)

  // vechain info alert
  const [vechainAlertOpen, setVechainAlertOpen] = useState<boolean>(false)

  // start polling if there are orders not fulfilled yet
  const intervalId = useRef<any>()

  useEffect(() => {
    if (
      orders.some(
        (order) =>
          order.status !== OrderStatus.COMPLETED &&
          order.status !== OrderStatus.ERROR &&
          order.status !== OrderStatus.REFUNDED
      )
    ) {
      getOrders()
      intervalId.current = setInterval(() => {
        getOrders()
      }, 6000)
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [])

  useEffect(() => {
    if (
      !orders.some(
        (order) =>
          order.status !== OrderStatus.COMPLETED &&
          order.status !== OrderStatus.ERROR &&
          order.status !== OrderStatus.REFUNDED
      ) &&
      intervalId.current
    ) {
      clearInterval(intervalId.current)
    }
  }, [orders])

  return isMobile ? (
    <WalletMobile
      vechainAlertOpen={vechainAlertOpen}
      setVechainAlertOpen={setVechainAlertOpen}
    />
  ) : (
    <WalletDesktop
      vechainAlertOpen={vechainAlertOpen}
      setVechainAlertOpen={setVechainAlertOpen}
    />
  )
}

export default Wallet
