import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import pointsStarIcon from "../../assets/icons/points-star.svg"
import rocketIcon from "../../assets/icons/rocket.svg"
import trophyIconWhite from "../../assets/icons/trophy-white.svg"
import arrowRightIconWhite from "../../assets/icons/arrow-right-white.svg"
import FadeFromTop from "../animations/FadeFromTop"
import { useContext } from "react"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  italianFootprintAverage,
  navBarHeight,
} from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import PointsHistoryCardDesktop from "./desktop/PointsHistoryCardDesktop"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"
import { FootprintContext } from "../../controllers/footprint"
import settingsIcon from "../../assets/icons/settings.svg"
import exitIconPrimary from "../../assets/icons/exit-primary.png"
import { MainContext } from "../../controllers/main"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import { MissionSubType } from "../../services/config/enum"

const PerformanceDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, setViewTutorial, setCurrentMission } = useContext(MainContext)
  const { missions, completedMissionsCount } = useContext(MissionsContext)
  const { userFootprint, formsList } = useContext(FootprintContext)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("performance") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("performance")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("monitor_your_progress")}
              </Text>
            </AppearFromSide>
            <Appear style={{ position: "absolute", right: 0 }} fade>
              <AvatarInfoButton
                title={t("view_tutorial")}
                onClick={() => {
                  scrollWindowToTop()
                  navigate("/")
                  setViewTutorial(true)
                }}
              />
            </Appear>
          </Stack>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: colors.background,
              marginTop: 40,
              borderRadius: 16,
              padding: 60,
              gap: 13,
            }}
          >
            {/* first column */}
            <Stack
              style={{
                width: "30%",
                minWidth: "30%",
                maxWidth: "30%",
                height: "auto",
                position: "relative",
              }}
            >
              <FadeFromTop>
                <div
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: "100%",
                    backgroundImage: `url(${
                      user!.profileImage
                    }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                    backgroundSize: "105%, 100%",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                />
              </FadeFromTop>
              <FadeFromTop delay={0.025} style={{ marginTop: 14 }}>
                <Title
                  fontSize={30}
                  lineHeight="38px"
                  style={{
                    width: "90%",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                >
                  {user?.first_name} {user?.last_name}
                </Title>
              </FadeFromTop>
              <FadeFromTop delay={0.05} style={{ marginTop: 10 }}>
                <Text fontSize={18} lineHeight="25px" fontWeight={400}>
                  {t("user_active_since")} {user?.active_since}
                </Text>
              </FadeFromTop>
              <FadeFromTop
                delay={0.05}
                style={{ position: "absolute", bottom: 0 }}
              >
                <Stack direction="row" style={{ gap: 16 }}>
                  <ButtonBase
                    disableRipple
                    aria-label={t("settings")}
                    onClick={() => {
                      scrollWindowToTop()
                      navigate("/performance/settings")
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ height: 28, gap: 11 }}
                    >
                      <img
                        src={settingsIcon}
                        style={{ width: 24, height: 24 }}
                        alt=""
                      />
                      <Title
                        fontSize={20}
                        lineHeight="28px"
                        color={colors.primary}
                        style={{ textDecoration: "underline" }}
                      >
                        {t("settings")}
                      </Title>
                    </Stack>
                  </ButtonBase>
                  <ButtonBase
                    disableRipple
                    aria-label={t("settings")}
                    onClick={() => {
                      localStorage.clear()
                      navigate("/")
                      window.location.reload()
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ height: 28, gap: 11 }}
                    >
                      <img
                        src={exitIconPrimary}
                        style={{ width: 24, height: 24 }}
                        alt=""
                      />
                      <Title
                        fontSize={20}
                        lineHeight="28px"
                        color={colors.primary}
                        style={{ textDecoration: "underline" }}
                      >
                        {t("exit")}
                      </Title>
                    </Stack>
                  </ButtonBase>
                </Stack>
              </FadeFromTop>
            </Stack>
            {/* second column */}
            <Stack
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                gap: 30,
              }}
            >
              <FadeFromTop style={{ width: "100%" }}>
                <ButtonBase
                  disabled={
                    !userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    if (
                      formsList.length &&
                      formsList.some((item) => !item.isRefine)
                    ) {
                      const missionToSet = missions.find(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      )!
                      setCurrentMission(missionToSet)
                      localStorage.setItem(
                        "currentMission",
                        JSON.stringify(missionToSet)
                      )
                      navigate("/performance/footprint/form")
                    } else {
                      navigate("/performance/footprint")
                    }
                  }}
                  aria-label={t("your_carbon_footprint")}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: 92,
                      borderRadius: 16,
                      backgroundColor: colors.backgroundWhite,
                      paddingLeft: 22,
                      gap: 22,
                      position: "relative",
                    }}
                  >
                    <img
                      src={footprintDoughnutImage}
                      style={{ height: 58 }}
                      alt=""
                    />
                    <Stack style={{ gap: 10 }}>
                      <Title fontSize={20} lineHeight="24px">
                        {t("your_carbon_footprint")}
                      </Title>
                      {!userFootprint &&
                      !missions.some(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      ) ? (
                        <div style={{ height: 19 }} />
                      ) : (
                        <Text fontSize={16} fontWeight={400}>
                          {userFootprint
                            ? `${Math.abs(
                                Math.round(
                                  ((userFootprint.footprint -
                                    italianFootprintAverage) /
                                    ((userFootprint.footprint +
                                      italianFootprintAverage) /
                                      2)) *
                                    100
                                )
                              )}% ${
                                userFootprint.footprint >
                                italianFootprintAverage
                                  ? t("above_average")
                                  : t("below_average")
                              }`
                            : t("answer_the_survey")}
                        </Text>
                      )}
                    </Stack>
                    <Stack
                      justifyContent="flex-end"
                      style={{
                        position: "absolute",
                        right:
                          !userFootprint &&
                          !missions.some(
                            (mission) =>
                              mission.missionSubType ===
                              MissionSubType.TYPEFORM_BASE
                          )
                            ? 28
                            : 85,
                      }}
                    >
                      <Title
                        fontSize={26}
                        lineHeight="30px"
                        color={colors.primary}
                        textAlign="right"
                      >
                        {userFootprint
                          ? userFootprint.footprint.toString().replace(".", ",")
                          : "--"}
                      </Title>
                      <Text fontSize={16} lineHeight="30px" fontWeight={400}>
                        {t("tons")} CO2e
                      </Text>
                    </Stack>
                    {!userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    ) ? null : (
                      <img
                        src={chevronRightPrimaryIcon}
                        style={{ height: 20, position: "absolute", right: 28 }}
                        alt=""
                      />
                    )}
                  </Stack>
                </ButtonBase>
              </FadeFromTop>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 160,
                  gap: desktopColumnsGap,
                }}
              >
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={pointsStarIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {user?.points}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      style={{ marginTop: 5 }}
                    >
                      {t("points_extended")}
                    </Text>
                  </Stack>
                </FadeFromTop>
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={rocketIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {completedMissionsCount}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      textAlign="center"
                      style={{ marginTop: 5, width: 100 }}
                    >
                      {completedMissionsCount === 1
                        ? t("completed_mission")
                        : t("completed_missions")}
                    </Text>
                  </Stack>
                </FadeFromTop>
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <ButtonBase
                    style={{ width: "100%", height: "100%", borderRadius: 16 }}
                    onClick={() => {
                      scrollWindowToTop()
                      navigate("/store")
                    }}
                  >
                    <Stack
                      alignItems="center"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 16,
                        border: "1px solid " + colors.primary,
                        backgroundColor: colors.primary,
                      }}
                    >
                      <img
                        src={trophyIconWhite}
                        style={{ height: 30, marginTop: 21 }}
                        alt=""
                      />
                      <Text
                        fontSize={16}
                        lineHeight="16px"
                        fontWeight={500}
                        color={colors.textWhite}
                        textAlign="center"
                        style={{ marginTop: 23 }}
                      >
                        Usa i tuoi Punti nello Store!
                      </Text>
                      <img
                        src={arrowRightIconWhite}
                        style={{ height: 18, marginTop: 20 }}
                        alt=""
                      />
                    </Stack>
                  </ButtonBase>
                </FadeFromTop>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* performance and leaderboard section */}
      <PaddingContainerDesktop>
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            height: "auto",
            marginTop: 60,
            marginBottom: 62,
          }}
        >
          {/* performance */}
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "48%",
              minWidth: "48%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("missions")}
                </Title>
              </Stack>
              <PointsHistoryCardDesktop />
              {completedMissionsCount > 5 ? (
                <ButtonBase
                  style={{
                    width: 90,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/missions")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default PerformanceDesktop
