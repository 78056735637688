import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { Stack } from "@mui/material"
import AmplifySignInMobile from "./AmplifySignInMobile"

const AmplifySignInDesktop = ({
  loading,
  setLoading,
  code,
  setCode,
  errorAlertOpen,
  setErrorAlertOpen,
  callConfirmCode,
}: {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  code: string
  setCode: Dispatch<SetStateAction<string>>
  errorAlertOpen: boolean
  setErrorAlertOpen: Dispatch<SetStateAction<boolean>>
  callConfirmCode: () => void
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <AmplifySignInMobile
        loading={loading}
        setLoading={setLoading}
        code={code}
        setCode={setCode}
        errorAlertOpen={errorAlertOpen}
        setErrorAlertOpen={setErrorAlertOpen}
        callConfirmCode={callConfirmCode}
        height="100%"
        itemsWidth={400}
        continueButtonWidth={330}
      />
    </Stack>
  )
}

export default AmplifySignInDesktop
