import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import AmplifySignInMobile from "../../components/onboarding/AmplifySignInMobile"
import AmplifySignInDesktop from "../../components/onboarding/AmplifySignInDesktop"
import "../../styles/amplifySignIn.css"

const AmplifySignIn = () => {
  const { isMobile, confirmCode, setViewAmplifySignIn } =
    useContext(MainContext)

  // loading for amplify sign in
  const [loading, setLoading] = useState<boolean>(false)

  // values
  const [code, setCode] = useState<string>("")

  // signin functions
  const callConfirmCode = async () => {
    if (code.length === 6) {
      setLoading(true)

      const result = await confirmCode(code)

      if (result) {
        setViewAmplifySignIn(false)
      } else {
        setErrorAlertOpen(true)
        setCode("")
      }

      setLoading(false)
    }
  }

  // confirm code when length is 6
  useEffect(() => {
    callConfirmCode()
  }, [code])

  // something went wrong alert
  const [errorAlertOpen, setErrorAlertOpen] = useState<boolean>(false)

  return isMobile ? (
    <AmplifySignInMobile
      loading={loading}
      setLoading={setLoading}
      code={code}
      setCode={setCode}
      errorAlertOpen={errorAlertOpen}
      setErrorAlertOpen={setErrorAlertOpen}
      callConfirmCode={callConfirmCode}
      itemsWidth={400}
    />
  ) : (
    <AmplifySignInDesktop
      loading={loading}
      setLoading={setLoading}
      code={code}
      setCode={setCode}
      errorAlertOpen={errorAlertOpen}
      setErrorAlertOpen={setErrorAlertOpen}
      callConfirmCode={callConfirmCode}
    />
  )
}

export default AmplifySignIn
