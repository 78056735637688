import { MissionPeriod, MissionSubType, MissionType } from "../config/enum"
import { colors } from "../config/colors"
import Avatar from "../../models/avatar"

// parse actions list
export const parseActionsListData = (items: any[], appLang: string) => {
  items.forEach((item) => {
    // metrics
    item.metrics = item.savingMetrics[0].metrics
    delete item.savingMetrics
    delete item.metrics.__typename
    if (
      item.metrics.co2Saving > 0 ||
      item.metrics.energySaving > 0 ||
      item.metrics.waterSaving > 0
    ) {
      item.metrics.hasSavings = true
    } else {
      item.metrics.hasSavings = false
    }

    // document
    item.document =
      item.document.items.find(
        (documentItem: any) => documentItem.lang === appLang
      ) ?? item.document.items.find((documentItem: any) => documentItem.default)
    delete item.document.__typename
    if (item.document.body) {
      item.document.body.forEach((bodyItem: any) => {
        delete bodyItem.__typename
      })
    }

    // category
    item.category.name = item.category.translations.find(
      (categoryItem: any) => categoryItem.lang === appLang
    )
      ? item.category.translations.find(
          (categoryItem: any) => categoryItem.lang === appLang
        ).name
      : item.category.translations.find(
          (categoryItem: any) => categoryItem.lang === "en"
        ).name
    delete item.category.translations
    delete item.category.__typename

    // general
    delete item.__typename
  })
}

// parse journey
export const parseJourneyData = (journey: any) => {
  // general
  delete journey.__typename
  delete journey.category.__typename
  delete journey.result.__typename

  // episodes
  journey.episodes.forEach((episode: any) => {
    delete episode.__typename
    delete episode.result.__typename
    episode.slides = episode.translation.slides
    episode.quiz = episode.translation.quiz
    delete episode.translation
    episode.slides.forEach((slide: any) => {
      Object.keys(slide).forEach((key) => {
        if (key.includes("Text")) {
          slide.text = slide[key]
          delete slide[key]
        } else if (key.includes("Caption") && !key.includes("CaptionTitle")) {
          slide.caption = slide[key]
          delete slide[key]
        } else if (key.includes("CaptionTitle")) {
          slide.captionTitle = slide[key]
          delete slide[key]
        } else if (key.includes("Image")) {
          slide.image = slide[key]
          delete slide[key]
        }
      })
    })
  })

  // sdgs
  if (journey.sdgs.find((item: any) => item.primary)) {
    const sdgId: string = journey.sdgs.find((item: any) => item.primary).sdg.id
    journey.sdg = parseInt(sdgId.slice(sdgId.indexOf("#") + 1))
  }
  delete journey.sdgs
}

// parse history
export const parseHistoryData = (history: any[]) => {
  history.forEach((historyItem) => {
    delete historyItem.__typename

    historyItem.counters.forEach((counter: any) => {
      delete counter.__typename
      delete counter.category.__typename
    })
  })
}

// parse detailed history
export const parseDetailedHistoryData = (history: any[], appLang: string) => {
  history.forEach((historyItem) => {
    delete historyItem.__typename
    delete historyItem.id

    // action
    delete historyItem.action.__typename
    historyItem.action.document =
      historyItem.action.document.items.find(
        (documentItem: any) => documentItem.lang === appLang
      ) ??
      historyItem.action.document.items.find(
        (documentItem: any) => documentItem.default
      )
    delete historyItem.action.document.__typename
    if (historyItem.action.document.body) {
      historyItem.action.document.body.forEach((bodyItem: any) => {
        delete bodyItem.__typename
      })
    }
    historyItem.action.category.name =
      historyItem.action.category.translations.find(
        (categoryItem: any) => categoryItem.lang === appLang
      )
        ? historyItem.action.category.translations.find(
            (categoryItem: any) => categoryItem.lang === appLang
          ).name
        : historyItem.action.category.translations.find(
            (categoryItem: any) => categoryItem.lang === "en"
          ).name
    delete historyItem.action.category.translations
    delete historyItem.action.category.__typename
  })
}

// parse categories list
export const parseCategoriesListData = (categories: any[], appLang: string) => {
  categories.forEach((category) => {
    delete category.__typename
    category.name = category.translations.find(
      (item: any) => item.lang === appLang
    )
      ? category.translations.find((item: any) => item.lang === appLang).name
      : category.translations.find((item: any) => item.lang === "en").name

    delete category.translations

    if (category.mainCategory) {
      category.mainCategory.name = category.mainCategory.translations.find(
        (item: any) => item.lang === appLang
      )
        ? category.mainCategory.translations.find(
            (item: any) => item.lang === appLang
          ).name
        : category.mainCategory.translations.find(
            (item: any) => item.lang === "en"
          ).name

      delete category.mainCategory.translations
      delete category.mainCategory.__typename
    }
  })
}

// parse main categories list
export const parseMainCategoriesListData = (
  mainCategories: any[],
  appLang: string
) => {
  mainCategories.forEach((mainCategory) => {
    delete mainCategory.__typename
    mainCategory.name = mainCategory.translations.find(
      (item: any) => item.lang === appLang
    )
      ? mainCategory.translations.find((item: any) => item.lang === appLang)
          .name
      : mainCategory.translations.find((item: any) => item.lang === "en").name

    delete mainCategory.translations
  })

  mainCategories.push(
    ...[
      mainCategories.find(
        (mainCategory) =>
          mainCategory.id === "78fe8630-965e-4d4d-923f-ac500ed63031"
      ),
      mainCategories.find(
        (mainCategory) =>
          mainCategory.id === "d498ed5b-265e-42bc-b488-309b70751d80"
      ),
      mainCategories.find(
        (mainCategory) =>
          mainCategory.id === "84512f91-6760-4129-827a-6c34da547c77"
      ),
      mainCategories.find(
        (mainCategory) =>
          mainCategory.id === "4550292f-08f5-4301-b1a5-8a08bf361744"
      ),
    ]
  )
  mainCategories.splice(0, 4)
}

// parse missions list
export const parseMissionsListData = (missions: any[], userMissions: any[]) => {
  // remove GDT custom ticket mission from list
  const indexToRemove = missions.findIndex(
    (mission) => mission.missionId === "cfac7fbe-8d9b-45c7-8164-72acfe15f693"
  )
  if (indexToRemove >= 0) {
    missions.splice(indexToRemove, 1)
  }

  missions.forEach((mission) => {
    // parse labels
    if (mission.title) {
      mission.title = mission.title.replace("Logga", "Registra")
      mission.title = mission.title.replace(
        "il Carbon Footprint",
        "la Carbon Footprint"
      )
    }

    delete mission.dependency
    delete mission.pk
    delete mission.sk
    delete mission.updatedAt

    switch (mission.missionType) {
      case MissionType.ACT:
        mission.color = colors.missions.act
        mission.titleColor = colors.text
        mission.goButtonColor = colors.textWhite
        mission.navigationPath = "/actions"
        break
      case MissionType.LEARN:
        mission.color = colors.missions.learn
        mission.titleColor = colors.text
        mission.goButtonColor = colors.textWhite
        mission.navigationPath = "/journey"
        break
      case MissionType.MEASURE:
        mission.color = colors.missions.measure
        mission.titleColor = colors.text
        mission.goButtonColor = colors.textWhite
        mission.navigationPath = "/performance/footprint"
        break
      case MissionType.SURVEY:
        mission.color = colors.missions.measure
        mission.titleColor = colors.text
        mission.goButtonColor = colors.missions.measure
        mission.navigationPath = "/survey"
        break
    }

    switch (mission.missionSubType) {
      case MissionSubType.DAILYEPISODE:
        mission.navigationPath = "/dailyepisode/0"
        break
      case MissionSubType.FOOD ||
        MissionSubType.ENERGY ||
        MissionSubType.PURCHASE ||
        MissionSubType.SOCIAL ||
        MissionSubType.TRAVEL ||
        MissionSubType.WASTE ||
        MissionSubType.MOBILITY ||
        MissionSubType.ENVIRONMENT ||
        MissionSubType.WATER:
        mission.navigationPath = "/actions"
        break
      case MissionSubType.TYPEFORM_BASE:
        mission.navigationPath = "/performance/footprint/form"
        break
      case MissionSubType.TYPEFORM_REFINE:
        mission.navigationPath = "/performance/footprint/form"
        break
      case MissionSubType.FIXED:
        mission.navigationPath = `/actions/${mission.missionTypeId}`
        break
    }

    // set go button color to primary if needed
    if (
      (mission.missionType === MissionType.ACT &&
        mission.missionSubType === MissionSubType.FIXED &&
        mission.period === MissionPeriod.DAILY) ||
      (mission.missionType === MissionType.ACT &&
        mission.missionSubType === MissionSubType.FIXED &&
        mission.period === MissionPeriod.WEEKLY) ||
      mission.missionSubType === MissionSubType.TYPEFORM_BASE ||
      mission.missionType === MissionType.LEARN
    ) {
      mission.goButtonColor = colors.primary
    }

    // set title color to white if needed
    if (
      (mission.missionType === MissionType.ACT &&
        mission.missionSubType !== MissionSubType.ALL &&
        mission.missionSubType !== MissionSubType.FIXED &&
        mission.period === MissionPeriod.WEEKLY &&
        mission.image.includes("repeatable")) ||
      (mission.missionSubType === MissionSubType.DAILYEPISODE &&
        mission.period === MissionPeriod.DAILY) ||
      (mission.missionType === MissionType.ACT &&
        mission.missionSubType === MissionSubType.ALL &&
        mission.period === MissionPeriod.CUSTOM &&
        !mission.missionTypeId &&
        !mission.image.includes("act-repeatable-all")) ||
      mission.image.includes("act-daily.svg") ||
      mission.image.includes("act-energy-repeatable.svg") ||
      mission.image.includes("act-water-repeatable.svg")
    ) {
      mission.titleColor = colors.textWhite
    }
  })

  userMissions.forEach((userMission) => {
    if (
      missions.find((mission) => mission.missionId === userMission.missionId)
    ) {
      const indexToEdit = missions.findIndex(
        (mission) => mission.missionId === userMission.missionId
      )
      missions[indexToEdit].current = userMission.current
      missions[indexToEdit].currentPerDay = userMission.currentPerDay
      missions[indexToEdit].satisfied = userMission.satisfied
      missions[indexToEdit].updatedAt = userMission.updatedAt
    }
  })

  missions.forEach((mission) => {
    if (!mission.current) {
      mission.current = 0
      mission.satisfied = false
    }
  })

  return missions
}

// parse user
export const parseUserData = (user: any) => {
  delete user.__typename
  if (user.first_name) {
    user.first_name =
      user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
  }
  if (user.last_name) {
    user.last_name =
      user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)
  }
  user.active_since = new Date(user.created_at).toLocaleDateString()
  delete user.created_at
  delete user.createdAt
  delete user.createdAtT
  delete user.kind
  delete user.pk
  delete user.sk
  delete user.tenant
  delete user.updatedAt
  delete user.updatedAtT
}

// parse group
export const parseGroupData = (group: any) => {
  group.name = group.groupName
  delete group.__typename
  delete group.groupName
  delete group.tenant
  delete group.kind
  delete group.teamId
}

// parse daily episode
export const parseDailyEpisodeData = (episode: any) => {
  delete episode.__typename
  delete episode.result.__typename
  episode.slides = episode.translation.slides
  episode.quiz = episode.translation.quiz
  delete episode.translation
  episode.slides.forEach((slide: any) => {
    Object.keys(slide).forEach((key) => {
      if (key.includes("Text")) {
        slide.text = slide[key]
        delete slide[key]
      } else if (key.includes("Caption") && !key.includes("CaptionTitle")) {
        slide.caption = slide[key]
        delete slide[key]
      } else if (key.includes("CaptionTitle")) {
        slide.captionTitle = slide[key]
        delete slide[key]
      } else if (key.includes("Image")) {
        slide.image = slide[key]
        delete slide[key]
      }
    })
  })
}

// parse challenge
export const parseChallengeData = (challenge: any, appLang: string) => {
  delete challenge.__typename
  delete challenge.metric.__typename
  delete challenge.team.__typename

  // document
  challenge.document = challenge.document.items.find(
    (item: any) => item.lang === appLang
  )
    ? challenge.document.items.find((item: any) => item.lang === appLang)
    : challenge.document.items.find((item: any) => item.default)
  delete challenge.document.__typename
  challenge.document.body.forEach((item: any) => {
    delete item.__typename
  })

  // team
  challenge.team.document = challenge.team.document.items.find(
    (item: any) => item.lang === appLang
  )
    ? challenge.team.document.items.find((item: any) => item.lang === appLang)
    : challenge.team.document.items.find((item: any) => item.default)
  delete challenge.team.document.__typename
}

// parse avatars
export const parseAvatarsListData = (avatars: string[]) => {
  const returnCorrectAvatarName = (index: number) => {
    switch (index) {
      case 0:
        return "koala"
      case 1:
        return "camel"
      case 2:
        return "tiger"
      case 3:
        return "giraffe"
      case 4:
        return "parrot"
      case 5:
        return "seal"
      case 6:
        return "panda"
      case 7:
        return "owl"
      case 8:
        return "monkey"
      case 9:
        return "chameleon"
    }

    return ""
  }

  const listToReturn: Avatar[] = []

  avatars.forEach((avatar, index) => {
    listToReturn.push({
      name: returnCorrectAvatarName(index),
      url: avatar,
    })
  })

  return listToReturn
}
