import { ButtonBase, Stack } from "@mui/material"
import { defaultBoxShadow } from "../../../services/config/constants"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import LotteryProduct from "../../../models/lotteryProduct"
import { scrollWindowToTop } from "../../../services/utils/utils"

const LotteryProductCard = ({
  index,
  product,
  currentLotteryPage,
  customOnClick,
}: {
  index: number
  product: LotteryProduct
  currentLotteryPage?: number
  customOnClick?: () => void
}) => {
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      aria-label={t("go_to_prize", { title: product.document.title })}
      style={{
        width: "100%",
        minWidth: "100%",
        height: isMobile ? "100%" : 280,
        borderRadius: 10,
      }}
      onClick={
        customOnClick
          ? customOnClick
          : () => {
              scrollWindowToTop()
              navigate(`/store/product/${product.id}`)
            }
      }
    >
      <Stack
        style={{
          width: "100%",
          height: isMobile ? "100%" : 280,
          boxShadow: defaultBoxShadow,
          borderRadius: 10,
          overflow: "hidden",
          position: "relative",
          scrollSnapAlign: "center",
        }}
        aria-hidden={currentLotteryPage !== index ? "true" : "false"}
        aria-label={product.document.title}
        aria-live="polite"
      >
        <div
          className="center"
          style={{
            width: "auto",
            height: isMobile ? 24 : 30,
            backgroundColor: "#EB5F11",
            paddingInline: isMobile ? 12 : 16,
            borderRadius: 18,
            position: "absolute",
            top: isMobile ? 85 : 101,
            left: isMobile ? 14 : 20,
          }}
        >
          <Text
            fontSize={isMobile ? 12 : 16}
            fontWeight={500}
            color={colors.textWhite}
          >
            {product.inventoryCount}{" "}
            {product.inventoryCount === 1
              ? t("available_singular")
              : t("available_plural")}
          </Text>
        </div>
        <img
          src={product.image}
          style={{
            width: "100%",
            height: isMobile ? 123 : 151,
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
        />
        <Title
          fontSize={isMobile ? 16 : 20}
          lineHeight={isMobile ? "22px" : "28px"}
          paddingLeft={isMobile ? 12 : 20}
          paddingRight={isMobile ? 12 : 20}
          ellipsis
          style={{
            marginTop: isMobile ? 12 : 13,
          }}
        >
          {product.document.title}
        </Title>
        <Text
          fontSize={isMobile ? 14 : 18}
          lineHeight={isMobile ? "20px" : "25px"}
          fontWeight={400}
          paddingLeft={isMobile ? 12 : 20}
          paddingRight={isMobile ? 12 : 20}
          style={{ marginTop: 4 }}
        >
          By {product.sponsoredBy}
        </Text>
        <Title
          fontSize={isMobile ? 12 : 16}
          fontWeight={isMobile ? 600 : 700}
          color={colors.primary}
          style={{
            textDecoration: "underline",
            position: "absolute",
            bottom: isMobile ? 14 : 25,
            right: isMobile ? 14 : 20,
          }}
        >
          {t("see")}
        </Title>
      </Stack>
    </ButtonBase>
  )
}

export default LotteryProductCard
