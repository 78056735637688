import { Grow, Modal, Stack } from "@mui/material"
import { t } from "i18next"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../../services/utils/utils"
import Text from "../../global/common/Text"
import { Trans } from "react-i18next"
import { defaultBoxShadowDarker } from "../../../services/config/constants"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const ActionLoggedFeedback = ({
  open,
  plural = false,
}: {
  open: boolean
  plural?: boolean
}) => {
  const navigate = useNavigate()
  const { isMobile, user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={plural ? t("actions_logged") : t("action_logged")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
            paddingTop: 70,
            position: "relative",
          }}
        >
          <img
            src={user?.profileImage}
            style={{
              width: 80,
              height: 80,
              borderRadius: "100%",
              boxShadow: defaultBoxShadowDarker,
              position: "absolute",
              top: -40,
            }}
            alt=""
          />
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
          >
            {plural ? t("actions_logged") : t("action_logged")}
          </Title>
          <Text
            fontSize={18}
            lineHeight="24px"
            textAlign="center"
            style={{ marginTop: 30 }}
          >
            <Trans i18nKey="come_back_tomorrow_actions">
              Continua così!
              <br />
              Torna anche domani per contribuire al completamento della
              missione.
            </Trans>
          </Text>
          <Button
            width={isMobile ? "100%" : 280}
            style={{ marginTop: 34 }}
            onClick={() => {
              scrollWindowToTop()
              navigate("/")
            }}
            title={t("back_to_missions")}
          >
            {t("back_to_missions")}
          </Button>
        </Stack>
      </Grow>
    </Modal>
  )
}

export default ActionLoggedFeedback
