import { Stack } from "@mui/material"
import FadeFromTop from "../../animations/FadeFromTop"
import { colors } from "../../../services/config/colors"
import trophyWhiteIcon from "../../../assets/icons/trophy-white.svg"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { t } from "i18next"
import PointsBadge from "../../global/common/PointsBadge"

const TicketMissionCardDesktop = () => {
  return (
    <FadeFromTop
      style={{
        width: "100%",
        position: "relative",
      }}
      y={-40}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 330,
          height: 461,
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: "calc(100% - 34px)",
            borderRadius: 20,
            backgroundColor: colors.missions.act,
            paddingTop: 42,
            paddingInline: 25,
            position: "relative",
            boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
          }}
        >
          <img src={trophyWhiteIcon} style={{ width: 42 }} alt="" />
          <Title
            fontSize={20}
            lineHeight="23px"
            color={colors.textWhite}
            textAlign="center"
            style={{ marginTop: 22 }}
          >
            {t("mission_completed").toUpperCase() + "!"}
          </Title>
          <Text
            fontSize={18}
            fontWeight={500}
            lineHeight="21px"
            color={colors.textWhite}
            textAlign="center"
            style={{ width: "100%", marginTop: 20, fontStyle: "italic" }}
            paddingLeft={25}
            paddingRight={25}
          >
            {t("ticket_got")}
          </Text>
          <PointsBadge
            points={10}
            style={{ position: "absolute", bottom: 120 }}
          />
        </Stack>
        <div
          className="mission-card-completion-badge"
          style={{
            width: 68,
            height: 68,
            borderRadius: "100%",
            backgroundColor: colors.missions.act,
            border: "3.5px solid " + colors.backgroundWhite,
            position: "absolute",
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={checkWhiteIcon}
            style={{ width: 24 }}
            alt={t("mission_completed")}
          />
        </div>
      </div>
    </FadeFromTop>
  )
}

export default TicketMissionCardDesktop
