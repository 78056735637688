import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  eventStart,
  eventEnd,
  mobileBreakpoint,
  highlightEventCardFrom,
} from "../services/config/constants"
import { checkToken, deleteMethod, get, post, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { useNavigate, useSearchParams } from "react-router-dom"
import Avatar from "../models/avatar"
import {
  cacheImages,
  generateRandomString,
  isUserSuperUser,
  logger,
} from "../services/utils/utils"
import Mission from "../models/mission"
import {
  signIn as amplifySignIn,
  signUp as amplifySignUp,
  confirmSignIn,
} from "aws-amplify/auth"
import slugify from "slugify"
import { EventStatus } from "../services/config/enum"
import EventSlot from "../models/eventSlot"
import Ticket from "../models/ticket"
import SelectOption from "../models/selectOption"
import { t } from "i18next"
import Event from "../models/event"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  signInError: boolean
  authType: "normal" | "amplify"
  viewOnboarding: boolean
  viewAmplifySignIn: boolean
  setViewAmplifySignIn: Dispatch<SetStateAction<boolean>>
  viewTutorial: boolean
  setViewTutorial: Dispatch<SetStateAction<boolean>>
  viewTicketSelection: boolean
  setViewTicketSelection: Dispatch<SetStateAction<boolean>>
  viewAvatarSelection: boolean
  setViewAvatarSelection: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userError: boolean
  getUserInfo: () => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  signIn: (keyParam?: string, ivParam?: string) => Promise<boolean>
  visualizingErrorPage: boolean
  setVisualizingErrorPage: Dispatch<SetStateAction<boolean>>
  visualizingLoadingPage: boolean
  setVisualizingLoadingPage: Dispatch<SetStateAction<boolean>>
  currentTutorialPage: number
  setCurrentTutorialPage: Dispatch<SetStateAction<number>>
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  currentMission: Mission | null
  setCurrentMission: Dispatch<SetStateAction<Mission | null>>
  updatingMissions: boolean
  setUpdatingMissions: Dispatch<SetStateAction<boolean>>
  deleteUser: () => Promise<boolean>
  sendCode: (emai: string, shouldSignIn?: boolean) => Promise<boolean>
  confirmCode: (code: string) => Promise<boolean | string>
  signUp: (
    email: string,
    firstName: string,
    lastName: string
  ) => Promise<boolean>
  eventStatus: EventStatus
  setEventStatus: Dispatch<SetStateAction<EventStatus>>
  consents: boolean[]
  setConsents: Dispatch<SetStateAction<boolean[]>>
  firstName: string
  setFirstName: Dispatch<SetStateAction<string>>
  lastName: string
  setLastName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  birthDate: string
  setBirthDate: Dispatch<SetStateAction<string>>
  eventSlots: EventSlot[]
  timeSlotOption: SelectOption
  setTimeSlotOption: Dispatch<SetStateAction<SelectOption>>
  minorTicketsOption: number
  setMinorTicketsOption: Dispatch<SetStateAction<number>>
  createTicket: () => Promise<boolean>
  ticket: Ticket | null
  event: Event | null
  emailError: boolean
  setEmailError: Dispatch<SetStateAction<boolean>>
  birthDateError: boolean
  setBirthDateError: Dispatch<SetStateAction<boolean>>
  ticketError: boolean
  setTicketError: Dispatch<SetStateAction<boolean>>
  checkInIdParam: MutableRefObject<string | null>
  checkInCompletedFeedbackOpen: boolean
  setCheckInCompletedFeedbackOpen: Dispatch<SetStateAction<boolean>>
  ticketAlreadyPresent: boolean
  slotsError: boolean
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  signInError: false,
  authType: "normal",
  viewOnboarding: true,
  viewAmplifySignIn: true,
  setViewAmplifySignIn: () => {},
  viewTutorial: true,
  setViewTutorial: () => {},
  viewTicketSelection: true,
  setViewTicketSelection: () => {},
  viewAvatarSelection: true,
  setViewAvatarSelection: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  lang: "it",
  user: null,
  setUser: () => {},
  userError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  signIn: async () => true,
  visualizingErrorPage: false,
  setVisualizingErrorPage: () => {},
  visualizingLoadingPage: false,
  setVisualizingLoadingPage: () => {},
  currentTutorialPage: 0,
  setCurrentTutorialPage: () => {},
  avatars: [],
  changeAvatar: async () => true,
  currentMission: null,
  setCurrentMission: () => {},
  updatingMissions: false,
  setUpdatingMissions: () => {},
  deleteUser: async () => true,
  sendCode: async () => true,
  confirmCode: async () => true,
  signUp: async () => true,
  eventStatus: EventStatus.LOCKED,
  setEventStatus: () => {},
  consents: [],
  setConsents: () => {},
  firstName: "",
  setFirstName: () => {},
  lastName: "",
  setLastName: () => {},
  email: "",
  setEmail: () => {},
  birthDate: "",
  setBirthDate: () => {},
  eventSlots: [],
  timeSlotOption: { id: "select", label: t("select"), disabled: false },
  setTimeSlotOption: () => {},
  minorTicketsOption: 0,
  setMinorTicketsOption: () => {},
  createTicket: async () => true,
  ticket: null,
  event: null,
  emailError: false,
  setEmailError: () => {},
  birthDateError: false,
  setBirthDateError: () => {},
  ticketError: false,
  setTicketError: () => {},
  checkInIdParam: { current: null },
  checkInCompletedFeedbackOpen: false,
  setCheckInCompletedFeedbackOpen: () => {},
  ticketAlreadyPresent: false,
  slotsError: false,
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading
  const [updatingMissions, setUpdatingMissions] = useState<boolean>(true) // loading for missions update

  // earth day event locked or not
  const [eventStatus, setEventStatus] = useState<EventStatus>(
    new Date() >= new Date(eventEnd)
      ? EventStatus.HIDDEN
      : new Date() >= new Date(eventStart) && new Date() < new Date(eventEnd)
      ? EventStatus.ACTIVE
      : new Date() >= new Date(highlightEventCardFrom)
      ? EventStatus.HIGHLIGHTED
      : EventStatus.LOCKED
  )

  // states
  const [signInError, setSignInError] = useState<boolean>(false) // signin error
  const [authType, setAuthType] = useState<"normal" | "amplify">("normal") // signin type
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign in onboarding
  const [viewAmplifySignIn, setViewAmplifySignIn] = useState<boolean>(true) // view or not amplify signin
  const [viewTicketSelection, setViewTicketSelection] = useState<boolean>(true) // view or not team ticket selection
  const [viewAvatarSelection, setViewAvatarSelection] = useState<boolean>(true) // view or not user avatar selection
  const [viewTutorial, setViewTutorial] = useState<boolean>(true) // view or not tutorial
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight) // window current height
  const [lang, setLang] = useState<string>("it") // app language
  const [user, setUser] = useState<User | null>(null) // current user
  const [userError, setUserError] = useState<boolean>(false) // current user error
  const [visualizingErrorPage, setVisualizingErrorPage] =
    useState<boolean>(false) // if user is visualizing error page or not
  const [visualizingLoadingPage, setVisualizingLoadingPage] =
    useState<boolean>(false) // if user is visualizing loading page or not
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number>(0) // current tutorial page
  const [avatars, setAvatars] = useState<Avatar[]>([]) // avatars list
  const [currentMission, setCurrentMission] = useState<Mission | null>(
    localStorage.getItem("currentMission")
      ? JSON.parse(localStorage.getItem("currentMission")!)
      : null
  ) // current mission
  const [slotsError, setSlotsError] = useState<boolean>(false) // error fetching time slots

  // signin states
  const [consents, setConsents] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ])
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [emailError, setEmailError] = useState<boolean>(false)
  const [birthDate, setBirthDate] = useState<string>("")
  const [birthDateError, setBirthDateError] = useState<boolean>(false)
  const [timeSlotOption, setTimeSlotOption] = useState<SelectOption>({
    id: "select",
    label: t("select"),
    disabled: false,
  })
  const [minorTicketsOption, setMinorTicketsOption] = useState<number>(0)

  // event states
  const [eventSlots, setEventSlots] = useState<EventSlot[]>([])
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const [event, setEvent] = useState<Event | null>(null)
  const checkInIdParam = useRef<string | null>(null)
  const [checkInCompletedFeedbackOpen, setCheckInCompletedFeedbackOpen] =
    useState<boolean>(false)

  // ticket state
  const [ticketError, setTicketError] = useState<boolean>(false) // ticket error (if user does a signin but doesn't have a ticket)
  const [ticketAlreadyPresent, setTicketAlreadyPresent] =
    useState<boolean>(false) // ticket already present for account

  // change language
  const changeLang = async (newLang: string) => {
    try {
      await put("/web/user/update", { lang: newLang })

      logger("user lang changed to " + newLang)

      return true
    } catch (e) {
      logger("lang change error", e)

      return false
    }
  }

  // get user
  const getUser = async () => {
    const { data } = await get("/web/user/get")

    return data
  }

  // get user wallet
  const getUserWallet = async () => {
    const { data } = await get("/web/mission/point/user")

    return data
  }

  // get user ticket
  const getUserTicket = async () => {
    const { data } = await get("/web/ticket/get")

    return data
  }

  // get event
  const getEvent = async () => {
    const { data } = await get("/web/ticket/event")

    return data
  }

  // get all user info
  const getUserInfo = async () => {
    setUserError(false)

    try {
      // check if user has already done the checkin mission
      let shouldCheckIn = false
      if (checkInIdParam.current) {
        const { data } = await get(
          `/web/checkin/verify/${checkInIdParam.current}`
        )

        if (!data.done) {
          shouldCheckIn = true
        }
      }

      // get all user info and event info and, if checkInIdParam is present, complete checkin mission
      const result = await Promise.all([
        getUser(),
        getUserWallet(),
        getAvatars(),
        getUserTicket(),
        getEvent(),
        ...(shouldCheckIn &&
        (eventStatus === EventStatus.ACTIVE || isUserSuperUser())
          ? [
              post("/web/checkin", {
                checkinId: checkInIdParam.current,
              }),
            ]
          : []),
      ])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet.points) {
        userData.points = userWallet.points + (shouldCheckIn ? 100 : 0)
      } else {
        userData.points = 0 + (shouldCheckIn ? 100 : 0)
      }

      // user
      logger("user", userData)
      setUser({ ...userData, lang: "it" })

      // ticket
      logger("ticket", result[3])
      // if ticket is not present, create it
      if (!result[3].qr && firstName && lastName) {
        await createTicket()
      } else if (!result[3].qr) {
        setTicketError(true)
        return false
      } else if (result[3].qr && firstName && lastName) {
        setTicketAlreadyPresent(true)
        setTicket(result[3])
      } else {
        setTicket(result[3])
      }

      // event
      logger("event", result[4])
      setEvent(result[4])

      // show checkin mission completed feedback if needed
      if (
        shouldCheckIn &&
        (eventStatus === EventStatus.ACTIVE || isUserSuperUser())
      ) {
        setCheckInCompletedFeedbackOpen(true)
      }

      // if user language is null or not it, change it
      if (userData.lang !== "it") {
        await changeLang("it")
      }

      // check what to show
      if (!firstName) {
        setViewTicketSelection(false)
      }
      if (userData.profileImage) {
        setViewAvatarSelection(false)
      }
      if (!userData.firstAccess) {
        setViewTutorial(false)
      } else {
        navigate("/")
      }

      return true
    } catch (e) {
      logger("user error", e)
      setUserError(true)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      logger("avatars list", dataToSet)

      // cache avatars
      await cacheImages(dataToSet.map((item) => item.url))

      setAvatars(dataToSet)

      return true
    } catch (e) {
      logger("avatars list error", e)
      return false
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })
      logger(`avatar set ${newAvatar}`)

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      return true
    } catch (e) {
      logger("profile image change error", e)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess")
      logger("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      logger("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    })
  }, [])

  // normal signin
  const signIn = async (keyParam?: string, ivParam?: string) => {
    try {
      // get key and iv from query params
      const key = keyParam ?? searchParams.get("key")
      const iv = ivParam ?? searchParams.get("iv")

      // do signin
      const { data } = await post(
        "/web/signin",
        {},
        {
          key: key,
          iv: iv,
        },
        false
      )

      // set tokens to local storage
      localStorage.setItem("accessToken", data.AccessToken)
      localStorage.setItem("refreshToken", data.RefreshToken)

      // get current user
      await getUserInfo()

      setViewOnboarding(false)
      setViewAmplifySignIn(false)
      setLoading(false)

      return true
    } catch (e) {
      logger("signin error", e)
      setSignInError(true)

      return false
    }
  }

  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      logger("user delete error", e)

      return false
    }
  }

  // send code to user via email
  const sendCode = async (email: string, shouldSignIn = false) => {
    try {
      await amplifySignIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })

      return true
    } catch (e) {
      if (shouldSignIn) {
        return false
      }

      // do signup and send code
      const uid =
        slugify(`${firstName} ${lastName}`, { lower: true }) +
        generateRandomString(6, "0123456789abcdefghijklmnopqrstuvwxyz")
      const password = generateRandomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )

      await amplifySignUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            name: `${firstName} ${lastName}`,
            preferred_username: uid,
            "custom:tenant": "aworld",
          },
        },
      })

      await amplifySignIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })

      return true
    }
  }

  // check code that user has entered
  const confirmCode = async (code: string) => {
    try {
      const result = await confirmSignIn({ challengeResponse: code })

      if (result.isSignedIn) {
        logger("right code")

        // search for amplify accessToken in localStorage
        let amplifyAccessToken
        for (let i = 0; i < localStorage.length; i++) {
          if (
            localStorage.key(i) &&
            localStorage.key(i)!.includes("accessToken")
          ) {
            amplifyAccessToken = localStorage.getItem(localStorage.key(i)!)!
          }
        }

        // clear local storage from amplify session
        localStorage.clear()

        // encrypt amplify accessToken
        const { data } = await post(
          "/web/encrypt",
          {},
          {
            Authorization: "Bearer " + amplifyAccessToken,
          },
          false
        )

        const key = data.key
        const iv = data.iv

        // do a normal signin
        await signIn(key, iv)

        return true
      } else {
        logger("wrong code")

        return false
      }
    } catch (e: any) {
      logger("confirmSignIn error", e)

      return false
    }
  }

  // amplify signup
  const signUp = async (email: string, firstName: string, lastName: string) => {
    // generate uid and "fake" password
    const uid =
      slugify(`${firstName} ${lastName}`, { lower: true }) +
      generateRandomString(6, "0123456789abcdefghijklmnopqrstuvwxyz")
    const password = generateRandomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    )

    try {
      await amplifySignUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            name: `${firstName} ${lastName}`,
            preferred_username: uid,
            "custom:tenant": "aworld",
          },
        },
      })

      return true
    } catch (e) {
      logger("signup error", e)
      return false
    }
  }

  // get event time slots
  const getSlots = async () => {
    try {
      const { data } = await get("/web/ticket/slots", undefined, false)
      logger("event slots", data.righe)

      setEventSlots(data.righe)

      return true
    } catch (e) {
      logger("slots error", e)

      setSlotsError(true)
    }
  }

  // create event ticket
  const createTicket = async () => {
    try {
      const { data } = await post("/web/ticket/record/insert", {
        id_fascia: timeSlotOption.id,
        nome: firstName,
        cognome: lastName,
        email: email,
        src:
          searchParams.get("key") && searchParams.get("iv")
            ? "app"
            : searchParams.get("src"),
        nascita:
          birthDate.slice(6) +
          "-" +
          birthDate.slice(3, 5) +
          "-" +
          birthDate.slice(0, 2),
        campi_aggiuntivi: {
          minori: minorTicketsOption.toString(),
        },
        checkbox_1: consents[1] ? "1" : "0",
        checkbox_2: consents[2] ? "1" : "0",
        checkbox_3: consents[3] ? "1" : "0",
        checkbox_4: consents[4] ? "1" : "0",
      })

      logger("ticket created", data)
      setTicket(data)

      return true
    } catch (e) {
      logger("ticket creation error", e)

      return false
    }
  }

  // check if an auth session is already present or not
  const checkSession = async () => {
    // get checkin_id for checkin missions
    checkInIdParam.current = searchParams.get("checkin_id")

    // if key and iv are present, it means that the user is coming from the aworld app
    // so in this case, either the privacy acceptance page is shown or a normal signin is performed.
    // otherwise do an amplify signin
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")
    const privacy = searchParams.get("privacy")

    if (key && iv) {
      setAuthType("normal")
      setViewAmplifySignIn(false)
      localStorage.setItem("fromApp", "true")

      if (privacy === "true") {
        setViewOnboarding(false)

        signIn()
      } else {
        await getSlots()

        setLoading(false)
      }
    } else {
      setAuthType("amplify")

      if (
        !localStorage.getItem("accessToken") ||
        !localStorage.getItem("refreshToken")
      ) {
        // clear localStorage
        localStorage.clear()

        // get event slots
        await getSlots()

        // no user, so go to onboarding, accept terms and privacy and do amplify signin
        setLoading(false)
      } else {
        // do a token refresh if needed
        await checkToken()

        // get current user
        await getUserInfo()

        setViewAmplifySignIn(false)
        setViewOnboarding(false)
        setLoading(false)
      }
    }
  }

  // initial fetch
  useEffect(() => {
    checkSession()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        signInError,
        authType,
        viewOnboarding,
        viewAmplifySignIn,
        setViewAmplifySignIn,
        viewTutorial,
        setViewTutorial,
        viewTicketSelection,
        setViewTicketSelection,
        viewAvatarSelection,
        setViewAvatarSelection,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        user,
        setUser,
        userError,
        getUserInfo,
        setUserFirstAccess,
        signIn,
        visualizingErrorPage,
        setVisualizingErrorPage,
        visualizingLoadingPage,
        setVisualizingLoadingPage,
        currentTutorialPage,
        setCurrentTutorialPage,
        avatars,
        changeAvatar,
        currentMission,
        setCurrentMission,
        updatingMissions,
        setUpdatingMissions,
        deleteUser,
        sendCode,
        confirmCode,
        signUp,
        eventStatus,
        setEventStatus,
        consents,
        setConsents,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        birthDate,
        setBirthDate,
        eventSlots,
        timeSlotOption,
        setTimeSlotOption,
        minorTicketsOption,
        setMinorTicketsOption,
        createTicket,
        ticket,
        event,
        emailError,
        setEmailError,
        birthDateError,
        setBirthDateError,
        ticketError,
        setTicketError,
        checkInIdParam,
        checkInCompletedFeedbackOpen,
        setCheckInCompletedFeedbackOpen,
        ticketAlreadyPresent,
        slotsError,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
