import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { defaultBoxShadow } from "../../../services/config/constants"
import Text from "../../global/common/Text"
import { ReactNode } from "react"
import Switch from "../../global/common/Switch"

const ConsentCard = ({
  title,
  description,
  checked,
  onChange,
  disabled = false,
}: {
  title: string
  description: ReactNode
  checked: boolean
  onChange: () => void
  disabled?: boolean
}) => {
  return (
    <Stack
      style={{
        width: "100%",
        paddingTop: 12,
        paddingBottom: 12,
        paddingInline: 10,
        borderRadius: 16,
        boxShadow: defaultBoxShadow,
      }}
    >
      <Title fontSize={18} lineHeight="25px" fontWeight={600}>
        {title}
      </Title>
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          marginTop: 8,
          position: "relative",
        }}
      >
        <Text
          fontSize={12}
          lineHeight="14px"
          style={{ width: "calc(100% - 100px)", minHeight: 28 }}
        >
          {description}
        </Text>
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          style={{ position: "absolute", right: 6 }}
        />
      </Stack>
    </Stack>
  )
}

export default ConsentCard
