import LotteryProduct from "../../models/lotteryProduct"
import { BodySliceType } from "../config/enum"
import lotteryProduct1 from "../../assets/images/products/lottery-product-1.jpg"
import lotteryProduct2 from "../../assets/images/products/lottery-product-2.jpg"

export const lotteryProducts: LotteryProduct[] = [
  {
    id: "nfc-jukb-x3x",
    image: lotteryProduct1,
    document: {
      title: "City bike e cargo light",
      body: [
        {
          sliceType: BodySliceType.BodyTextSlice,
          text: "Nilox mette in palio <strong>n.1 e-Cargo bike C1 Cargo Light Nilox e n.1 e-bike J5 Plus Nilox.<br /><br />Prova a vincere:</strong> più Punti Pianeta ottieni completando le Missioni, più probabilità avrai di partecipare all’estrazione per vincere un premio.",
        },
      ],
    },
    sponsoredBy: "Nilox",
    inventoryCount: 2,
  },
  {
    id: "crt-5fww-35h",
    image: lotteryProduct2,
    document: {
      title: "Biglietto Nitto ATP Finals Torino",
      body: [
        {
          sliceType: BodySliceType.BodyTextSlice,
          text: "<strong>VeChain mette in palio una coppia di biglietti per le Nitto ATP Finals Torino.<br /><br />Prova a vincere:</strong> più Punti Pianeta ottieni completando le Missioni, più probabilità avrai di partecipare all’estrazione per vincere il premio.",
        },
      ],
    },
    sponsoredBy: "VeChain",
    inventoryCount: 2,
  },
]
