import EventArea from "../../models/eventArea"
import EventZone from "../../models/eventZone"
import mainStageImage from "../../assets/images/event/main-stage.jpg"
import areaPanelImage from "../../assets/images/event/area-panel.jpg"
import biodiversityHubImage from "../../assets/images/event/biodiversity-hub.jpg"
import hubImage from "../../assets/images/event/hub.jpg"
import kidsAreaImage from "../../assets/images/event/kids-area.jpg"
import wellnessAreaImage from "../../assets/images/event/wellness-area.jpg"
import earthMarketImage from "../../assets/images/event/earth-market.jpg"

export const eventZones: EventZone[] = [
  {
    id: "all",
    label: "Tutte le zone",
  },
  {
    id: "blue",
    label: "Blue Zone",
  },
  {
    id: "green",
    label: "Green Zone",
  },
  {
    id: "orange",
    label: "Earth & Food Market",
  },
  {
    id: "yellow",
    label: "Wellness & Kids Area",
  },
  {
    id: "pink",
    label: "Main Stage",
  },
]

export const eventAreas: EventArea[] = [
  {
    id: "area_panel",
    qrId: "bevzxV",
    title: "Area Panel",
    description:
      "L'area panel è pensata come salotto informale, in cui saranno organizzati dei panel e dei talk sulle tematiche centrali dell’evento. Apriamo, così, un dialogo anche con il pubblico presente in modo da togliere le distanze tra gli esperti in materia e la cittadinanza.<br /><br />Non perdetevi lo spazio dedicato alle istituzioni e agli sponsor che sostengono l’evento della Giornata della Terra.",
    schedule: `<ul>
      <li><strong>9:45 - 10:30: Mobilità sostenibile</strong> e accessibile, un confronto tra attivisti ed esperti, con Milo Cuniberto (FIAB, Bike Pride), Monica Nanetti (giornalista), Alessia Volpin (Disability Pride). Modera Nicolas Lozito.</li>
      <li><strong>10:35 - 11:20: Salute circolare: un modo di pensare.</strong> Dialogo tra Ilaria Capua (Virologa, Senior Fellow of Global Health alla Johns Hopkins University) e Massimo Cirri (Conduttore radiofonico e psicologo).</li>
      <li><strong>11:25 - 12:10: Cool future? La crisi climatica tra arte, scienza e sport. una giornalista, uno scienziato, un artista (e una sportiva da confermare) discutono quali futuri possiamo immaginare per i ghiacciai alpini e per il nostro rapporto con la montagna.</strong> Ospiti: Gianpaolo Balsamo, Sergio Maggioni, Katia Tomatis. Modera Barbara Gallavotti</li>
      <li><strong>12:15 - 13:10: Sostenibilità sostenibile: sfaccettature ed esperienze del ritorno di investimento.</strong> Rossella Sobrero (Founder Koinetica), Daniela Bernacchi (Executive Director Global Compact), Alberto Grignolo (Imprenditore e filantropo - Fondazione AlberoRosso), Marco Piccolo (CEO Reynaldi, Consigliere Gruppo Chimica&Vetro Unione Industriali Torino), Federico Corsinotti, AD Maider NCG e Consigliere Gruppo Gomma Plastica Unione Industriali Torino. Modera Vittorio Di Tomaso (partner Maize e presidente gruppo Digital Techologies, UI Torino).</li>
      <li><strong>13:15 - 14:00: "Verso un futuro sostenibile: Community, AI e Gamification come catalizzatore del cambiamento per consumatori, dipendenti e cittadini“.</strong> Intervengono: Giuseppe Zuliani (Marketing Director di Conad), Marco Armellino (Presidente di AWorld), Matteo Vacchetti (Coordinatore Corporate Venture Capital di Iren), Marianna Manfrino (Managing Partner di I-DEAGATE) Modera: Angela Bruzzone (Project & Partnership Manager di AWorld).</li>
      <li><strong>14:05 - 14:35: Un approccio integrato alla sostenibilità. Istituzioni a confronto</strong>, con Alberto Anfossi (Segretario Generale, Fondazione Compagnia di San Paolo), Chiara Foglietta (Assessora alla Transizione Ecologica e Digitale, Politiche per l'Ambiente, Innovazione, Servizi Cimiteriali, Piano dei trasporti, parcheggi e viabilità), Giulio lo Iacono (Segretario Generale, Asvis), Cristina Prandi (Vice-Rettrice per la ricerca delle scienze naturali e agrarie, Università degli Studi di Torino), Stefano Corgnati (Rettore, Politecnico di Torino). Modera Alessandro Armillotta (CEO, AWorld)</li>
      <li>14:40 - 15:25: <strong>"Sbloccare l'SDG18: i vantaggi senza precedenti del Web3 per l'impatto individuale.</strong> con Alex Armillotta (CEO AWorld), Francesco Bruschi (Head of Blockchain and Distributed Ledger Observatory, Politecnico di Milano), Renato Grottola (Vechain Steering Committee Member), Eleonora Tieri (Climate & Sustainability Project Leader, Boston Consulting Group), Francesco Perrini (Università Bocconi di Milano). Modera Lucrezia Van Stegeren (CEO & Co-founder of Cryptoland Media)"</li>
      <li><strong>15:30 - 16:15: Il coraggio civile: costruire un futuro equo insieme</strong>, con Shata Diallo (Inclusion Lead in MIDA), Marco Giusta (Arcigay), Alice Tancredi (Mai Ultimi), Francesca Vecchioni (Diversity Lab), Marco Martinelli (@marcoilgiallino, divulgatore scientifico Università sant'Anna). Modera Juliana Papurello (Associazione Legami Adottivi).</li>
      <li><strong>16:20 - 17:05: Le nuove frontiere della cucina vegetale. Un nuovo concetto di benessere personale che richiama un contesto più ampio.</strong> Ospiti: Eduardo Ferrante (Orto già Salsamentario), Luca e Riccardo dei Di Pazza (Vegan Food Creator), Luigi Torchio (medico nutrizionista). Modera Egidio Dansero (Vicerettore per la sostenibilità e la cooperazione allo sviluppo dell'Università di Torino).</li>
      <li><strong>17:10 - 17:55:</strong> L’attivismo nel mondo del lavoro: giovani voci a confronto con Aurora Audino (consulente in Climate Policy and Sustainable Finance), Guglielmo Mazzà (ReFuse), Marta Tuninetti (Politecnico di Torino), Elisa Terenghi (climatologa). Modera Patrizia Lombardi (Vice Rettrice per Campus sostenibile e Living Lab al Politecnico di Torino).</li>
      <li><strong>10:00 - 18:00: "Changing room (Fondazione Pistoletto) a cura di CTRLZAK Studio</strong> (Katia Meneghini e Thanos Zakopoulos)".</li>
      <li><strong>10:00 - 18:00:</strong> Raccolta straordinaria di abiti usati, a cura di Humana.</li>
    </ul>`,
    zone: "blue",
    image: areaPanelImage,
  },
  {
    id: "biodiversity_hub",
    qrId: "bevzwb",
    title: "Biodiversity Hub",
    description:
      "Un centro dinamico per l'educazione, in cui si promuove la comprensione e la conservazione del ricco tessuto biologico della Terra.",
    schedule: `<ul>
      <li><strong>10:00 - 18:00: Arco Elefante di Guerilla Spam.</strong> “Arco elefante” è un'opera del collettivo Guerrilla Spam, realizzata per il Museo Regionale di Scienze Naturali: un passaggio immaginario tra Oriente e Occidente; l’incontro tra noi e gli altri può avvenire attraverso una tenda che deve essere attraversata.</li>
      <li><strong>13:00 - 18:00: “Osservatorio micologico”, installazione partecipata.</strong> Un'installazione artistica partecipata che unisce arte e natura per celebrare l'importanza della simbiosi e la bellezza dell'ecosistema forestale. Il pubblico potrà contribuire alla costruzione dell'installazione, realizzando statuette di argilla da posizionare sul tappeto di stoffa. L'obiettivo sarà riflettere sulla simbiosi tra l'Amanita muscaria e le piante della foresta.</li>
      <li><strong>10:30 - 11:15: Il turismo sostenibile in relazione agli animali selvatici.</strong> Il talk esplora il legame tra turismo etico, benessere animale ed etologia. Con la partecipazione di Chiara Grasso, esperta in etologia, il panel discuterà delle pratiche sostenibili nel turismo che rispettano il benessere degli animali selvatici.</li>
      <li><strong>10:30 - 13:00: L’asteroide siamo noi!</strong> Un approccio esperienziale per una riflessione consapevole sull’impatto della specie uomo sull’ambiente e sulle altre specie viventi che lo popolano, con l’obiettivo di sensibilizzare alle problematiche legate alla continua diminuzione di biodiversità nei diversi habitat, aumentando la consapevolezza del ruolo di ciascuno nelle scelte quotidiane, cruciali per il futuro del nostro pianeta.</li>
      <li><strong>13:30 - 14:15: Liminality Open Lab.</strong> Riccardo Mantelli dà vita a "Liminality Open Lab", un'esperienza dove l'antico dialoga con il digitale. Attraverso l'uso innovativo della tecnologia, le piante in questo spazio diventano fonti di suono al tocco, permettendo una nuova forma di espressione artistica. Questa collaborazione trasforma il laboratorio in un campo di sperimentazione multisensoriale, dove la natura comunica attraverso la tecnologia, stimolando riflessioni sul nostro rapporto con l'ambiente circostante.</li>
      <li><strong>14:00 - 16:30: L’asteroide siamo noi!</strong></li>
      <li><strong>14:30 - 15:00: Jam session. Animal & Earth.</strong> Workshop a cura del Dipartimento Dbios dell'Università di Torino, progettato per ricreare l'atmosfera e i suoni del mondo animale. La capacità di percepire e fare musica e la nostra predilezione per i paesaggi sonori naturali sono aspetti differenti che ci legano in modo profondo alla storia naturale degli ambienti in cui viviamo e delle specie animali...</li>
      <li><strong>15:15 - 16:00: Animali brutti, sporchi e cattivi - animali bullizzati.</strong> Laboratorio interattivo per adulti e bambini: animali brutti, sporchi e cattivi - animali bullizzati. Tutti gli animali meritano rispetto, non solo quelli "pucciosi" - la biodiversità è fatta di tutti gli animali, anche quelli piccoli e apparentemente inutili e scomodi. Perchè abbiamo paura degli insetti? Perchè è importante saper convivere con tutti gli animali? Un'etologa guiderà i partecipanti alla scoperta dei segreti nascosti nel "brutto" della natura.</li>
      <li><strong>16:10 - 16:55: Bio-Forest: progetti di riforestazione urbana a favore della Biodiversità territoriale.</strong> Con Giuseppe Zuliani Direttore, Customer Marketing e Comunicazione di Conad, e Paolo Viganò, fondatore di Rete Clima, parleremo di come sia possibile sperimentare soluzioni ecologicamente innovative come le Bio-Forest, per il miglioramento degli ecosistemi urbani e di come Aziende come Conad siano in prima linea per la tutela del territorio cittadino raccontando il loro progetto in tutto il territorio nazionale ed i loro recenti interventi presso la città di Torino.</li>
      <li><strong>17:30 - 18:00: Jam session. Animal & Earth.</strong> Workshop a cura del Dipartimento Dbios dell'Università di Torino, progettato per ricreare l'atmosfera e i suoni del mondo animale. La capacità di percepire e fare musica e la nostra predilezione per i paesaggi sonori naturali sono aspetti differenti.</li>
    </ul>`,
    zone: "green",
    image: biodiversityHubImage,
  },
  {
    id: "tech_innovation_hub",
    qrId: "bevzvb",
    title: "Tech & Innovation Hub",
    description:
      "Il Tech & Innovation sarà un luogo in cui convergeranno tecnologie e soluzioni per creare innovazione volta ad affrontare il cambiamento climatico.",
    schedule: `<ul>
      <li><strong>10:00 - 18:00: Videogame station by Dinobros.</strong> Gioca a tennis con una pallina di C02 grazie a Dinobros! Riuscirai a vincere un Match da tennis da solo o in compagnia?</li>
      <li><strong>10:00 - 18:00: Start-up Challenge.</strong> La challenge vede sfidarsi una serie di start-up innovative nel campo della sostenibilità, ognuna con soluzioni uniche e promettenti per affrontare le sfide ambientali attuali. Le start-up presenteranno idee e progetti volti a ridurre l'impatto ambientale, promuovere l'efficienza energetica, gestire le risorse in modo sostenibile e favorire uno sviluppo eco-compatibile.</li>
      <li><strong>10:30 - 12:30: Attività VR.</strong> In un contesto animato, immerso nella musica e nella folla, è essenziale trovare un momento di quiete e distacco. Ti senti sopraffatto dalla folla o dal rumore? Questa esperienza di realtà virtuale offre un rifugio rilassante, permettendoti di scegliere un ambiente che rispecchi il tuo stato d'animo attuale. Concediti alcuni minuti di totale tranquillità e comfort, lontano dal trambusto, in un ambiente sicuro e protetto.</li>
      <li><strong>10:30 - 11:15: Web3 Bar.</strong> Gli esperti di VeChain ci guideranno nel Web3 Bar, area esperienziale del Tech&Innovation Hub, dove si potranno sorseggiare Mocktail dai nomi innovativi e apprendere come le nuove tecnologie associate al Web3 possano entrare a far parte della sostenibilità e dell'esperienza Giornata della Terra nell'App AWorld.</li>
      <li><strong>11:00 - 12:00: Start-up in pillole.</strong> Vuoi scoprire le nuove sfide tecnologiche nel campo della sostenibilità? Vieni, Ascolta e Vota la Start Up più promettente.</li>
      <li><strong>15:30 - 17:30: Attività VR</strong></li>
      <li><strong>15:00 - 16:00: Start-up in pillole</strong></li>
      <li><strong>16:00 - 16:45: Web3 Bar a cura di VeChain</strong></li>
    </ul>`,
    zone: "green",
    image: hubImage,
  },
  {
    id: "diversity_inclusion_hub",
    qrId: "bevztc",
    title: "Diversity & Inclusion Hub",
    description:
      "L’Inclusion Hub sarà la voce di attivisti/e, divulgatori/divulgatrici ed esperti/e per porre l’attenzione sulla dimensione sociale della sostenibilità (diritti fondamentali, parità di genere, diversità).",
    schedule: `<ul>
      <li><strong>10:00 - 18:00: Scultura MOG a cura di Morgana Orsetta Ghini (Collettivoop.com).</strong> Scultura UUUUDUM. UUUUDUM è il processo artistico del Collettivo OP progettato da MOG Morgana Orsetta Ghini e Luca Lagash nell'ambito di ricerca sviluppata con il polo tecnologico HTW Berlin, che prosegue l'opera Uno Di Un Milione inaugurata con l'Accademia Teatro alla Scala di Milano e PPK Innovation nella vetta di Pejo 3000.</li>
      <li><strong>10:00 - 18:00: Stand informativi di associazioni, collettivi e fondazioni.</strong> Associazioni, fondazioni e collettivi impegnati nell'ambito Diversity and Inclusion presenzieranno durante tutto l'evento, raccontando le proprie azioni a sostegno dei diritti di tutti e tutte.</li>
      <li><strong>11:00 - 14:00: Workshop interattivo. Accessibilità e Sostenibilità a cura di Mai Ultimi.</strong> Il workshop interattivo proposto da Mai Ultimi mira a affrontare le sfide che le persone con disabilità e neurodivergenze incontrano nella mobilità sostenibile. Durante il workshop, i partecipanti saranno coinvolti attivamente in attività pratiche e simulazioni sensoriali per comprendere meglio le sfide affrontate da queste persone nella mobilità urbana contemporanea. Attraverso la raccolta di dati qualitativi e la condivisione di esperienze personali, il workshop stimolerà una profonda riflessione sul concetto di accessibilità e sostenibilità, sottolineando l'importanza dei mezzi pubblici come strumento di emancipazione.</li>
      <li><strong>11:00 - 11:45: Hai mai provato a metterti nei panni di qualcun altro?</strong> Siamo qui per te, offrendo percorsi professionali inclusivi per coloro che, come te, stanno cercando l'opportunità giusta. Utilizza la tua voce per rielaborare il tuo CV con l'intelligenza artificiale in pochi minuti e lasciati guidare nella ricerca del lavoro che fa al caso tuo. Ti aspettiamo presso l'HUB D&I nello spazio Adecco.</li>
      <li><strong>14:00 - 18:00: Parti da te, cambia una cosa.</strong> Durante il laboratorio curato dall' illustratrice Viola Gesmundo sarà possibile realizzare un manifesto personalizzato per ricordare quotidianamente il potenziale di ciascuno di noi nel fare la differenza.</li>
      <li><strong>16:30 - 17:15: Casa mia o casa tua, dialogo sulla cittadinanza globale.</strong> Tramite il confronto di attivisti e giornalisti si discuterà del valore della cittadinanza globale come elemento imprescindibile in ogni forma di democrazia, ragionando su ostacoli, sfide e nuove modi di costruire e vivere la comunità civile.</li>
    </ul>`,
    zone: "green",
    image: hubImage,
  },
  {
    id: "energy_transition_hub",
    qrId: "bevzrj",
    title: "Energy Transition Hub",
    description:
      "Hub di condivisione per presentare storie e strategie innovative che accelerino i progressi verso il netzero.",
    schedule: `<ul>
      <li><strong>10:00 - 18:00: SmartFlower Solar Panel by Imel Osasio.</strong> SmartFlower Solar Panel è l’ultimo ritrovato della tecnologia in merito a pannelli solari e sistemi fotovoltaici, si tratta di un inseguitore biassiale dalle sembianze di un fiore che combina efficienza, produzione di energia, automatismo, design ed estetica: il sistema funziona automaticamente grazie a un innovativo algoritmo astronomico che permette di ottenere il massimo rendimento in qualsiasi momento della giornata.</li>
      <li><strong>10:00 - 18:00: Back to Earth: la bioplastica torna in natura.</strong> Come svolgere una corretta raccolta differenziata delle bioplastiche? Grazie ad AMIAT Gruppo Iren durante la giornata sarà possibile cimentarsi in giochi enigmistici, ricevere materiale divulgativo, partecipare ad attività di formazione, imparando a distinguere gli imballaggi in bioplastica compostabile da quelli di normale plastica, per un corretto riciclo di entrambi.</li>
      <li><strong>10:30 - 12:00: La plastica invisibile: cosa si nasconde in quello che ingeriamo?</strong> Le ricercatrici del Politecnico di Torino guideranno i partecipanti alla scoperta dell’uso e delle ricadute di un materiale che ha cambiato la vita sulla terra: le plastiche. Una mostra interattiva e multimediale ci racconterà il viaggio delle plastiche tra mare e terra, dall’Antartide alle Alpi. L’esposizione verrà arricchita da un laboratorio che consentirà a tutte le persone interessate di conoscere meglio gli aspetti relativi all’inquinamento da plastiche.</li>
      <li><strong>13:00 - 13:30: Live performance di Domenico Urban Drummer.</strong> Con la sua live performance Domenico Urban Drummer coinvolgerà il pubblico in un'esperienza musicale unica nel suo genere. Domenico trasforma gli oggetti insoliti in strumenti musicali, creando una sinfonia irresistibile di ritmi e gag che intratterranno e divertiranno grandi e piccoli.</li>
      <li><strong>14:00 - 17:00: Laboratorio mobile.</strong> Un furgone 4x4 con allestimento personalizzato per diventare un vero e proprio laboratorio da campo, in cui poter elaborare i dati in tempo reale, installare sensoristica per acquisizione in movimento, trasportare attrezzature di ricerca per il monitoraggio del cambiamento climatico sia in ambiente urbano che in zone remote (es. ghiacciai alpini). I ricercatori e le ricercatrici del Dipartimento di Ingegneria dell’Ambiente, del Territorio e delle Infrastrutture del Politecnico di Torino illustreranno ai visitatori il veicolo e i suoi accessori, le attrezzature installate e alcune delle attività di ricerca svolte sul campo grazie a questi mezzi progettati e realizzati nell’ambito del progetto cambiamenti_climatici@polito.it.</li>
    </ul>`,
    zone: "green",
    image: hubImage,
  },
  {
    id: "youth_hub",
    qrId: "bevzfb",
    title: "Youth Hub",
    description:
      "Uno spazio per raccontare come i giovani stiano facendo la differenza e realizzando cambiamenti positivi nel mondo, uno spazio per attivisti e attiviste e per giovani changemakers.",
    schedule: `<ul>
      <li><strong>10:00 - 11:30: AquaBINGO.</strong> Organizzato dai Dipartimenti di Chimica e di Molecular Biotechnology and Health Sciences dell'Università di Torino, coinvolge i partecipanti in un gioco di bingo per un'esplorazione interattiva delle caratteristiche delle acque e dei loro trattamenti di riutilizzo, basandosi sulle ricerche effettuate nel progetto europeo IN2AQUAS.</li>
      <li><strong>10:00 - 18:00: Live painting a cura di Depurarte.</strong> L'associazione Depurarte curerà un live painting di grandi dimensioni sul tema della sostenibilità. Saranno coinvolti due giovani artisti emergenti torinesi, ovvero Giga e NoReasonArt. L'intervento pittorico sarà realizzato con le vernici foto catalitiche che hanno la capacità di assorbire CO2 dall'aria.</li>
      <li><strong>10:00 - 18:00: La mia città del futuro è…</strong> Attività di coinvolgimento dei partecipanti sulla condivisione di idee e desideri futuri per la loro città. L'obiettivo è quello di rendere le persone partecipi del cambiamento fornendo spunti di riflessione ed evidenziando bisogni e necessità dei cittadini che le istituzioni dovrebbero tenere presenti nello sviluppo del territorio.</li>
      <li><strong>10:00 - 17:00: Pop the Vote! Culture on the Ballot.</strong> Lo stand del progetto Pop the Vote, condotto in Italia da Gaia Zanone e Michele Tagliavini, offrirà tre esperienze culturali per sensibilizzare i cittadini sulle Elezioni Europee del 2024. L'obiettivo è contrastare il trend negativo registrato negli anni precedenti, aumentando così la partecipazione elettorale.</li>
      <li><strong>10:00 - 18:00: Che changemaker sei?</strong> Scopri il tuo potenziale nascosto con Snooze! Inizia a creare il tuo CV in pochi minuti grazie alla nostra interpretazione dell’AI. Mettiti alla prova e potresti diventare il CEO di The Adecco Group! I nostri recruiter saranno al tuo fianco per aiutarti a trovare il lavoro che fa per te. Non vediamo l'ora di incontrarti allo Youth Hub presso lo spazio Adecco!</li>
      <li><strong>10:30 - 11:15: Il magico mondo della crisi climatica secondo Harry Potter</strong>, con Sofia Pasotto (attivista per il clima). Il magico mondo della crisi climatica, un'attività orientata ai ragazzi delle scuole superiori di primo grado per spiegare, attraverso analogie con il più famoso libro e film del mondo, i cambiamenti causati della crisi climatica. L'utilizzo di un linguaggio "universale" come quello del magico mondo ideato da J.K.Rowling per parlare di tematiche complesse ha l'obiettivo di rendere più accessibili per i ragazzi temi di attualità.</li>
      <li><strong>11:30 - 13:00: Escape TOLearn, escape room digitale sul tema dell’acqua.</strong> Attraverso queste escape-room digitali sulle acque sotterranee sarà possibile comprendere l'importanza di un uso sostenibile delle risorse idriche. Risorsa didattica basata sul gioco e creata in collaborazione con alcuni dipartimenti dell'Università di Torino (Chimica, Scienze della Terra, Psicologia, Filosofia e Scienze dell'Educazione). Partecipazione di 2-3 persone a turno che potranno visionare immagini, video, testi sul tema delle risorse idriche e partecipare a giochi e quiz per raccogliere indizi utili a completare il percorso.</li>
      <li><strong>11:45 - 12:30: Smontare la crisi climatica come un mobile dell’Ikea</strong>, con Sofia Pasotto (attivista per il clima). Durante il laboratorio, pensato per per le scuole superiori di secondo grado, attraverso la scomposizione dei macrotemi del cambiamento climatico e della crisi climatica in problemi più piccoli ed affrontabili separatamente, verrà fornito un quadro generale di un problema complesso cercando di trattarli in maniera più semplice e chiara.</li>
      <li><strong>12:45 - 13:30: NextJournalist</strong> a cura di Lifegate. Il team di ragazze e ragazzi vincitori del progetto NextJournalist di LifeGate selezionerà una serie di notizie del giorno, commentandole sul divano dello Youth Hub con un giornalista di LifeGate: una mini rassegna stampa dalle quale scaturiranno riflessioni e considerazioni in un dialogo diretto con la redazione.</li>
      <li><strong>13:40 - 14:25: Turin Carbon Cutters.</strong> Il Progetto Turin Carbon Cutters ospita una tavola rotonda composta da quattro relatori provenienti da diverse realtà coinvolte nel progetto. Moderata da una conduttrice che pone domande specifiche, la tavola rotonda si propone di esplorare il tema della sostenibilità ambientale da varie prospettive.</li>
      <li><strong>14:30 - 15:15: Climate Talks.</strong> Giovani attiviste under 30 discutono insieme sulla lotta al cambiamento climatico da una prospettiva europea.</li>
      <li><strong>15:20 - 15:50. L’effetto della guerra sul clima.</strong> Durante l'incontro, verranno esaminati i possibili impatti dei conflitti armati sull'ambiente e sul clima globale, attraverso l'analisi di casi di studio. Sarà discussa anche l'importanza dell'attivismo civico nel promuovere la pace e la sostenibilità ambientale, con particolare attenzione alle strategie non violente e alle azioni di sensibilizzazione.</li>
      <li><strong>16:00 - 17:00: Presentazione di “Città autistica” (Einaudi) di Alberto Vanolo.</strong> Attraverso il dialogo stimolante tra l'autore e Gaia de' Liguoro di Fridays for Future Torino, questo talk, in collaborazione con il Salone del Libro di Torino, approfondisce i temi chiave trattati nel libro, offrendo un'occasione unica per esplorare la visione dell'autore sulla città contemporanea e il suo impatto sulle persone autistiche.</li>
    </ul>`,
    zone: "green",
    image: hubImage,
  },
  {
    id: "kids_area",
    qrId: "bevzcu",
    title: "Kids Area",
    description:
      "La kids area offre un ambiente interattivo e educativo dove i bambini possono esplorare la natura, partecipare a giochi ecologici e imparare l'importanza della sostenibilità ambientale attraverso attività divertenti e coinvolgenti. Con laboratori creativi, dimostrazioni pratiche e giochi educativi, i giovani partecipanti possono scoprire modi divertenti per prendersi cura del pianeta.",
    schedule: `<ul>
      <li><strong>10:00 - 12:00: Pet Therapy</strong>, a cura della Fondazione Rubens, offre un'esperienza di interazione unica tra gli animali e gli individui, per migliorare il benessere emotivo, fisico e sociale. I cani addestrati saranno presenti per instaurare una connessione empatica e per offrire comfort e supporto, creando così un ambiente terapeutico e rilassante.</li>
      <li><strong>10:00 - 12:00: Kids in action.</strong> Le volontarie ed i volontari di Generation Carbon coinvolgeranno bambini e bambine di tutte le età e le loro famiglie in divertenti attività sulla base della Guida al Cambiamento Climatico “Generation Carbon: è il momento di passare all’azione”.</li>
      <li><strong>10:30 - 12:00: La storia di principessa Foglietta e di come creò la festa della Terra</strong> a cura di Amalgama. In questo workshop fiabesco e magico, i bambini si immergeranno nella bellezza del paesaggio circostante, raccogliendo sassolini, foglie e fiori. Attraverso il contatto sensoriale con la natura, esploreranno l'ambiente circostante, diventando autentici esploratori. Successivamente, utilizzeranno gli elementi raccolti per creare una composizione organica, ispirata alla storia narrata durante l'esperienza. Un'occasione magica per connettersi con la natura e stimolare la creatività! <strong>Prenotazione.</strong></li>
      <li><strong>10:30 - 11:30: L’aiuola didattica delle farfalle</strong> a cura di Fondazione Zoom. I bambini che partecipano all’attività laboratoriale riceveranno una piantina e/o un semino per andare a costruire insieme ai ricercatori della Fondazione ZOOM un’aiuola temporanea, in grado di attirare le farfalle e gli impollinatori selvatici. I bambini creeranno, inoltre, una sagoma a forma di farfalla, e gli verrà raccontanto l'importante contributo degli impollinatori per la preservazione della biodiversità. <strong>Prenotazione.</strong></li>
      <li><strong>11:00 - 12:00: Attività circense</strong> a cura della Scuola di circo FLIC. Divertente attività per bambini a cura della Scuola circense FLIC in cui potranno sperimentare rudimenti di giocoleria e provare le discipline aeree. <strong>Prenotazione.</strong></li>
      <li><strong>12:15 - 13:15: Piovono polpette</strong> a cura di Eataly. Un laboratorio dedicato ai più piccoli in cui potranno mettere le manine in pasta per creare delle buonissime polpette, a partire da proteine vegetali. Ceci neri, lenticchie rosse, piselli e fagioli saranno alla base delle ricette in cui i piccoli Chef potranno cimentarsi in questo appuntamento. Per stimolare ancora di più l’olfatto e la vista i partecipanti andranno alla scoperta di spezie ed erbe aromatiche, alleate infallibili per questa preparazione. Durante l’attività i bimbi prepareranno delle polpette di legumi, spezie ed erbe aromatiche che potranno poi portare a casa e condividere in famiglia. <strong>Prenotazione.</strong></li>
      <li><strong>14:00 - 15:00: Lotta allo spreco</strong> a cura di Eataly. L'attività ha lo scopo di insegnare ai bambini il concetto di "spreco alimentare", imparando a valutarlo secondo diversi parametri e capire come e se possibile trasformare un cibo da scarto a risorsa. L'uso dei cinque sensi per analizzare un cibo apre le porte ad un mondo nuovo fatto di colori, aromi, consistenze, suoni e sapori che i bambini apprezzano con grande entusiasmo essendo chiamati in prima persona a immergere le “manine in pasta”. Durante l’attività i bimbi prepareranno dei tartufini con banane e cacao che potranno poi portare a casa e condividere in famiglia. Prenotazione</li>
      <li><strong>15:00 - 16:45: Pet Therapy</strong>, promossa dalla Fondazione Rubens, offre un'esperienza di interazione unica tra gli animali e gli individui, per migliorare il benessere emotivo, fisico e sociale. I cani addestrati saranno presenti per instaurare una connessione empatica e per offrire comfort e supporto, creando così un ambiente terapeutico e rilassante.</li>
      <li><strong>15:00 - 17:00: Kids in action</strong> a cura di Associazione Generation Carbon. Le volontarie ed i volontari di Generation Carbon coinvolgeranno bambini e bambine di tutte le età e le loro famiglie in divertenti attività sulla base della Guida al Cambiamento Climatico “Generation Carbon: è il momento di passare all’azione”.</li>
      <li><strong>16:00 - 17:00: L’aiuola didattica delle farfalle</strong> a cura di Fondazione Zoom. I bambini che partecipano all’attività laboratoriale riceveranno una piantina e/o un semino per andare a costruire insieme ai ricercatori della Fondazione ZOOM un’aiuola temporanea, in grado di attirare le farfalle e gli impollinatori selvatici. I bambini creeranno, inoltre, una sagoma a forma di farfalla, e gli verrà raccontanto l'importante contributo degli impollinatori per la preservazione della biodiversità. <strong>Prenotazione.</strong></li>
      <li><strong>16:30 - 18:00: La storia di principessa Foglietta e di come creò la festa della Terra.</strong> In questo workshop fiabesco e magico, i bambini si immergeranno nella bellezza del paesaggio circostante, raccogliendo sassolini, foglie e fiori. Attraverso il contatto sensoriale con la natura, esploreranno l'ambiente circostante, diventando autentici esploratori. Successivamente, utilizzeranno gli elementi raccolti per creare una composizione organica, ispirata alla storia.</li>
    </ul>`,
    zone: "yellow",
    image: kidsAreaImage,
  },
  {
    id: "wellness_area",
    qrId: "bevzcl",
    title: "Wellness Area",
    description:
      "L’area wellness offrirà laboratori e attività coinvolgenti per tutte le età. Riconosciamo l’importanza, proprio durante la giornata dedicata alla terra, di educare e sensibilizzare la popolazione ai temi del benessere psico-fisico, e della consapevolezza del proprio corpo connesso all’ambiente circostante.",
    schedule: `<ul>
      <li><strong>09:00 - 10:00: Yoga - Saluto al sole.</strong> L'attività di yoga offre un momento di calma e connessione con sé stessi. Guidati da insegnanti qualificati, avrai l'opportunità di praticare una serie di posture, movimenti e respiri consapevoli per rilassare la mente e rinvigorire il corpo. <strong>Prenotazione.</strong></li>
      <li><strong>10:00 - 18:00: YouthLAB Che Succ? Osservatorio giovanile.</strong> YouthLAB - Osservatorio Giovanile è il progetto di Club Silencio di ascolto finalizzato a promuovere e incentivare la partecipazione attiva dei giovani under 35 sui temi del benessere mentale. In collaborazione con MinD-Mad in Design, si propone di ridurre il stigma associato alla salute mentale e promuovere pratiche benefiche per creare una comunità più consapevole e attenta.</li>
      <li><strong>10:05 - 10:55: Master Yoga.</strong> Il Master Yoga con Alberto Jorge offre un'esperienza unica di apprendimento dello yoga con un'insegnante esperta. Sara guiderà i partecipanti in sessioni mirate per migliorare postura, respirazione e concentrazione, fornendo strumenti pratici per integrare lo yoga nella vita quotidiana. Grazie alla sua vasta esperienza e passione per lo yoga, Alberto Jorge ispirerà e accompagnerà i partecipanti nel loro percorso di crescita personale.</li>
      <li><strong>10:30 - 12:30: L’ambiente che vorrei.</strong> “L’ambiente che vorrei”: riflessioni critiche circa il contesto attuale e lo stato di benessere sociale ed individuale. Il team di esperti dello sportello "Ti Ascolto" accompagneranno i partecipanti a confrontarsi reciprocamente circa i vissuti individuali legati al tema dell’ambiente, della sfera personale e del contesto sociale attuale. <strong>Prenotazione.</strong></li>
      <li><strong>11:15 - 12:00: Pilates.</strong> Il Pilates offre un momento di rilassamento e rinvigorimento per mente e corpo. Guidati da istruttori esperti, avrail'opportunità di praticare esercizi mirati per migliorare la flessibilità, la forza e il benessere generale. <strong>Prenotazione.</strong></li>
      <li><strong>12:15 - 13:00: Danzamovimento Terapia.</strong> Un laboratorio di Danzamovimento terapia, dove il corpo diventa lo strumento principale per esplorare la propria natura. Attraverso pratiche specifiche, i partecipanti avranno l'opportunità di recuperare attitudini e capacità di "stare nel corpo", entrando in contatto con noi stessi e con gli altri in modo naturale e genuino.</li>
      <li><strong>13:05 - 13:55: Master Yoga.</strong> Il Master Yoga con Alberto Jorge offre un'esperienza unica di apprendimento dello yoga con un'insegnante esperta. Sara guiderà i partecipanti in sessioni mirate per migliorare postura, respirazione e concentrazione, fornendo strumenti pratici per integrare lo yoga nella vita quotidiana. Grazie alla sua vasta esperienza e passione per lo yoga, Alberto Jorge ispirerà e accompagnerà i partecipanti nel loro percorso di crescita personale.</li>
      <li><strong>14:00 - 15:00: Wake up! Presenta RE//CONNECT.</strong> Meditazioni guidate da Carlotta Scotti in arte Lottimedita, insegnante decennale di meditazione e autoindagine & sonorizzate da Michele Poli, medico psicoterapeuta musicoterapeuta e insegnante di hatha yoga. La meditazione sarà un mezzo per riconnettersi all’ambiente, alla natura e a tutto ciò che ci circonda, rievocando il senso di unità e di appartenenza che ci connette ad ogni angolo di realtà.</li>
      <li><strong>15:00 - 15:45: Danzamovimento Terapia.</strong> Un laboratorio di Danzamovimento terapia, dove il corpo diventa lo strumento principale per esplorare la propria natura. Attraverso pratiche specifiche, i partecipanti avranno l'opportunità di recuperare attitudini e capacità di "stare nel corpo", entrando in contatto con noi stessi e con gli altri in modo naturale e genuino.</li>
      <li><strong>16:00 - 17:00: Yoga.</strong> L'attività di yoga offre un momento di calma e connessione con sé stessi. Guidati da insegnanti qualificati, avrai l'opportunità di praticare una serie di posture, movimenti e respiri consapevoli per rilassare la mente e rinvigorire il corpo. <strong>Prenotazione.</strong></li>
      <li><strong>16:00 - 17:00: Attività circense per adulti.</strong> Divertente attività per adulti a cura della Scuola circense FLIC in cui si potranno sperimentare rudimenti di giocoleria e provare le discipline aeree. <strong>Prenotazione.</strong></li>
      <li><strong>17:00 - 18:00: Wake up! Presenta RE//CONNECT.</strong> Meditazioni guidate da Carlotta Scotti in arte Lottimedita, insegnante decennale di meditazione e autoindagine & sonorizzate da Michele Poli, medico psicoterapeuta musicoterapeuta e insegnante di hatha yoga. La meditazione sarà un viaggio introspettivo per stimolare la propria energia, la propria forza interiore, il proprio potenziale, l’abbondanza di cui tutti siamo fatti.</li>
    </ul>`,
    zone: "yellow",
    image: wellnessAreaImage,
  },
  {
    id: "earth_market",
    qrId: "bevzYh",
    title: "Earth Market",
    description:
      "L’Earth Market è un'area dedicata alla circolarità e a piccoli produttori del territorio con solidi valori sulla sostenibilità dei propri prodotti. E' un luogo dove conoscere nuove realtà, ma anche uno spazio per costruire comunità, scambio ed educazione.",
    schedule: `<ul>
      <li><strong>10:00 - 18:00: Bancarella di libri a cura della Libreria Luxemburg.</strong> La bancarella di libri curata dalla Libreria Luxemburg di Torino offre una selezione accurata di libri legati ai temi discussi durante la Giornata della Terra, creando un'opportunità unica per chi vorrà approfondire ulteriormente quanto discusso nei talk, laboratori e workshop. Sarà possibile acquistare i libri in loco.</li>
      <li><strong>10:00 - 18:00: Km0 Market a cura di Maestri del Gusto (Camera di Commercio).</strong> Il Km0 Market, organizzato da Maestri del Gusto di Torino e provincia, Torino DOC e Torino Cheese, con il supporto della Camera di Commercio di Torino, promuove e celebra i prodotti locali e a chilometro zero. Questo mercato offre una variegata selezione di prodotti freschi e di qualità provenienti direttamente dai produttori locali, che verranno presentati ai partecipanti attraverso piccoli assaggi e racconti.</li>
      <li><strong>10:00 - 11:00: Re-visioni: l'approccio modulare come tecnica di upcycling.</strong>	Il workshop di upcycling di vestiti promosso dalla Fondazione Pistoletto offre un'opportunità unica per imparare a trasformare capi di abbigliamento esistenti in nuovi pezzi creativi e sostenibili. Attraverso tecniche innovative e pratiche guidate da esperti del settore, i partecipanti avranno l'opportunità di esplorare il potenziale artistico e ambientale dell'upcycling, riducendo gli sprechi e dando vita a creazioni uniche e personalizzate. Il workshop mira non solo a promuovere la creatività individuale, ma anche a sensibilizzare sull'importanza della moda sostenibile e della riduzione dell'impatto ambientale nell'industria tessile. A cura di FLAVIA LA ROCCA Cittadellarte Fashion B.E.S.T.</li>
      <li><strong>11:00 - 12:00: Ma tu come mangi? Pillole per una spesa sostenibile</strong>, a cura di Anna Carlin, biologa nutrizionista. Il workshop "Ma tu come mangi?", condotto da Anna Carlin, offre preziosi consigli e suggerimenti per adottare un approccio sostenibile alla spesa alimentare. Attraverso una serie di pillole informative, i partecipanti saranno guidati a fare scelte consapevoli e rispettose dell'ambiente durante gli acquisti alimentari.</li>
      <li><strong>11:00 - 15:00: Accredito per swap di vestiti.</strong> Per partecipare all'attività di swap di vestiti organizzato da Swapush, dovrai portare al desk i tuoi capi, che verranno divisi per tipologia e stato.</li>
      <li><strong>12:00 - 13:00: Mini-talk su sostenibilità e circolarità.</strong> Mini talk dedicati a sostenibilità e circolarità: interverranno produttori Km0 nell'ambito enogastronomico e realtà affermate nell'ambito della moda circolare.</li>
      <li><strong>13:00 - 13:45: Show cooking a cura di Di Pazza powered by Kenwood.</strong> "Lo show cooking a cura di Di Pazza (Vegan Food Creator), powered by Kenwood, presenta Luca e Riccardo, coppia creativa e di vita che porta sui propri social ricette di piatti deliziosi e totalmente vegetali, spaziando tra diversi argomenti e affrontando anche temi importanti con una giusta dose di ironia. Durante la preparazione di oggi, con l'impastatrice planetaria Kenwood Titanium Chef Baker XL, si discuterà di temi importanti come scelte vegan e sostenibilità".</li>
      <li><strong>15:00 - 16:00: Ma tu come mangi? Pillole per una spesa sostenibile</strong>, a cura di Anna Carlin, biologa nutrizionista	Durante la preparazione di oggi, con l'impastatrice planetaria Kenwood Titanium Chef Baker XL, si discuterà di temi importanti come scelte vegan e sostenibilità.</li>
      <li><strong>16:00 - 17:00: Swap di vestiti a cura di Swapush.</strong> Un'esperienza di swap party organizzata da Swapush, un'app che promuove lo scambio di abiti in modo divertente e sostenibile. Partecipa al swap party, condividi i tuoi capi e trova nuovi pezzi per il tuo guardaroba, il tutto senza spendere nulla e nel rispetto dell'ambiente.</li>
      <li><strong>14:00 - 15:00: Re-visioni: l'approccio modulare come tecnica di upcycling.</strong> Il workshop di upcycling di vestiti promosso dalla Fondazione Pistoletto offre un'opportunità unica per imparare a trasformare capi di abbigliamento esistenti in nuovi pezzi creativi e sostenibili. Attraverso tecniche innovative e pratiche guidate da esperti del settore, i partecipanti avranno l'opportunità di esplorare il potenziale artistico e ambientale dell'upcycling, riducendo gli sprechi e dando vita a creazioni uniche e personalizzate. Il workshop mira non solo a promuovere la creatività individuale, ma anche a sensibilizzare sull'importanza della moda sostenibile e della riduzione dell'impatto ambientale nell'industria tessile.</li>
      <li><strong>10:00 - 18:00: Esposizione di capi second hand per bambini.</strong> Petit Deux Fois / baby seconde vie è un progetto che abbraccia i valori dell'economia circolare e della sostenibilità, offrendo un'attenta selezione di capi pre-loved baby in taglia 0/12 anni; in occasione della Giornata della Terra Valentina Camerucci, founder di Petit Deux Fois, ha curato un'esposizione di capi che raccontino l'anima del suo progetto in un'ottica di sviluppo sostenibile del Pianeta.</li>
    </ul>`,
    zone: "orange",
    image: earthMarketImage,
  },
  {
    id: "main_stage",
    qrId: "bevyt7",
    title: "Main Stage",
    description:
      "Lungo la giornata verranno accolti progetti artistici performativi nati con l'obiettivo di ripensare e portare in scena il tema dello sviluppo sostenibile e i suoni di alcuni artisti torinesi la cui ricerca sonora tenta di dialogare con gli obiettivi dell'agenda 2030. Troveranno spazio nella parte serale dell'evento momenti di intrattenimento, il concerto e dj set che coinvolgeranno artisti di fama nazionale.",
    schedule: `<ul>
      <li><strong>9:00 - 9:30: Rassegna stampa</strong> a cura di Life Gate con Maurizio Bongioanni e Matteo Suanno</li>
      <li><strong>9:35 - 9:45: Apertura dell'evento</strong>, con Alberto Ferrari (Club Silencio), Alessandro Armillotta (AWorld), Barbara Tuzzolino (Musei Reali)</li>
      <li><strong>11:15 - 12:00: Youth4Climate, un dialogo tra associazioni, organizzazioni e istituzioni sulla partecipazione giovanile alle soluzioni per il clima</strong>, con Francesco Corvaro (Inviato Speciale per il cambiamento climatico per il Governo italiano), Agostino Inguscio (Direttore UNDP Rome Centre), Paolo Rozera (Direttore UNICEF Italia), Movimento YOUNICEF. Modera Stefania Farina (AWorld)</li>
      <li><strong>15:30 - 15:45: "Jazz Street Parade</strong> con Diego Borotti (sax tenore), Stefano Cocon (tromba), Gianni Virone (sax basso), Stefano Calcagno (trombone), Ruben Bellavia (rullante, percussioni), Mattia Barbieri (grancassa, percussioni)"</li>
    </ul>`,
    zone: "pink",
    image: mainStageImage,
  },
]
