import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import FootprintFormDesktop from "../../components/footprint/FootprintFormDesktop"
import FootprintFormMobile from "../../components/footprint/FootprintFormMobile"
import { FootprintContext } from "../../controllers/footprint"
import { useLocation, useNavigate } from "react-router-dom"
import { registerEvent, registerPageEvent } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"

const FootprintForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile, currentMission, setCurrentMission } =
    useContext(MainContext)
  const {
    formsList,
    setGettingFootprint,
    getUserFootprint,
    getFormsList,
    getUserFormsList,
  } = useContext(FootprintContext)
  const { updateAll } = useContext(MissionsContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("footprint_form")
  }, [])

  // current form to complete
  const currentFormId = useMemo(() => {
    if (location.state && location.state.formId) {
      return location.state.formId
    }

    if (location.state && location.state.sector) {
      return formsList.find((form) => form.sector === location.state.sector)!
        .formId
    }

    if (formsList.some((form) => !form.isRefine)) {
      return formsList.find((form) => !form.isRefine)!.formId
    }

    return formsList[0].formId
  }, [formsList, location])

  // mission completed feedback
  const [missionFeedbackOpen, setMissionFeedbackOpen] = useState<boolean>(false)

  // on typeform submit function
  const onTypeformSubmit = () => {
    registerEvent("footprint_form_completed", {
      id: currentFormId,
    })

    // update current mission and show feedback
    setCurrentMission({
      ...currentMission!,
      current: 1,
    })
    setMissionFeedbackOpen(true)

    // update all and footprint
    updateAll(true)
    setGettingFootprint(true)
    setTimeout(async () => {
      await Promise.all([
        getUserFootprint(false),
        getFormsList(false),
        getUserFormsList(false),
      ])
      setGettingFootprint(false)
    }, 2000)
  }

  return isMobile ? (
    <FootprintFormMobile
      currentFormId={currentFormId}
      onTypeformSubmit={onTypeformSubmit}
      missionFeedbackOpen={missionFeedbackOpen}
    />
  ) : (
    <FootprintFormDesktop
      currentFormId={currentFormId}
      onTypeformSubmit={onTypeformSubmit}
      missionFeedbackOpen={missionFeedbackOpen}
    />
  )
}

export default FootprintForm
