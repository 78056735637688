import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { MissionType } from "../../../services/config/enum"
import actWhiteIcon from "../../../assets/icons/missions/missionsTypes/act-white.svg"
import learnWhiteIcon from "../../../assets/icons/missions/missionsTypes/learn-white.svg"
import measureWhiteIcon from "../../../assets/icons/missions/missionsTypes/measure-white.svg"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import PointsBadge from "../../global/common/PointsBadge"
import qrIconWhite from "../../../assets/icons/qr-white.svg"
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined"

const PointsHistoryListItem = ({
  title,
  time,
  points,
  type,
  forFullList = false,
}: {
  title: string
  time: string
  points: number
  type: MissionType
  forFullList?: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: "auto",
        position: "relative",
        gap: isMobile ? 4 : 0,
      }}
    >
      <div
        style={{
          width: isMobile ? 22 : 32,
          height: isMobile ? 22 : 32,
          borderRadius: "100%",
          backgroundColor:
            type === MissionType.TICKET
              ? colors.missions.act
              : type === MissionType.CHECKIN
              ? colors.primary
              : type
              ? (colors.missions as any)[type.toLowerCase()]
              : colors.backgroundWhite,
          position: "absolute",
          top: 0,
          left: forFullList ? 12 : isMobile ? 18 : 26,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {type === MissionType.TICKET ? (
          <ConfirmationNumberOutlinedIcon
            style={{ fontSize: isMobile ? 12 : 16, color: "white" }}
          />
        ) : (
          <img
            src={
              type === MissionType.CHECKIN
                ? qrIconWhite
                : type === MissionType.ACT
                ? actWhiteIcon
                : type === MissionType.LEARN
                ? learnWhiteIcon
                : measureWhiteIcon
            }
            style={{
              width:
                type === MissionType.MEASURE
                  ? isMobile
                    ? 8
                    : 13
                  : isMobile
                  ? 10
                  : 15,
            }}
            alt=""
          />
        )}
      </div>
      <Title
        fontSize={isMobile ? 14 : 20}
        lineHeight={isMobile ? "20px" : "28px"}
        paddingLeft={forFullList ? 62 : isMobile ? 55 : 80}
      >
        {t("you_completed_mission")}:
      </Title>
      {isMobile ? (
        <Text
          fontSize={12}
          lineHeight="17px"
          fontWeight={500}
          paddingLeft={55}
          style={{ width: "82%" }}
        >
          {title ?? "unknown"}
        </Text>
      ) : (
        <Title
          fontSize={18}
          lineHeight="24px"
          paddingLeft={forFullList ? 62 : 80}
          ellipsis
          style={{ width: "82%" }}
        >
          {title ?? "unknown"}
        </Title>
      )}
      <Text
        fontSize={isMobile ? 12 : 16}
        lineHeight={isMobile ? "17px" : "22px"}
        fontWeight={400}
        paddingLeft={forFullList ? 62 : isMobile ? 55 : 80}
        style={{
          marginTop: isMobile ? 0 : 5,
        }}
      >
        {`${
          new Date(time).toLocaleDateString() ===
          new Date().toLocaleDateString()
            ? t("today")
            : new Date(time).toLocaleDateString() ===
              new Date(
                new Date().setDate(new Date().getDate() - 1)
              ).toLocaleDateString()
            ? t("yesterday")
            : new Date(time).toLocaleDateString()
        } · ${new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`}
      </Text>
      {!forFullList && (
        <Text
          fontSize={isMobile ? 14 : 20}
          lineHeight={isMobile ? "20px" : "26px"}
          fontWeight={700}
          color={colors.primary}
          style={{ position: "absolute", top: 0, right: isMobile ? 18 : 26 }}
        >
          {points} {t("points")}
        </Text>
      )}
      {forFullList && (
        <PointsBadge
          points={points}
          style={{ position: "absolute", right: 5 }}
        />
      )}
    </Stack>
  )
}

export default PointsHistoryListItem
