import { Helmet } from "react-helmet"

const MetaPixelPageView = () => {
  return (
    <Helmet>
      <script>{`fbq('track', 'PageView');`}</script>
    </Helmet>
  )
}

export default MetaPixelPageView
