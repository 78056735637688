import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingMobile from "../../components/onboarding/OnboardingMobile"
import OnboardingDesktop from "../../components/onboarding/OnboardingDesktop"

const Onboarding = () => {
  const { isMobile } = useContext(MainContext)

  return isMobile ? <OnboardingMobile /> : <OnboardingDesktop />
}

export default Onboarding
