import { Dispatch, SetStateAction } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import { desktopMaxWidth } from "../../../services/config/constants"

const AlertFullScreen = ({
  open,
  setOpen,
  content,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  content: string
}) => {
  return (
    <FeedbackContainer open={open} setOpen={setOpen} withPadding={false}>
      <div
        style={{
          width: "100%",
          maxWidth: desktopMaxWidth,
          overflowY: "scroll",
          padding: 16,
          paddingBottom: 94,
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </FeedbackContainer>
  )
}

export default AlertFullScreen
