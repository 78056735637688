import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerLogoAlt from "../../assets/images/partner-logo.svg"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import ticketImage from "../../assets/images/ticket.png"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import AlertInfo from "../global/common/AlertInfo"
import MetaPixelPageView from "../global/common/MetaPixelPageView"

const GroupSelectionMobile = ({
  height,
  continueButtonWidth,
}: {
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const {
    windowHeight,
    isMobile,
    setViewTicketSelection,
    ticket,
    eventSlots,
    event,
    ticketAlreadyPresent,
  } = useContext(MainContext)

  // alerts
  const [ticketHelpAlertOpen, setTicketHelpAlertOpen] = useState<boolean>(false)
  const [timeSlotsAlertOpen, setTimeSlotsAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      <MetaPixelPageView />
      {/* content */}
      <Stack
        style={{
          width: "100%",
          maxWidth: "100%",
          minHeight: ticketAlreadyPresent
            ? 420
            : localStorage.getItem("fromApp")
            ? 700
            : 750,
          paddingTop: 26,
        }}
      >
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
            paddingBottom: 113,
          }}
          alignItems="center"
        >
          {/* header */}
          <FadeFromTop>
            <img
              src={partnerLogoAlt}
              style={{ height: 35 }}
              alt={t("partner_logo_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {ticketAlreadyPresent ? t("warning") : t("yout_ticket")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={400}
              component="h2"
            >
              {ticketAlreadyPresent ? (
                <Trans i18nKey="there_is_already_a_ticket" />
              ) : (
                <Trans i18nKey="you_will_receive_the_ticket">
                  Ti abbiamo inviato il{" "}
                  <strong style={{ fontWeight: 600 }}>biglietto</strong> via
                  email.
                  <br />
                  Lo trovi sempre disponibile anche dal menu di questo sito
                </Trans>
              )}
            </Text>
          </FadeFromTop>
          {!ticketAlreadyPresent && (
            <FadeFromTop delay={0.12} style={{ marginTop: 30 }}>
              <Text fontSize={16} textAlign="center" fontWeight={600}>
                {t("we_wait")}
              </Text>
            </FadeFromTop>
          )}
          {/* content */}
          {!ticketAlreadyPresent && (
            <FadeFromTop
              delay={0.15}
              style={{
                width: "100%",
                maxWidth: continueButtonWidth,
                marginTop: 20,
              }}
            >
              <Stack alignItems="center" style={{ width: "100%" }}>
                <img
                  src={ticketImage}
                  style={{ width: 300 }}
                  alt={t("your_ticket")}
                />
                <Text
                  fontSize={16}
                  style={{ width: 300, maxWidth: 300, marginTop: 20 }}
                >
                  <strong style={{ fontWeight: 600 }}>N°:</strong>{" "}
                  {parseInt(ticket!.campi_aggiuntivi.minori) + 1}{" "}
                  {parseInt(ticket!.campi_aggiuntivi.minori) + 1 === 1
                    ? "biglietto"
                    : "biglietti"}
                  <br />
                  <strong style={{ fontWeight: 600 }}>Data:</strong> 20 aprile
                  2024
                  <br />
                  <strong style={{ fontWeight: 600 }}>Luogo:</strong> Piazzetta
                  Reale, 1 - Torino (Cancellata di Palazzo Reale)
                  <br />
                  <strong style={{ fontWeight: 600 }}>
                    Fascia oraria:
                  </strong>{" "}
                  {eventSlots
                    .find((item) => item.id === ticket?.id_fascia)
                    ?.ora_inizio.slice(0, 5) +
                    " - " +
                    eventSlots
                      .find((item) => item.id === ticket?.id_fascia)
                      ?.ora_fine.slice(0, 5)}
                </Text>
                <ButtonBase
                  disableRipple
                  style={{
                    width: "fit-content",
                    height: 22,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 36,
                    alignSelf: "flex-start",
                  }}
                  aria-label={t("didnt_receive_ticket")}
                  onClick={() => {
                    setTicketHelpAlertOpen(true)
                  }}
                >
                  <img
                    src={infoIconPrimary}
                    style={{ width: 16, height: 16 }}
                    alt=""
                  />
                  <Text
                    fontSize={14}
                    lineHeight="16px"
                    fontWeight={700}
                    style={{ marginLeft: 5 }}
                    color={colors.primary}
                  >
                    {t("didnt_receive_ticket")}
                  </Text>
                </ButtonBase>
                <ButtonBase
                  disableRipple
                  style={{
                    width: "fit-content",
                    height: 22,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 4,
                    alignSelf: "flex-start",
                  }}
                  aria-label={t("why_do_i_have_slots")}
                  onClick={() => {
                    setTimeSlotsAlertOpen(true)
                  }}
                >
                  <img
                    src={infoIconPrimary}
                    style={{ width: 16, height: 16 }}
                    alt=""
                  />
                  <Text
                    fontSize={14}
                    lineHeight="16px"
                    fontWeight={700}
                    style={{ marginLeft: 5 }}
                    color={colors.primary}
                  >
                    {t("why_do_i_have_slots")}
                  </Text>
                </ButtonBase>
              </Stack>
            </FadeFromTop>
          )}
        </Stack>
      </Stack>
      {/* buttons */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "100%",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack
          gap={1}
          style={{ width: continueButtonWidth ?? "calc(100% - 32px)" }}
        >
          <Button
            title={t("continue")}
            width="100%"
            onClick={() => {
              setViewTicketSelection(false)
            }}
          >
            {t("continue")}
          </Button>
          {localStorage.getItem("fromApp") || ticketAlreadyPresent ? null : (
            <Button
              title={t("share_event")}
              outlined
              width="100%"
              onClick={() => {
                window.open(event!.link)
              }}
            >
              {t("share_event")}
            </Button>
          )}
          {ticketAlreadyPresent && (
            <Button
              title={t("get_new_ticket")}
              outlined
              width="100%"
              onClick={() => {
                localStorage.clear()
                window.location.reload()
              }}
            >
              {t("get_new_ticket")}
            </Button>
          )}
        </Stack>
      </FadeFromBottom>
      {/* alerts */}
      <AlertInfo
        open={timeSlotsAlertOpen}
        setOpen={setTimeSlotsAlertOpen}
        title={t("why_do_i_have_slots")}
        description={t("time_slots_description")}
        descriptionAlign="center"
        withAvatar={false}
      />
      <AlertInfo
        open={ticketHelpAlertOpen}
        setOpen={setTicketHelpAlertOpen}
        title={t("didnt_receive_ticket")}
        description={t("didnt_receive_ticket_description")}
        descriptionAlign="center"
        withAvatar={false}
      />
    </Stack>
  )
}

export default GroupSelectionMobile
