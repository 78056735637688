import { ButtonBase, LinearProgress, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import settingsIcon from "../../assets/icons/settings.svg"
import { useNavigate } from "react-router-dom"
import Text from "../global/common/Text"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightIcon from "../../assets/icons/chevron-right.svg"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import PointsHistoryListItem from "./common/PointsHistoryListItem"
import { scrollWindowToTop } from "../../services/utils/utils"
import { FootprintContext } from "../../controllers/footprint"
import errorIcon from "../../assets/icons/error.svg"
import { MissionsContext } from "../../controllers/missions"
import { MainContext } from "../../controllers/main"
import FadeFromTop from "../animations/FadeFromTop"
import { defaultBoxShadow } from "../../services/config/constants"
import { MissionSubType } from "../../services/config/enum"
import { MarketplaceContext } from "../../controllers/marketplace"

const PerformanceMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, setCurrentMission } = useContext(MainContext)
  const { formsList, userFootprint } = useContext(FootprintContext)
  const {
    missions,
    missionsHistory,
    missionsHistoryUpdating,
    missionsHistoryNextToken,
    getMissionsHistory,
    completedMissionsCount,
  } = useContext(MissionsContext)
  const { getProducts } = useContext(MarketplaceContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
        paddingBottom: 22,
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/", { state: { fromPerformance: true } })
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("performance")}
        </Title>
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("settings")}
        >
          <img src={settingsIcon} style={{ width: 24, height: 24 }} alt="" />
        </ButtonBase>
      </Stack>
      {/* user info */}
      <FadeFromTop
        style={{
          width: "calc(100% - 32px)",
          marginTop: 6,
          position: "relative",
          marginLeft: 16,
        }}
      >
        <img
          src={user?.profileImage}
          style={{
            width: 78,
            height: 78,
            borderRadius: "100%",
            objectFit: "cover",
            objectPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            marginInline: "auto",
            boxShadow: defaultBoxShadow,
          }}
          alt=""
        />
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: "auto",
            marginTop: 39,
            borderRadius: 10,
            backgroundColor: colors.backgroundWhite,
            boxShadow: defaultBoxShadow,
            paddingTop: 48,
            overflow: "hidden",
          }}
        >
          <Title
            fontSize={18}
            lineHeight="22px"
            color={colors.primary}
            ellipsis
            paddingLeft={16}
            paddingRight={16}
            style={{ maxWidth: "100%" }}
          >
            {user?.first_name} {user?.last_name}
          </Title>
          <Stack
            direction="row"
            alignItems="center"
            style={{ width: "100%", marginTop: 18 }}
          >
            <Stack alignItems="center" style={{ width: "50%", gap: 10 }}>
              <Title fontSize={22} lineHeight="26px" color={colors.primary}>
                {completedMissionsCount}
              </Title>
              <Title
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                textAlign="center"
                width={80}
              >
                {completedMissionsCount === 1
                  ? t("completed_mission").toUpperCase()
                  : t("completed_missions").toUpperCase()}
              </Title>
            </Stack>
            <div
              style={{
                width: 1,
                minWidth: 1,
                height: 50,
                backgroundColor: colors.primary,
                borderRadius: 10,
              }}
            />
            <Stack alignItems="center" style={{ width: "50%", gap: 10 }}>
              <Title fontSize={22} lineHeight="26px" color={colors.primary}>
                {user?.points}
              </Title>
              <Title
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                textAlign="center"
                width={80}
              >
                {t("available_points").toUpperCase()}
              </Title>
            </Stack>
          </Stack>
          <ButtonBase
            style={{
              width: "100%",
              height: 50,
              backgroundColor: colors.primary,
              marginTop: 18,
              color: colors.textWhite,
            }}
            aria-label={t("go_to_marketplace")}
            onClick={() => {
              getProducts()
              navigate("/store")
            }}
          >
            <Title fontSize={16} color={colors.textWhite}>
              {t("go_to_marketplace")}
            </Title>
          </ButtonBase>
        </Stack>
      </FadeFromTop>
      {!userFootprint &&
      !missions.some(
        (mission) => mission.missionSubType === MissionSubType.TYPEFORM_BASE
      ) ? null : (
        <AppearFromSide delay={0.05} fade style={{ marginTop: 32 }}>
          <ButtonBase
            disabled={
              !userFootprint &&
              !missions.some(
                (mission) =>
                  mission.missionSubType === MissionSubType.TYPEFORM_BASE
              )
            }
            style={{
              width: "calc(100% - 32px)",
              marginLeft: 16,
              borderRadius: 10,
            }}
            onClick={() => {
              scrollWindowToTop()
              if (
                formsList.length &&
                formsList.some((item) => !item.isRefine)
              ) {
                const missionToSet = missions.find(
                  (mission) =>
                    mission.missionSubType === MissionSubType.TYPEFORM_BASE
                )!
                setCurrentMission(missionToSet)
                localStorage.setItem(
                  "currentMission",
                  JSON.stringify(missionToSet)
                )
                navigate("/performance/footprint/form")
              } else {
                navigate("/performance/footprint")
              }
            }}
            aria-label={t("your_carbon_footprint")}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 45,
                paddingInline: 12,
                borderRadius: 10,
                boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                gap: 10,
                position: "relative",
              }}
            >
              <img
                src={footprintDoughnutImage}
                style={{ width: 33, height: 30 }}
                alt=""
              />
              <Text fontSize={14} fontWeight={700} lineHeight="20px">
                {t("your_carbon_footprint")}
              </Text>
              <img
                src={chevronRightIcon}
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 12,
                }}
                alt=""
              />
              {!userFootprint ? (
                <img
                  src={errorIcon}
                  style={{
                    width: 22,
                    height: 22,
                    position: "absolute",
                    right: 48,
                  }}
                  alt={t("footprint_empty_state")}
                />
              ) : null}
            </Stack>
          </ButtonBase>
        </AppearFromSide>
      )}
      {/* content */}
      <AppearFromSide delay={0.15} fade style={{ marginTop: 32 }}>
        {/* completed missions */}
        <Stack
          style={{
            width: "100%",
            paddingInline: 16,
          }}
        >
          <Title fontSize={18} lineHeight="21px" component="h2">
            {t("completed_missions")}
          </Title>
          {completedMissionsCount ? (
            <Text
              fontSize={12}
              lineHeight="14px"
              fontWeight={500}
              style={{ marginTop: 12 }}
            >
              {completedMissionsCount}{" "}
              {completedMissionsCount === 1 ? t("mission") : t("missions")}
            </Text>
          ) : null}
          {!completedMissionsCount ? (
            <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
              {t("points_history_empty_state")}.
            </Text>
          ) : (
            <Stack
              id="missions-history-container"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: 396.5,
                borderRadius: 10,
                boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                marginTop: 12,
                gap: 18,
                paddingTop: 18,
                paddingBottom: 18,
                overflowY: "scroll",
                position: "relative",
              }}
              onScroll={() => {
                const container = document.getElementById(
                  "missions-history-container"
                )

                if (
                  container!.scrollTop + 224 + 400 >= container!.scrollHeight &&
                  missionsHistoryNextToken &&
                  !missionsHistoryUpdating
                ) {
                  getMissionsHistory(false)
                }
              }}
            >
              {missionsHistory.map((item, index) => (
                <Stack key={index} style={{ gap: 18 }}>
                  {index > 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: 1.5,
                        backgroundColor: colors.backgroundLight,
                      }}
                    />
                  )}
                  <PointsHistoryListItem
                    title={item.title}
                    time={item.updatedAt!}
                    points={item.points}
                    type={item.missionType}
                  />
                </Stack>
              ))}
              {missionsHistoryUpdating && (
                <LinearProgress
                  style={{
                    width: "calc(100% - 32px)",
                    position: "fixed",
                    bottom: 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
      </AppearFromSide>
    </Stack>
  )
}

export default PerformanceMobile
