import { gql } from "@apollo/client"

export const productsList = gql`
  query ProductsList($input: ListProductsInput) {
    productsList(input: $input) {
      items {
        id
        handle
        image
        sponsoredBy
        prices {
          amount
          currency
        }
        document {
          title
          body {
            ... on BodyTextSlice {
              sliceType
              text
            }
          }
        }
        inventoryCount
        boughtCount
        canBuy
        available
        defaultCurrency
        type
        limitPerUser
        inventoryCount
      }
    }
  }
`

export const ordersList = gql`
  query OrdersList($input: ListOrdersInput) {
    ordersList(input: $input) {
      items {
        id
        price {
          amount
          currency
        }
        products {
          product {
            id
            handle
            image
            sponsoredBy
            prices {
              amount
              currency
            }
            document {
              title
              body {
                ... on BodyTextSlice {
                  sliceType
                  text
                }
              }
            }
            inventoryCount
            boughtCount
            canBuy
            available
            defaultCurrency
            type
            limitPerUser
            inventoryCount
          }
          item {
            itemId
          }
        }
        status
        updatedAt
      }
    }
  }
`
