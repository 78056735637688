import { Dispatch, SetStateAction, useContext } from "react"
import { colors } from "../../../services/config/colors"
import { Grow, Modal, Stack } from "@mui/material"
import { MainContext } from "../../../controllers/main"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"

const ProductGotAlert = ({
  open,
  setOpen,
  points,
  setProductDialogOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  points: number
  setProductDialogOpen?: Dispatch<SetStateAction<boolean>>
}) => {
  const { user, isMobile } = useContext(MainContext)
  const navigate = useNavigate()

  return (
    <Modal
      open={open}
      aria-labelledby={t("here_is_your_prize")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
            paddingTop: user && user.profileImage ? 72 : 24,
            position: "relative",
          }}
        >
          {user && user.profileImage ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
              }}
              alt=""
            />
          ) : null}
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
            id={t("here_is_your_prize")}
          >
            {t("here_is_your_prize")}
          </Title>
          <Text
            fontSize={12}
            lineHeight="24px"
            fontWeight={400}
            style={{ marginTop: 24 }}
          >
            {t("points_used").toUpperCase()}
          </Text>
          <PointsBadge points={points} style={{ marginTop: 4 }} />
          <div
            dangerouslySetInnerHTML={{ __html: t("price_got_description") }}
            style={{
              fontSize: 18,
              lineHeight: "24px",
              fontWeight: 400,
              textAlign: "center",
              marginTop: 24,
            }}
          />
          <Button
            title={t("back_to_store")}
            width="100%"
            style={{ marginTop: 24 }}
            onClick={() => {
              if (isMobile) {
                navigate("/store")
              } else {
                setOpen(false)
                if (setProductDialogOpen) {
                  setProductDialogOpen(false)
                }
              }
            }}
          >
            {t("back_to_store")}
          </Button>
        </Stack>
      </Grow>
    </Modal>
  )
}

export default ProductGotAlert
