import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import vechainLogo from "../../assets/images/vechain-logo.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import FadeFromTop from "../animations/FadeFromTop"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { defaultBoxShadow } from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import TransactionCardMobile from "./mobile/TransactionCardMobile"
import Button from "../global/common/Button"
import FadeFromBottom from "../animations/FadeFromBottom"
import { Trans } from "react-i18next"
import AlertInfo from "../global/common/AlertInfo"
import { MarketplaceContext } from "../../controllers/marketplace"

const WalletMobile = ({
  vechainAlertOpen,
  setVechainAlertOpen,
}: {
  vechainAlertOpen: boolean
  setVechainAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { orders } = useContext(MarketplaceContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        paddingBottom: 122,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/store")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("your_wallet")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      <ButtonBase
        disableRipple
        style={{
          width: "fit-content",
          height: 22,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 8,
        }}
        aria-label={t("powered_by") + " Vechain"}
        onClick={() => {
          setVechainAlertOpen(true)
        }}
      >
        <img src={infoIconPrimary} style={{ width: 16, height: 16 }} alt="" />
        <Text
          fontSize={14}
          lineHeight="16px"
          fontWeight={700}
          style={{ marginLeft: 5 }}
          color={colors.primary}
        >
          {t("powered_by")}
        </Text>
        <img
          src={vechainLogo}
          style={{ height: 24, marginLeft: -1 }}
          alt="Vechain"
        />
      </ButtonBase>
      {/* points card */}
      <FadeFromTop
        style={{
          width: "calc(100% - 32px)",
          marginTop: 22,
          position: "relative",
        }}
      >
        <img
          src={user?.profileImage}
          style={{
            width: 78,
            height: 78,
            borderRadius: "100%",
            objectFit: "cover",
            objectPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            marginInline: "auto",
            boxShadow: defaultBoxShadow,
          }}
          alt=""
        />
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: "auto",
            marginTop: 39,
            borderRadius: 10,
            backgroundColor: colors.backgroundWhite,
            boxShadow: defaultBoxShadow,
            paddingTop: 48,
            paddingBottom: 20,
            overflow: "hidden",
          }}
        >
          <Title
            fontSize={18}
            lineHeight="22px"
            color={colors.primary}
            ellipsis
            paddingLeft={16}
            paddingRight={16}
            style={{ maxWidth: "100%" }}
          >
            {user?.first_name} {user?.last_name}
          </Title>
          <Stack
            direction="row"
            alignItems="center"
            style={{ width: "100%", marginTop: 18 }}
          >
            <Stack alignItems="center" style={{ width: "50%", gap: 10 }}>
              <Title fontSize={22} lineHeight="26px" color={colors.primary}>
                {user?.points}
              </Title>
              <Title
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                textAlign="center"
                width={120}
                color={colors.textSecondary}
              >
                {t("available_points").toUpperCase()}
              </Title>
            </Stack>
            <div
              style={{
                width: 1,
                minWidth: 1,
                height: 50,
                backgroundColor: colors.primary,
                borderRadius: 10,
              }}
            />
            <Stack alignItems="center" style={{ width: "50%", gap: 10 }}>
              <Title fontSize={22} lineHeight="26px" color={colors.primary}>
                {orders.reduce(
                  (accumulator, item) => accumulator + item.price.amount,
                  0
                )}
              </Title>
              <Title
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                textAlign="center"
                width={120}
                color={colors.textSecondary}
              >
                {t("points_used").toUpperCase()}
              </Title>
            </Stack>
          </Stack>
        </Stack>
      </FadeFromTop>
      {/* retrieved products */}
      <AppearFromSide
        fade
        style={{ width: "calc(100% - 32px)", marginTop: 30 }}
      >
        <Title fontSize={16}>{t("retrieved_prizes")}</Title>
      </AppearFromSide>
      {orders.length ? (
        <Stack style={{ width: "calc(100% - 32px)", marginTop: 24, gap: 14 }}>
          {orders.map((item, index) => (
            <Stack key={index} style={{ width: "100%", gap: 14 }}>
              <AppearFromSide fade delay={0.05 * (index + 1)}>
                <TransactionCardMobile
                  product={item.products[0].product}
                  updatedAt={item.updatedAt}
                  status={item.status}
                />
              </AppearFromSide>
              {index !== orders.length - 1 ? (
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: colors.primary,
                    borderRadius: 10,
                  }}
                />
              ) : null}
            </Stack>
          ))}
        </Stack>
      ) : (
        <FadeFromTop delay={0.1} style={{ marginTop: 24 }}>
          <Text fontSize={16} fontWeight={400} textAlign="center">
            <Trans i18nKey="transactions_empty_state">
              Non hai ancora ottenuto premi,
              <br />
              inizia ora!
            </Trans>
          </Text>
        </FadeFromTop>
      )}
      {/* back button */}
      <FadeFromBottom
        style={{ width: "calc(100% - 32px)", position: "fixed", bottom: 22 }}
      >
        <Button
          title={t("back_to_store")}
          width="100%"
          onClick={() => {
            navigate("/store")
          }}
        >
          {t("back_to_store")}
        </Button>
      </FadeFromBottom>
      {/* alerts */}
      <AlertInfo
        open={vechainAlertOpen}
        setOpen={setVechainAlertOpen}
        title={t("vechain_alert_title")}
        description={t("vechain_alert_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default WalletMobile
