import { gql } from "@apollo/client"

export const orderCreate = gql`
  mutation OrderCreate($input: CreateOrderInput!) {
    orderCreate(input: $input) {
      id
    }
  }
`

export const orderConfirmEmailResend = gql`
  mutation OrderConfirmEmailResend($input: ResendOrderConfirmEmailInput!) {
    orderConfirmEmailResend(input: $input) {
      orderId
      productId
      itemId
      emailRequested
    }
  }
`
