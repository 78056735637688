import { ButtonBase, Modal, Slide, Stack } from "@mui/material"
import { t } from "i18next"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import closeIconWhite from "../../../assets/icons/close-white.svg"
import { useSwipeable } from "react-swipeable"
import ticketFullImage from "../../../assets/images/ticket-full.png"

const TicketModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { windowHeight, ticket, isMobile } = useContext(MainContext)

  // swipe to close handler
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Down" && eventData.absY > 100) {
        setOpen(false)
      }
    },
  })

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby={t("ticket")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{
            width: "100%",
            height: windowHeight,
            backgroundColor: "#1C3965",
            position: "relative",
          }}
          {...handlers}
        >
          <ButtonBase
            aria-label={t("close")}
            style={{
              width: 26,
              height: 26,
              position: "absolute",
              top: 11,
              left: 11,
              borderRadius: "100%",
              zIndex: 1,
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            <img
              src={closeIconWhite}
              style={{ width: 16, height: 16 }}
              alt=""
            />
          </ButtonBase>
          <div
            className="center"
            style={{
              width: 375,
              height: 666.5,
              position: "absolute",
              bottom: isMobile ? -20 : undefined,
            }}
          >
            <img
              src={ticketFullImage}
              style={{
                width: "100%",
                height: "100%",
              }}
              alt={t("yout_ticket")}
            />
            <img
              src={ticket?.qr}
              style={{
                width: 196,
                height: 196,
                position: "absolute",
                bottom: 146,
              }}
              alt=""
            />
          </div>
        </Stack>
      </Slide>
    </Modal>
  )
}

export default TicketModal
