import { ButtonBase, Stack } from "@mui/material"
import {
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { colors } from "../../services/config/colors"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Button from "../global/common/Button"
import AvatarButton from "./common/AvatarButton"
import { scrollWindowToTop } from "../../services/utils/utils"

const SettingsDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, avatars, changeAvatar, setViewTutorial } =
    useContext(MainContext)

  // loading for avatar change
  const [loading, setLoading] = useState<boolean>(false)

  // selected avatar
  const [selectedAvatar, setSelectedAvatar] = useState<string>(
    user!.profileImage
  )

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: `calc(100% - ${footerHeight}px)`,
      }}
    >
      <Stack style={{ width: "100%", backgroundColor: colors.backgroundWhite }}>
        {/* navbar background */}
        <div
          style={{
            width: "100%",
            height: navBarHeight,
            backgroundColor: colors.background,
          }}
        />
        {/* breadcrumb */}
        <BreadcrumbDesktop
          items={[
            {
              label: t("performance"),
              onClick: () => {
                navigate("/performance")
              },
            },
            { label: t("settings") },
          ]}
          selectedItem={1}
          style={{ marginTop: 22 }}
        />
        {/* main container */}
        <PaddingContainerDesktop>
          <Stack
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            {/* header */}
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{ marginTop: 22 }}
                component="h1"
              >
                {t("settings")}
              </Title>
            </AppearFromSide>
            <Stack
              alignItems="center"
              style={{
                width: "57.14%",
                height: "auto",
                marginTop: 28,
                marginBottom: 54,
                gap: 32,
              }}
            >
              {/* avatars */}
              <AppearFromSide fade delay={0.05} style={{ width: "100%" }}>
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: colors.backgroundWhite,
                    borderRadius: 16,
                    boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                    paddingTop: 22,
                    paddingBottom: 22,
                    paddingInline: 28,
                  }}
                >
                  <Title fontSize={20} component="h2">
                    {t("avatar")}
                  </Title>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(0, 5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={
                          user!.profileImage === avatar.url ? true : false
                        }
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={
                          user!.profileImage === avatar.url ? true : false
                        }
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    style={{ alignSelf: "flex-end", marginTop: 24, gap: 8 }}
                  >
                    <Button
                      width={150}
                      style={{ borderRadius: 12 }}
                      title={t("cancel")}
                      outlined
                      disabled={
                        selectedAvatar === user?.profileImage || loading
                      }
                      onClick={() => {
                        setSelectedAvatar(user!.profileImage)
                      }}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      width={150}
                      style={{ borderRadius: 12 }}
                      title={t("save_change")}
                      disabled={selectedAvatar === user?.profileImage}
                      loading={loading}
                      onClick={async () => {
                        setLoading(true)

                        await changeAvatar(selectedAvatar)

                        setLoading(false)
                      }}
                    >
                      {t("save_change")}
                    </Button>
                  </Stack>
                </Stack>
              </AppearFromSide>
              {/* tutorial */}
              <MenuButton
                title="Tutorial"
                delay={0.1}
                onClick={() => {
                  scrollWindowToTop()
                  navigate("/")
                  setViewTutorial(true)
                }}
              />
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </Stack>
    </Stack>
  )
}

const MenuButton = ({
  title,
  onClick,
  delay,
}: {
  title: string
  onClick: () => void
  delay?: number
}) => {
  return (
    <AppearFromSide fade delay={delay ?? 0} style={{ width: "100%" }}>
      <ButtonBase
        style={{ width: "100%", borderRadius: 16 }}
        aria-label={title}
        onClick={onClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 71,
            backgroundColor: colors.backgroundWhite,
            borderRadius: 16,
            boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
            paddingInline: 28,
            position: "relative",
          }}
        >
          <Title fontSize={20} component="h2">
            {title}
          </Title>
          <img
            src={chevronRightPrimaryIcon}
            style={{ width: 11, position: "absolute", right: 32 }}
            alt=""
          />
        </Stack>
      </ButtonBase>
    </AppearFromSide>
  )
}

export default SettingsDesktop
