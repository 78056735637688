import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import TicketSelectionMobile from "../../components/onboarding/TicketSelectionMobile"
import TicketSelectionDesktop from "../../components/onboarding/TicketSelectionDesktop"

const TicketSelection = () => {
  const { isMobile } = useContext(MainContext)

  return isMobile ? <TicketSelectionMobile /> : <TicketSelectionDesktop />
}

export default TicketSelection
