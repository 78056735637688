import { Stack } from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import TicketSelectionMobile from "./TicketSelectionMobile"

const GroupSelectionDesktop = () => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <TicketSelectionMobile height="100%" continueButtonWidth={330} />
    </Stack>
  )
}

export default GroupSelectionDesktop
