import { Grow, Modal, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import Button from "../../global/common/Button"
import { t } from "i18next"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"

const CheckInMissionCompletedFeedback = ({
  open,
  setOpen,
  areaName,
  points,
  allMissionsCompleted,
  fromQr = false,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  areaName: string
  points: number
  allMissionsCompleted: boolean
  fromQr?: boolean
}) => {
  const { user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={
        allMissionsCompleted ? t("mission_completed") : t("qr_code_scanned")
      }
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
            paddingTop: user && user.profileImage ? 72 : 24,
            position: "relative",
          }}
        >
          {user && user.profileImage ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
              }}
              alt=""
            />
          ) : null}
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
            id={
              allMissionsCompleted
                ? t("event_missions_completed")
                : t("event_mission_completed")
            }
          >
            {allMissionsCompleted
              ? t("event_missions_completed")
              : t("event_mission_completed")}
          </Title>
          <Stack alignItems="center" style={{ marginTop: 14 }}>
            {allMissionsCompleted ? (
              <Text
                fontSize={18}
                lineHeight="24px"
                fontWeight={400}
                textAlign="center"
                style={{ width: 210 }}
              >
                {t("alL_event_missions_completed")}
              </Text>
            ) : (
              <Text fontSize={18} lineHeight="24px" fontWeight={400}>
                {t("you_visited_the_area")}
              </Text>
            )}
            {!allMissionsCompleted && (
              <Text
                fontSize={18}
                lineHeight="24px"
                fontWeight={600}
                textAlign="center"
                style={{ marginTop: allMissionsCompleted ? 24 : 0 }}
              >
                "{allMissionsCompleted ? t("event_description") : areaName}"
              </Text>
            )}
            {!allMissionsCompleted && (
              <Text
                fontSize={12}
                lineHeight="24px"
                fontWeight={400}
                style={{ marginTop: allMissionsCompleted ? 24 : 14 }}
              >
                {t("points_earned").toUpperCase()}
              </Text>
            )}
            {!allMissionsCompleted && (
              <PointsBadge points={points} style={{ marginTop: 4 }} />
            )}
            <Text
              fontSize={18}
              lineHeight="24px"
              fontWeight={400}
              textAlign="center"
              style={{ width: 280, marginTop: allMissionsCompleted ? 30 : 14 }}
            >
              {allMissionsCompleted
                ? t("now_you_can_view_program")
                : t("contribute_to_event_mission")}
            </Text>
          </Stack>
          <Button
            width="100%"
            style={{ marginTop: 24 }}
            title={
              fromQr
                ? t("continue")
                : allMissionsCompleted
                ? t("back_to_missions")
                : t("back_to_program")
            }
            onClick={() => {
              setOpen(false)
            }}
          >
            {fromQr ? t("continue") : t("back_to_program")}
          </Button>
        </div>
      </Grow>
    </Modal>
  )
}

export default CheckInMissionCompletedFeedback
