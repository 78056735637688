import { ButtonBase } from "@mui/material"
import Category from "../../../models/category"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const CategoryFilterBadge = ({
  category,
  selected,
  onClick,
}: {
  category: Category
  selected: boolean
  onClick: () => void
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      style={{
        width: "auto",
        height: isMobile ? 32 : 40,
        paddingInline: 12,
        borderRadius: 5,
        backgroundColor: selected ? category.backTagColor : colors.background,
        transition: "180ms",
      }}
      onClick={onClick}
      aria-label={category.name}
      role="radio"
      aria-checked={selected ? "true" : "false"}
    >
      <Text
        fontSize={isMobile ? 14 : 16}
        lineHeight={isMobile ? "16px" : "18px"}
        fontWeight={isMobile ? 600 : 700}
        color={selected ? category.foreColor : colors.textSecondary}
        style={{ whiteSpace: "nowrap", transition: "180ms" }}
        ariaHidden
      >
        {category.name}
      </Text>
    </ButtonBase>
  )
}

export default CategoryFilterBadge
