import { useContext } from "react"
import { MainContext } from "./controllers/main"
import Error from "./views/public/error"
import Loading from "./views/public/loading"
import Onboarding from "./views/public/onboarding"
import TicketSelection from "./views/public/ticketSelection"
import Main from "./views/private/main"
import AvatarSelection from "./views/public/avatarSelection"
import AmplifySignIn from "./views/public/amplifySignIn"
import TicketError from "./views/public/ticketError"

const App = () => {
  const {
    loading,
    signInError,
    slotsError,
    ticketError,
    userError,
    viewOnboarding,
    viewAmplifySignIn,
    viewTicketSelection,
    viewAvatarSelection,
  } = useContext(MainContext)

  if (signInError || userError || slotsError) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: signInError },
          { name: "User", error: userError },
          { name: "Slots", error: slotsError },
        ]}
      />
    )
  }

  if (ticketError) {
    return <TicketError />
  }

  if (loading) {
    return <Loading />
  }

  if (viewOnboarding) {
    return <Onboarding />
  }

  if (viewAmplifySignIn) {
    return <AmplifySignIn />
  }

  if (viewTicketSelection) {
    return <TicketSelection />
  }

  if (viewAvatarSelection) {
    return <AvatarSelection />
  }

  return <Main />
}

export default App
