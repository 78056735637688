import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import JourneyMobile from "../../components/journey/JourneyMobile"
import JourneyDesktop from "../../components/journey/JourneyDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"

const Journey = () => {
  const navigate = useNavigate()
  const { isMobile, currentMission } = useContext(MainContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("journey")
  }, [])

  return isMobile ? <JourneyMobile /> : <JourneyDesktop />
}

export default Journey
