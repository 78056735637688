import { CSSProperties, useContext } from "react"
import EmbedSlice from "../../../models/bodySlices/embedSlice"
import ImageSlice from "../../../models/bodySlices/imageSlice"
import QuoteSlice from "../../../models/bodySlices/quoteSlice"
import TextSlice from "../../../models/bodySlices/textSlice"
import { colors } from "../../../services/config/colors"
import { BodySliceType } from "../../../services/config/enum"
import { MainContext } from "../../../controllers/main"

const BodyRenderer = ({
  body,
  style,
}: {
  body?: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  style?: CSSProperties
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <div style={{ width: "100%", ...style }}>
      {body && body.length
        ? body.map((item, index) => {
            if (item.sliceType === BodySliceType.BodyTextSlice) {
              return (
                <div
                  className="html"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item.text.replace(/<\/?a[^>]*>/g, ""),
                  }}
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    fontWeight: 400,
                    lineHeight: "22px",
                    color: colors.text,
                    overflow: "hidden",
                  }}
                />
              )
            } else {
              return null
            }
          })
        : null}
    </div>
  )
}

export default BodyRenderer
