import { Grid, Stack } from "@mui/material"
import {
  currency,
  defaultBoxShadow,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { t } from "i18next"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import vechainLogo from "../../assets/images/vechain-logo.png"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import Alert from "../global/common/Alert"
import Button from "../global/common/Button"
import { useNavigate } from "react-router-dom"
import Appear from "../animations/Appear"
import { lotteryProducts } from "../../services/data/products"
import ProductCard from "./common/ProductCard"
import LotteryProductCard from "./common/LotteryProductCard"
import ProductDetailsDialogDesktop from "./desktop/ProductDetailsDialogDesktop"
import Product from "../../models/product"
import { scrollWindowToTop } from "../../services/utils/utils"
import ProductGotAlert from "./common/ProductGotAlert"
import LotteryProduct from "../../models/lotteryProduct"
import { MarketplaceContext } from "../../controllers/marketplace"

const StoreDesktop = ({
  storeAlertOpen,
  setStoreAlertOpen,
  lotteryProductsAlertOpen,
  setLotteryProductsAlertOpen,
}: {
  storeAlertOpen: boolean
  setStoreAlertOpen: Dispatch<SetStateAction<boolean>>
  lotteryProductsAlertOpen: boolean
  setLotteryProductsAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { products, orders, createOrder, resendEmail } =
    useContext(MarketplaceContext)

  // product dialog
  const [productDialogOpen, setProductDialogOpen] = useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<
    Product | LotteryProduct
  >()

  // product loading
  const [loading, setLoading] = useState<boolean>(false)

  // transaction confirmation alert
  const [transactionAlertOpen, setTransactionAlertOpen] =
    useState<boolean>(false)

  // product got alert
  const [productGotAlertOpen, setProductGotAlertOpen] = useState<boolean>(false)

  // no email alert
  const [noEmailAlertOpen, setNoEmailAlertOpen] = useState<boolean>(false)

  // error alert
  const [errorAlertOpen, setErrorAlertOpen] = useState<boolean>(false)

  // not enough points error alert
  const [noPointsAlertOpen, setNoPointsAlertOpen] = useState<boolean>(false)

  // if email has already been resent for this session or not
  const [emailResent, setEmailResent] = useState<boolean>(false)

  // email resend logic
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const [emailLoadingAlert, setEmailLoadingAlert] = useState<boolean>(false)

  const callEmailResend = async () => {
    if (
      orders.some(
        (order) => order.products[0].product.id === currentProduct?.id
      )
    ) {
      const currentOrder = orders.find(
        (order) => order.products[0].product.id === currentProduct?.id
      )!

      setEmailLoading(true)

      const result = await resendEmail({
        orderId: currentOrder.id,
        productId: currentOrder.products[0].product.id,
        itemId: currentOrder.products[0].item.itemId,
      })

      setEmailLoading(false)
      if (result) {
        setEmailResent(true)
      }

      return result
    }

    return false
  }

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("store") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
            paddingBottom: 90,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("store")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("get_prizes")}
              </Text>
            </AppearFromSide>
          </Stack>
          {/* points and info */}
          <Stack
            direction="row"
            style={{ width: "100%", height: 172, gap: 30, marginTop: 32 }}
          >
            <AppearFromSide
              fade
              delay={0.05}
              style={{ width: "100%", height: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: defaultBoxShadow,
                  borderRadius: 16,
                  backgroundColor: colors.backgroundWhite,
                  padding: 20,
                  gap: 42,
                  position: "relative",
                }}
              >
                <img
                  src={user?.profileImage}
                  style={{
                    width: 142,
                    height: 142,
                    borderRadius: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    boxShadow: defaultBoxShadow,
                  }}
                  alt=""
                />
                <Stack gap={2} alignItems="center" style={{ marginTop: 6 }}>
                  <Text
                    fontSize={20}
                    lineHeight="23px"
                    fontWeight={400}
                    color={colors.textSecondary}
                  >
                    {t("points_extended").toUpperCase()}
                  </Text>
                  <Title fontSize={30} lineHeight="30px" color={colors.primary}>
                    {user?.points}
                  </Title>
                  <Stack direction="row" alignItems="center">
                    <Title fontSize={14} color={colors.primary}>
                      Powered by
                    </Title>
                    <img
                      src={vechainLogo}
                      style={{
                        width: 94,
                        marginLeft: -1,
                      }}
                      alt="Vechain"
                    />
                  </Stack>
                </Stack>
                {/* <Stack
                  alignItems="center"
                  style={{
                    width: 310,
                    height: "calc(100% - 40px)",
                    backgroundColor: colors.primary,
                    borderRadius: 16,
                    position: "absolute",
                    right: 20,
                    paddingTop: 22,
                  }}
                >
                  <Text
                    fontSize={16}
                    lineHeight="18px"
                    color={colors.textWhite}
                    fontWeight={500}
                  >
                    {t("to_be_used_by")}
                  </Text>
                  <Countdown
                    date={new Date(eventEnd)}
                    renderer={(data) => {
                      return CountdownRenderedDesktop({
                        days: data.days,
                        hours: data.hours,
                        minutes: data.minutes,
                        seconds: data.seconds,
                        backgroundColor: colors.backgroundWhite,
                        marginTop: 20,
                      })
                    }}
                  />
                </Stack> */}
              </Stack>
            </AppearFromSide>
            <Appear fade delay={0.05} style={{ height: "100%" }}>
              <Stack justifyContent="space-between" style={{ height: "100%" }}>
                <AvatarInfoButton
                  title={t("how_does_store_work")}
                  onClick={() => {
                    setStoreAlertOpen(true)
                  }}
                />
                <Button
                  width="100%"
                  outlined
                  title={t("go_to_your_wallet")}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/store/wallet")
                  }}
                >
                  {t("go_to_your_wallet")}
                </Button>
              </Stack>
            </Appear>
          </Stack>
          {/* products */}
          <AppearFromSide fade delay={0.1} style={{ marginTop: 56 }}>
            <Title fontSize={26} lineHeight="46px" component="h2">
              {t("available_prizes")}
            </Title>
          </AppearFromSide>
          <Grid container columns={4} spacing="30px" style={{ marginTop: 0 }}>
            {products.map((product, index) => (
              <Grid key={index} item xs={1}>
                <Appear delay={0.05 * (index + 2)} fade>
                  <ProductCard
                    product={product}
                    customOnClick={() => {
                      setCurrentProduct(product)
                      setEmailResent(false)
                      setProductDialogOpen(true)
                    }}
                  />
                </Appear>
              </Grid>
            ))}
          </Grid>
          {/* lottery products */}
          <Stack
            direction="row"
            alignItems="flex-end"
            style={{
              width: "100%",
              height: 92,
              position: "relative",
              marginTop: 76,
            }}
          >
            <AppearFromSide fade delay={0.15}>
              <Stack>
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("lottery_prizes")}
                </Title>
                <Text
                  fontSize={18}
                  lineHeight="21px"
                  fontWeight={500}
                  color={colors.textSecondary}
                >
                  {t("final_draw")} stay tuned
                </Text>
              </Stack>
            </AppearFromSide>
            <Appear
              fade
              delay={0.15}
              style={{ position: "absolute", right: 0 }}
            >
              <AvatarInfoButton
                title={t("how_do_lottery_prizes_work")}
                onClick={() => {
                  setLotteryProductsAlertOpen(true)
                }}
                width={330}
              />
            </Appear>
          </Stack>
          <Grid container columns={2} spacing="30px" style={{ marginTop: 5 }}>
            {lotteryProducts.map((product, index) => (
              <Grid key={index} item xs={1}>
                <Appear delay={0.05 * (index + 4)} fade>
                  <LotteryProductCard
                    index={index}
                    product={product}
                    customOnClick={() => {
                      setCurrentProduct(product)
                      setProductDialogOpen(true)
                    }}
                  />
                </Appear>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </PaddingContainerDesktop>
      {/* alerts */}
      <Alert
        open={storeAlertOpen}
        title={t("how_does_store_work")}
        description={t("how_does_store_work_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setStoreAlertOpen(false)
        }}
      />
      <Alert
        open={lotteryProductsAlertOpen}
        title={t("how_do_lottery_prizes_work")}
        description={t("how_do_lottery_prizes_work_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setLotteryProductsAlertOpen(false)
        }}
      />
      {/* product details dialog */}
      <ProductDetailsDialogDesktop
        open={productDialogOpen}
        setOpen={setProductDialogOpen}
        setNoEmailAlertOpen={setNoEmailAlertOpen}
        setTransactionAlertOpen={setTransactionAlertOpen}
        setNoPointsAlertOpen={setNoPointsAlertOpen}
        emailLoading={emailLoading}
        emailLoadingAlert={emailLoadingAlert}
        emailResent={emailResent}
        callEmailResend={callEmailResend}
        product={currentProduct}
      />
      {/* alerts */}
      <Alert
        open={transactionAlertOpen}
        title={t("you_are_using_your_points")}
        description={t("you_will_use_your_points")}
        isDescriptionHtml
        primaryActionLabel={t("get_prize")}
        primaryActionOnClick={async () => {
          setLoading(true)

          const result = await createOrder(
            currentProduct!.id,
            currentProduct!.prices!.find(
              (price) => price.currency === currency
            )!.amount
          )

          setTransactionAlertOpen(false)
          setLoading(false)

          if (result) {
            setProductGotAlertOpen(true)
          } else {
            setErrorAlertOpen(true)
          }
        }}
        secondaryActionLabel={t("cancel")}
        secondaryActionOnClick={() => {
          setTransactionAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
        loading={loading}
      />
      <ProductGotAlert
        open={productGotAlertOpen}
        setOpen={setProductGotAlertOpen}
        points={
          currentProduct && currentProduct.prices
            ? currentProduct.prices.find(
                (price) => price.currency === currency
              )!.amount
            : 0
        }
        setProductDialogOpen={setProductDialogOpen}
      />
      <Alert
        open={noEmailAlertOpen}
        title={t("you_did_not_receive_the_email")}
        description={t("check_your_email_for_prize", { email: user?.email })}
        isDescriptionHtml
        primaryActionLabel={t("send_email_again")}
        primaryActionOnClick={async () => {
          setEmailLoadingAlert(true)

          const result = await callEmailResend()

          setEmailLoadingAlert(false)
          if (result) {
            setNoEmailAlertOpen(false)
          }
        }}
        secondaryActionLabel={t("close")}
        secondaryActionOnClick={() => {
          setNoEmailAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
        primaryActionDisabled={emailResent || emailLoading}
        loading={emailLoadingAlert}
      />
      <Alert
        open={errorAlertOpen}
        title={t("something_is_wrong")}
        description={t("prize_redeem_error")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setErrorAlertOpen(false)
        }}
      />
      <Alert
        open={noPointsAlertOpen}
        title={t("warning")}
        description={t("you_dont_have_enough_points")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setNoPointsAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default StoreDesktop
