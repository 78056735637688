import { Stack } from "@mui/material"
import {
  defaultBoxShadow,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { t } from "i18next"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import vechainLogo from "../../assets/images/vechain-logo.png"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import Button from "../global/common/Button"
import { useNavigate } from "react-router-dom"
import AlertInfo from "../global/common/AlertInfo"
import { scrollWindowToTop } from "../../services/utils/utils"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import { Trans } from "react-i18next"
import TransactionCardDesktop from "./desktop/TransactionCardDesktop"
import { MarketplaceContext } from "../../controllers/marketplace"

const WalletDesktop = ({
  vechainAlertOpen,
  setVechainAlertOpen,
}: {
  vechainAlertOpen: boolean
  setVechainAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { windowHeight, user } = useContext(MainContext)
  const { orders } = useContext(MarketplaceContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("store"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/store")
            },
          },
          { label: t("wallet") },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
            paddingBottom: 90,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("wallet")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("get_prizes")}
              </Text>
            </AppearFromSide>
          </Stack>
          {/* points and info */}
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: "100%", height: 172, gap: 30, marginTop: 32 }}
          >
            <AppearFromSide
              fade
              delay={0.05}
              style={{ width: "57.14%", height: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: defaultBoxShadow,
                  borderRadius: 16,
                  backgroundColor: colors.backgroundWhite,
                  padding: 20,
                  position: "relative",
                }}
              >
                <img
                  src={user?.profileImage}
                  style={{
                    width: 90,
                    height: 90,
                    borderRadius: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    boxShadow: defaultBoxShadow,
                  }}
                  alt=""
                />
                <Stack
                  alignItems="center"
                  style={{ width: 120, gap: 15, marginLeft: 30 }}
                >
                  <Text
                    fontSize={16}
                    lineHeight="23px"
                    fontWeight={400}
                    textAlign="center"
                    color={colors.textSecondary}
                  >
                    {t("available_points").toUpperCase()}
                  </Text>
                  <Title fontSize={26} lineHeight="28px" color={colors.primary}>
                    {user?.points}
                  </Title>
                </Stack>
                <div
                  style={{
                    width: 1,
                    height: 68,
                    borderRadius: 10,
                    backgroundColor: colors.primary,
                    marginLeft: 30,
                  }}
                />
                <Stack
                  alignItems="center"
                  style={{ width: 120, gap: 15, marginLeft: 30 }}
                >
                  <Text
                    fontSize={16}
                    lineHeight="23px"
                    fontWeight={400}
                    textAlign="center"
                    color={colors.textSecondary}
                  >
                    {t("points_used").toUpperCase()}
                  </Text>
                  <Title fontSize={26} lineHeight="28px" color={colors.primary}>
                    {orders.reduce(
                      (accumulator, item) => accumulator + item.price.amount,
                      0
                    )}
                  </Title>
                </Stack>
                <Stack
                  alignItems="center"
                  style={{ position: "absolute", top: 20, right: 20 }}
                >
                  <Title fontSize={14} lineHeight="20px" color={colors.primary}>
                    Powered by
                  </Title>
                  <img
                    src={vechainLogo}
                    style={{ height: 30, marginTop: -1 }}
                    alt="Vechain"
                  />
                </Stack>
              </Stack>
            </AppearFromSide>
            <Appear fade delay={0.05} style={{ height: "100%" }}>
              <Stack justifyContent="space-between" style={{ height: "100%" }}>
                <AvatarInfoButton
                  title={t("vechain_alert_title")}
                  onClick={() => {
                    setVechainAlertOpen(true)
                  }}
                  width={330}
                />
                <Button
                  width="100%"
                  outlined
                  title={t("go_back_to_store")}
                  onClick={() => {
                    navigate("/store")
                  }}
                >
                  {t("go_back_to_store")}
                </Button>
              </Stack>
            </Appear>
          </Stack>
          {/* retrieved products */}
          <AppearFromSide fade delay={0.1} style={{ marginTop: 40 }}>
            <Title lineHeight="46px">{t("retrieved_prizes")}</Title>
          </AppearFromSide>
          <Stack style={{ width: "57.14%" }}>
            {orders.length ? (
              <Stack>
                {orders.map((item, index) => (
                  <Stack key={index} style={{ width: "100%", gap: 14 }}>
                    <AppearFromSide fade delay={0.05 * (index + 1)}>
                      <TransactionCardDesktop
                        product={item.products[0].product}
                        updatedAt={item.updatedAt}
                        status={item.status}
                      />
                    </AppearFromSide>
                    {index !== orders.length - 1 ? (
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "#F2F3F4",
                          borderRadius: 10,
                        }}
                      />
                    ) : null}
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Text
                fontSize={18}
                fontWeight={400}
                textAlign="center"
                style={{ marginTop: 40 }}
              >
                <Trans i18nKey="transactions_empty_state">
                  Non hai ancora ottenuto premi,
                  <br />
                  inizia ora!
                </Trans>
              </Text>
            )}
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 700 }}
        />
      </div>
      {/* alerts */}
      <AlertInfo
        open={vechainAlertOpen}
        setOpen={setVechainAlertOpen}
        title={t("vechain_alert_title")}
        description={t("vechain_alert_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default WalletDesktop
