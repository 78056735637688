import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import AppearFromSide from "../animations/AppearFromSide"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import Text from "../global/common/Text"
import {
  defaultBoxShadow,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import {
  BodySliceType,
  MissionPeriod,
  MissionType,
} from "../../services/config/enum"
import SavingCard from "./common/SavingCard"
import infoIcon from "../../assets/icons/info-primary.svg"
import Button from "../global/common/Button"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import { MainContext } from "../../controllers/main"
import Action from "../../models/action"
import AlertInfo from "../global/common/AlertInfo"
import { ActionsContext } from "../../controllers/actions"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { Trans, useTranslation } from "react-i18next"
import actMissionIconWhite from "../../assets/icons/missions/missionsTypes/act-white.svg"
import PointsBadge from "../global/common/PointsBadge"
import ActionsHelpCardDesktop from "./desktop/ActionsHelpCardDesktop"
import ActionLoggedFeedback from "../feedbacks/common/ActionLoggedFeedback"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"

const ActionDetailsDesktop = ({
  action,
  timesLogged,
  repeatableInfoOpen,
  setRepeatableInfoOpen,
  logFeedbackOpen,
  missionFeedbackOpen,
}: {
  action: Action
  timesLogged: number
  repeatableInfoOpen: boolean
  setRepeatableInfoOpen: Dispatch<SetStateAction<boolean>>
  logFeedbackOpen: boolean
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight, currentMission, updatingMissions } =
    useContext(MainContext)
  const { setSelectedActions, logActions } = useContext(ActionsContext)

  // info alerts
  const [whatAreMissionsAlertOpen, setWhatAreMissionsAlertOpen] =
    useState<boolean>(false)
  const [whyLogAlertOpen, setWhyLogAlertOpen] = useState<boolean>(false)

  return !currentMission ? null : (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("act_mission"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/actions")
            },
          },
          ...(currentMission.missionTypeId === action.id
            ? []
            : [
                {
                  label: action.document.title,
                },
              ]),
        ]}
        selectedItem={currentMission.missionTypeId === action.id ? 0 : 1}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
            marginTop: 22,
          }}
        >
          {/* header */}
          {currentMission.missionTypeId === action.id ? (
            <AppearFromSide fade>
              <Stack direction="row" gap={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "auto",
                    height: 40,
                    borderRadius: 4,
                    paddingInline: 10,
                    backgroundColor: colors.missions.act,
                  }}
                >
                  <img src={actMissionIconWhite} style={{ width: 23 }} alt="" />
                  <Title
                    fontSize={18}
                    color={colors.textWhite}
                    style={{
                      fontVariant: "all-small-caps",
                      marginLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    {t("act")}
                  </Title>
                  <Title
                    fontSize={18}
                    color={colors.textWhite}
                    style={{
                      fontVariant: "all-small-caps",
                      marginLeft: 18,
                      marginBottom: 2,
                    }}
                  >
                    {currentMission.current}/{currentMission.target}
                  </Title>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "auto",
                    height: 40,
                    borderRadius: 4,
                    paddingInline: 10,
                    border: "1.5px solid " + colors.tertiary,
                    backgroundColor: colors.backgroundWhite,
                  }}
                >
                  <Title fontSize={18} color={colors.tertiary}>
                    {currentMission.period === MissionPeriod.DAILY
                      ? t("today")
                      : currentMission.period === MissionPeriod.WEEKLY
                      ? t("week")
                      : t("special_singular")}
                  </Title>
                </Stack>
                <PointsBadge points={currentMission.points} />
              </Stack>
            </AppearFromSide>
          ) : null}
          {currentMission.missionTypeId === action.id ? (
            <AppearFromSide
              fade
              delay={0.05}
              style={{ width: "100%", marginTop: 16 }}
            >
              <Title fontSize={28} style={{ width: "57.14%" }}>
                {currentMission.title}
              </Title>
            </AppearFromSide>
          ) : null}
          {/* content */}
          <Stack
            style={{
              marginTop: currentMission.missionTypeId === action.id ? 50 : 0,
            }}
          >
            <AppearFromSide
              fade
              delay={currentMission.missionTypeId === action.id ? 0.1 : 0}
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                gap={2}
                style={{
                  width: "57.14%",
                  height: 112,
                  padding: 12,
                  backgroundColor: colors.backgroundWhite,
                  borderRadius: 10,
                  boxShadow: defaultBoxShadow,
                }}
              >
                <div
                  className="center"
                  style={{
                    width: 88,
                    minWidth: 88,
                    height: 88,
                    borderRadius: 8,
                    backgroundColor: action.category.backColor,
                  }}
                >
                  <img
                    src={action.image}
                    style={{
                      width: 70,
                      height: 70,
                    }}
                    alt=""
                  />
                </div>
                <Stack gap={1}>
                  <div
                    style={{
                      width: "min-content",
                      height: 20,
                      minHeight: 20,
                      borderRadius: 4,
                      paddingInline: 6,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: action.category.backTagColor,
                    }}
                  >
                    <Text
                      fontSize={14}
                      lineHeight="16px"
                      fontWeight={700}
                      color={action.category.foreColor}
                    >
                      {action.category.name}
                    </Text>
                  </div>
                  <Text fontSize={18} lineHeight="20px" fontWeight={500}>
                    {action.document.title}
                  </Text>
                </Stack>
              </Stack>
            </AppearFromSide>
            <AppearFromSide
              fade
              delay={currentMission.missionTypeId === action.id ? 0.15 : 0.05}
              style={{
                width: "57.14%",
                marginTop: 24,
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack gap={2} style={{ width: 440 }}>
                {currentMission.missionTypeId === action.id &&
                currentMission.target === 1 ? null : action.timesPerDay > 1 ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <ButtonBase
                      disableRipple
                      style={{
                        height: 22,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRepeatableInfoOpen(true)
                      }}
                      aria-label={t("log_more_than_once")}
                    >
                      <img
                        src={infoIcon}
                        style={{ width: 18, height: 18 }}
                        alt=""
                      />
                      <Text
                        fontSize={18}
                        lineHeight="22px"
                        fontWeight={500}
                        style={{ marginLeft: 6 }}
                        color={colors.primary}
                      >
                        {t("log_more_than_once")}
                      </Text>
                    </ButtonBase>
                    <Text
                      fontSize={18}
                      lineHeight="22px"
                      fontWeight={500}
                      style={{ position: "absolute", right: 0 }}
                      color={colors.primary}
                    >
                      {timesLogged}/{action.timesPerDay}
                    </Text>
                  </Stack>
                ) : null}
                <Button
                  loading={updatingMissions}
                  disabled={timesLogged === action.timesPerDay}
                  width="100%"
                  onClick={() => {
                    setSelectedActions([])
                    logActions([action])
                  }}
                  title={
                    updatingMissions
                      ? t("log")
                      : timesLogged === action.timesPerDay
                      ? t("action_logged")
                      : t("log")
                  }
                >
                  {updatingMissions
                    ? t("log")
                    : timesLogged === action.timesPerDay
                    ? t("action_logged")
                    : t("log")}
                </Button>
              </Stack>
            </AppearFromSide>
            <AppearFromSide
              fade
              delay={currentMission.missionTypeId === action.id ? 0.2 : 0.1}
              style={{
                width: "100%",
                marginTop: 50,
                marginBottom: 58,
                position: "relative",
              }}
            >
              <Stack
                style={{
                  width: "57.14%",
                  height: "auto",
                  padding: 46,
                  paddingTop: 32,
                  backgroundColor: colors.backgroundWhite,
                  boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                  borderRadius: 10,
                }}
              >
                <Title
                  fontSize={24}
                  lineHeight="46px"
                  component="h2"
                  style={{ marginBottom: 14 }}
                >
                  {t("why_it_is_important")}
                </Title>
                {action.document.body && action.document.body.length
                  ? action.document.body.map((item, index) => {
                      if (item.sliceType === BodySliceType.BodyTextSlice) {
                        return (
                          <div
                            className="html"
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: item.text.replace(/<\/?a[^>]*>/g, ""),
                            }}
                            style={{
                              fontSize: 20,
                              fontWeight: 300,
                              lineHeight: "32px",
                              color: colors.text,
                              overflow: "hidden",
                            }}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  : null}
                {action.metrics.hasSavings ? (
                  <Title
                    fontSize={24}
                    lineHeight="46px"
                    style={{
                      marginTop:
                        action.document.body && action.document.body.length
                          ? 28
                          : 0,
                    }}
                    component="h2"
                  >
                    {t("action_savings")}
                  </Title>
                ) : null}
                {action.metrics.hasSavings ? (
                  <Stack
                    direction="row"
                    gap={2}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      overflowX: "scroll",
                      marginTop: 18,
                    }}
                  >
                    {action.metrics.co2Saving ? (
                      <SavingCard
                        metric="co2"
                        amount={action.metrics.co2Saving}
                      />
                    ) : null}
                    {action.metrics.waterSaving ? (
                      <SavingCard
                        metric="water"
                        amount={action.metrics.waterSaving}
                      />
                    ) : null}
                    {action.metrics.energySaving ? (
                      <SavingCard
                        metric="energy"
                        amount={action.metrics.energySaving}
                      />
                    ) : null}
                  </Stack>
                ) : null}
                {action.document.assumptions ? (
                  <ButtonBase
                    disableRipple
                    style={{
                      height: 22,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignSelf: "flex-start",
                      cursor: "pointer",
                      marginTop: 28,
                    }}
                    onClick={() => {
                      scrollWindowToTop()
                      navigate(window.location.pathname + "/calculations")
                    }}
                    aria-label={t("how_is_calculated")}
                  >
                    <img
                      src={infoIcon}
                      style={{ width: 18, height: 18 }}
                      alt=""
                    />
                    <Text
                      fontSize={18}
                      lineHeight="22px"
                      fontWeight={500}
                      style={{ marginLeft: 6 }}
                      color={colors.primary}
                    >
                      {t("how_is_calculated")}
                    </Text>
                  </ButtonBase>
                ) : null}
              </Stack>
            </AppearFromSide>
            {/* help card */}
            <ActionsHelpCardDesktop
              setWhatAreMissionsAlertOpen={setWhatAreMissionsAlertOpen}
              setWhyLogAlertOpen={setWhyLogAlertOpen}
              style={{ zIndex: 10 }}
            />
          </Stack>
          {/* action image */}
          <img
            src={action.image}
            style={{
              width: 350,
              position: "absolute",
              top: currentMission.missionTypeId === action.id ? 540 : 450,
              right: 0,
            }}
            alt=""
            aria-hidden="true"
          />
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={action.category.backColor}
          style={{
            position: "absolute",
            top: currentMission.missionTypeId === action.id ? 500 : 410,
          }}
        />
      </div>
      {/* alerts and feedbacks */}
      <AlertInfo
        open={repeatableInfoOpen}
        setOpen={setRepeatableInfoOpen}
        title={t("log_more_than_once")}
        description={t("common_and_frequent_actions")}
        descriptionAlign="center"
      />
      <AlertInfo
        open={whatAreMissionsAlertOpen}
        setOpen={setWhatAreMissionsAlertOpen}
        title={t("what_are_act_missions")}
        description={
          <Text
            fontSize={18}
            lineHeight="25px"
            fontWeight={400}
            textAlign="center"
            style={{ marginTop: 20 }}
          >
            <Trans i18nKey="act_missions_description">
              Migliora il tuo stile di vita,{" "}
              <strong style={{ fontWeight: 500 }}>esplora</strong> le azioni
              suggerite e <strong style={{ fontWeight: 500 }}>registra</strong>{" "}
              quelle che hai fatto.
            </Trans>
          </Text>
        }
        descriptionAlign="center"
      />
      <AlertInfo
        open={whyLogAlertOpen}
        setOpen={setWhyLogAlertOpen}
        title={t("why_log_actions")}
        description={t("why_log_actions_description")}
        descriptionAlign="center"
      />
      <ActionLoggedFeedback open={logFeedbackOpen} />
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.ACT}
      />
    </Stack>
  )
}

export default ActionDetailsDesktop
