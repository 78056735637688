import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import Title from "../global/common/Title"
import { t } from "i18next"
import Product from "../../models/product"
import PointsBadge from "../global/common/PointsBadge"
import Appear from "../animations/Appear"
import AppearFromSide from "../animations/AppearFromSide"
import Text from "../global/common/Text"
import FadeFromBottom from "../animations/FadeFromBottom"
import Button from "../global/common/Button"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import Alert from "../global/common/Alert"
import ProductGotAlert from "./common/ProductGotAlert"
import { MainContext } from "../../controllers/main"
import { currency } from "../../services/config/constants"
import LotteryProduct from "../../models/lotteryProduct"
import { MarketplaceContext } from "../../controllers/marketplace"
import BodyRenderer from "../global/common/BodyRenderer"
import { EventStatus } from "../../services/config/enum"

const ProductDetailsMobile = ({
  loading,
  setLoading,
  product,
  transactionAlertOpen,
  setTransactionAlertOpen,
  productGotAlertOpen,
  setProductGotAlertOpen,
  noEmailAlertOpen,
  setNoEmailAlertOpen,
  errorAlertOpen,
  setErrorAlertOpen,
  errorType,
  setErrorType,
  noPointsAlertOpen,
  setNoPointsAlertOpen,
  emailResent,
  emailLoading,
  callEmailResend,
}: {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  product: Product | LotteryProduct
  transactionAlertOpen: boolean
  setTransactionAlertOpen: Dispatch<SetStateAction<boolean>>
  productGotAlertOpen: boolean
  setProductGotAlertOpen: Dispatch<SetStateAction<boolean>>
  noEmailAlertOpen: boolean
  setNoEmailAlertOpen: Dispatch<SetStateAction<boolean>>
  errorAlertOpen: boolean
  setErrorAlertOpen: Dispatch<SetStateAction<boolean>>
  errorType: string
  setErrorType: Dispatch<SetStateAction<string>>
  noPointsAlertOpen: boolean
  setNoPointsAlertOpen: Dispatch<SetStateAction<boolean>>
  emailResent: boolean
  emailLoading: boolean
  callEmailResend: () => Promise<boolean>
}) => {
  const navigate = useNavigate()
  const { user, eventStatus } = useContext(MainContext)
  const { createOrder } = useContext(MarketplaceContext)

  // email loading for alert
  const [emailLoadingAlert, setEmailLoadingAlert] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        paddingBottom: product.boughtCount ? 226 : product.available ? 124 : 36,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/store")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
          ellipsis
        >
          {product.document.title}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* product image */}
      <Appear fade style={{ width: "calc(100% - 32px)", marginTop: 18 }}>
        <div
          style={{
            width: "100%",
            height: 203,
            position: "relative",
          }}
        >
          <img
            src={product.image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: 10,
            }}
            alt=""
          />
          {product.prices && product.prices.length ? (
            <PointsBadge
              points={
                product.prices.find((price) => price.currency === currency)!
                  .amount
              }
              style={{ position: "absolute", top: 14, right: 14 }}
            />
          ) : null}
        </div>
      </Appear>
      {/* description */}
      {Boolean(product.document.body && product.document.body.length) && (
        <AppearFromSide
          fade
          delay={0.05}
          style={{ width: "100%", marginTop: 20 }}
        >
          <Stack style={{ paddingInline: 16 }}>
            <Title fontSize={16} lineHeight="25px">
              {t("prize_description")}
            </Title>
            <BodyRenderer
              body={product.document.body}
              style={{ marginTop: 10 }}
            />
          </Stack>
        </AppearFromSide>
      )}
      {/* sponsor */}
      <AppearFromSide fade delay={0.1} style={{ width: "100%", marginTop: 20 }}>
        <Title
          fontSize={16}
          lineHeight="25px"
          paddingLeft={16}
          paddingRight={16}
        >
          {t("sponsored_by")}
        </Title>
      </AppearFromSide>
      <AppearFromSide fade delay={0.1} style={{ width: "100%", marginTop: 10 }}>
        <Text
          fontSize={14}
          lineHeight="22px"
          fontWeight={400}
          paddingLeft={16}
          paddingRight={16}
        >
          {product.sponsoredBy}
        </Text>
      </AppearFromSide>
      {/* log button */}
      {(product.available || product.boughtCount) &&
      (product.id !== "2608e769-933b-4440-9d8c-fd761703e43b" ||
        (eventStatus !== EventStatus.LOCKED &&
          eventStatus !== EventStatus.HIGHLIGHTED)) ? (
        <FadeFromBottom style={{ width: "100%", position: "fixed", bottom: 0 }}>
          <div
            style={{
              width: "100%",
              paddingTop: 16,
              paddingBottom: 16,
              boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
              borderRadius: "10px 10px 0px 0px",
              backgroundColor: colors.backgroundWhite,
            }}
          >
            {product.boughtCount ? (
              <ButtonBase
                disableRipple
                style={{
                  width: "fit-content",
                  justifyContent: "flex-start",
                  marginBottom: 16,
                  marginLeft: 16,
                }}
                aria-label={t("i_did_not_receive_the_email")}
                onClick={() => {
                  setNoEmailAlertOpen(true)
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={infoIconPrimary}
                    style={{ width: 16, height: 16 }}
                    alt=""
                  />
                  <Title fontSize={14} lineHeight="22px" color={colors.primary}>
                    {t("i_did_not_receive_the_email")}
                  </Title>
                </Stack>
              </ButtonBase>
            ) : null}
            <Button
              width="calc(100% - 32px)"
              disabled={!product.canBuy || !product.available}
              style={{ marginLeft: 16 }}
              title={
                product.boughtCount ? t("prize_retrieved") : t("get_prize")
              }
              onClick={() => {
                if (
                  user!.points >=
                  product.prices!.find((price) => price.currency === currency)!
                    .amount
                ) {
                  setTransactionAlertOpen(true)
                } else {
                  setNoPointsAlertOpen(true)
                }
              }}
            >
              {product.boughtCount ? t("prize_retrieved") : t("get_prize")}
            </Button>
            {Boolean(product.boughtCount) ? (
              <Button
                loading={emailLoading && !emailLoadingAlert}
                disabled={emailResent}
                width="calc(100% - 32px)"
                outlined
                style={{ marginLeft: 16, marginTop: 8 }}
                title={t("send_email_again")}
                onClick={callEmailResend}
              >
                {t("send_email_again")}
              </Button>
            ) : null}
          </div>
        </FadeFromBottom>
      ) : null}
      {/* alerts */}
      <Alert
        open={transactionAlertOpen}
        title={t("you_are_using_your_points")}
        description={t("you_will_use_your_points")}
        isDescriptionHtml
        primaryActionLabel={t("get_prize")}
        primaryActionOnClick={async () => {
          setLoading(true)

          const result = await createOrder(
            product!.id,
            product!.prices!.find((price) => price.currency === currency)!
              .amount
          )

          setTransactionAlertOpen(false)
          setLoading(false)

          if (result === true) {
            setProductGotAlertOpen(true)
          } else {
            setErrorType(result)
            setErrorAlertOpen(true)
          }
        }}
        secondaryActionLabel={t("cancel")}
        secondaryActionOnClick={() => {
          setTransactionAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
        loading={loading}
      />
      <ProductGotAlert
        open={productGotAlertOpen}
        setOpen={setProductGotAlertOpen}
        points={
          product.prices
            ? product.prices.find((price) => price.currency === currency)!
                .amount
            : 0
        }
      />
      <Alert
        open={noEmailAlertOpen}
        title={t("you_did_not_receive_the_email")}
        description={t("check_your_email_for_prize", { email: user?.email })}
        isDescriptionHtml
        primaryActionLabel={t("send_email_again")}
        primaryActionOnClick={async () => {
          setEmailLoadingAlert(true)

          const result = await callEmailResend()

          setEmailLoadingAlert(false)
          if (result) {
            setNoEmailAlertOpen(false)
          }
        }}
        secondaryActionLabel={t("close")}
        secondaryActionOnClick={() => {
          setNoEmailAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
        primaryActionDisabled={emailResent || emailLoading}
        loading={emailLoadingAlert}
      />
      <Alert
        open={errorAlertOpen}
        title={t("something_is_wrong")}
        description={
          errorType === "insufficient_quantity"
            ? t("product_out_of_stock")
            : t("prize_redeem_error")
        }
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setErrorAlertOpen(false)
        }}
      />
      <Alert
        open={noPointsAlertOpen}
        title={t("warning")}
        description={t("you_dont_have_enough_points")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setNoPointsAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default ProductDetailsMobile
