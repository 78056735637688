import { ButtonBase, Dialog, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Product from "../../../models/product"
import { t } from "i18next"
import {
  currency,
  defaultBoxShadowDarker,
} from "../../../services/config/constants"
import closeIcon from "../../../assets/icons/close.svg"
import PointsBadge from "../../global/common/PointsBadge"
import Text from "../../global/common/Text"
import Button from "../../global/common/Button"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"
import LotteryProduct from "../../../models/lotteryProduct"
import BodyRenderer from "../../global/common/BodyRenderer"
import { MainContext } from "../../../controllers/main"
import { EventStatus } from "../../../services/config/enum"

const ProductDetailsDialogDesktop = ({
  open,
  setOpen,
  setNoEmailAlertOpen,
  setTransactionAlertOpen,
  setNoPointsAlertOpen,
  emailLoading,
  emailLoadingAlert,
  emailResent,
  callEmailResend,
  product,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setNoEmailAlertOpen: Dispatch<SetStateAction<boolean>>
  setTransactionAlertOpen: Dispatch<SetStateAction<boolean>>
  setNoPointsAlertOpen: Dispatch<SetStateAction<boolean>>
  emailLoading: boolean
  emailLoadingAlert: boolean
  emailResent: boolean
  callEmailResend: () => Promise<boolean>
  product?: Product | LotteryProduct
}) => {
  const { user, eventStatus } = useContext(MainContext)

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      scroll="body"
      aria-labelledby="product-dialog-title"
      aria-describedby="product-dialog-description"
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      {product ? (
        <Stack
          alignItems="center"
          style={{
            width: 570,
            paddingTop: 50,
            paddingBottom: 35,
            paddingInline: 30,
            backgroundColor: colors.backgroundWhite,
            position: "relative",
          }}
        >
          {/* close button */}
          <ButtonBase
            aria-label={t("close")}
            style={{
              width: 36,
              height: 36,
              backgroundColor: colors.backgroundWhite,
              borderRadius: "100%",
              boxShadow: defaultBoxShadowDarker,
              position: "absolute",
              top: 26,
              right: 26,
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            <img src={closeIcon} style={{ width: 10 }} alt="" />
          </ButtonBase>
          {/* content */}
          <Title fontSize={24} lineHeight="28px">
            {product.document.title}
          </Title>
          <div
            style={{
              width: "100%",
              height: 225,
              marginTop: 50,
              position: "relative",
            }}
          >
            <img
              src={product.image}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: 10,
              }}
              alt=""
            />
            {product.prices && product.prices.length ? (
              <PointsBadge
                points={
                  product.prices.find((price) => price.currency === currency)!
                    .amount
                }
                style={{ position: "absolute", top: 15, right: 15 }}
              />
            ) : null}
          </div>
          {Boolean(product.document.body && product.document.body.length) && (
            <Title
              fontSize={20}
              lineHeight="25px"
              style={{ width: "100%", marginTop: 30 }}
            >
              {t("prize_description")}
            </Title>
          )}
          {Boolean(product.document.body && product.document.body.length) && (
            <BodyRenderer
              body={product.document.body}
              style={{ marginTop: 24 }}
            />
          )}
          <Title
            fontSize={20}
            lineHeight="25px"
            style={{ width: "100%", marginTop: 30 }}
          >
            {t("sponsored_by")}
          </Title>
          <Text
            fontSize={18}
            lineHeight="22px"
            fontWeight={400}
            style={{ width: "100%", marginTop: 14 }}
          >
            {product.sponsoredBy}
          </Text>
          {(product.available || product.boughtCount) &&
          (product.id !== "2608e769-933b-4440-9d8c-fd761703e43b" ||
            (eventStatus !== EventStatus.LOCKED &&
              eventStatus !== EventStatus.HIGHLIGHTED)) ? (
            <Stack alignItems="center" style={{ marginTop: 50 }}>
              {product.boughtCount ? (
                <ButtonBase
                  disableRipple
                  style={{
                    width: "fit-content",
                    justifyContent: "flex-start",
                    marginBottom: 16,
                  }}
                  aria-label={t("i_did_not_receive_the_email")}
                  onClick={() => {
                    setNoEmailAlertOpen(true)
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <img
                      src={infoIconPrimary}
                      style={{ width: 16, height: 16 }}
                      alt=""
                    />
                    <Title
                      fontSize={14}
                      lineHeight="22px"
                      color={colors.primary}
                    >
                      {t("i_did_not_receive_the_email")}
                    </Title>
                  </Stack>
                </ButtonBase>
              ) : null}
              <Button
                title={
                  product.boughtCount ? t("prize_retrieved") : t("get_prize")
                }
                width={330}
                disabled={!product.canBuy || !product.available}
                onClick={() => {
                  if (
                    user!.points >=
                    product.prices!.find(
                      (price) => price.currency === currency
                    )!.amount
                  ) {
                    setTransactionAlertOpen(true)
                  } else {
                    setNoPointsAlertOpen(true)
                  }
                }}
              >
                {product.boughtCount ? t("prize_retrieved") : t("get_prize")}
              </Button>
              {Boolean(product.boughtCount) && (
                <Button
                  loading={emailLoading && !emailLoadingAlert}
                  disabled={emailResent}
                  title={t("send_email_again")}
                  width={330}
                  outlined
                  style={{ marginTop: 8 }}
                  onClick={callEmailResend}
                >
                  {t("send_email_again")}
                </Button>
              )}
            </Stack>
          ) : null}
        </Stack>
      ) : null}
    </Dialog>
  )
}

export default ProductDetailsDialogDesktop
