import { Helmet } from "react-helmet"

const MetaPixelLead = () => {
  return (
    <Helmet>
      <script>{`fbq('track', 'Lead');`}</script>
    </Helmet>
  )
}

export default MetaPixelLead
