import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import StoreMobile from "../../components/store/StoreMobile"
import StoreDesktop from "../../components/store/StoreDesktop"

const Store = () => {
  const { isMobile } = useContext(MainContext)

  // products order select
  const [productsOrderOption, setProductsOrderOption] = useState<number>(0)

  // alerts
  const [storeAlertOpen, setStoreAlertOpen] = useState<boolean>(false)
  const [lotteryProductsAlertOpen, setLotteryProductsAlertOpen] =
    useState<boolean>(false)

  return isMobile ? (
    <StoreMobile
      productsOrderOption={productsOrderOption}
      setProductsOrderOption={setProductsOrderOption}
      storeAlertOpen={storeAlertOpen}
      setStoreAlertOpen={setStoreAlertOpen}
      lotteryProductsAlertOpen={lotteryProductsAlertOpen}
      setLotteryProductsAlertOpen={setLotteryProductsAlertOpen}
    />
  ) : (
    <StoreDesktop
      storeAlertOpen={storeAlertOpen}
      setStoreAlertOpen={setStoreAlertOpen}
      lotteryProductsAlertOpen={lotteryProductsAlertOpen}
      setLotteryProductsAlertOpen={setLotteryProductsAlertOpen}
    />
  )
}

export default Store
