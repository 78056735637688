import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import { MissionsContext } from "../../../controllers/missions"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useReducedMotion, motion } from "framer-motion"
import eventMissionBackgroundImage from "../../../assets/images/event-mission-background.jpg"
import arrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg"
import PointsBadge from "../../global/common/PointsBadge"
import { useNavigate } from "react-router-dom"
import Text from "../../global/common/Text"

const EventCardMobile = ({
  index,
  slidesLength,
  setBottomSheetOpen,
  setCarouselControlsHidden,
}: {
  index: number
  slidesLength: number
  setBottomSheetOpen: Dispatch<SetStateAction<boolean>>
  setCarouselControlsHidden: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const shouldReduceMotion = useReducedMotion()
  const { currentSlide, missions, checkInMissions } =
    useContext(MissionsContext)

  // animation
  const [cardOpen, setCardOpen] = useState<boolean>(false)

  return (
    <motion.div
      style={{
        width: 256,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
      onClick={() => {
        if (Math.abs(currentSlide % slidesLength) === index) {
          if (shouldReduceMotion) {
            navigate("/event")
          } else {
            setBottomSheetOpen(false)
            setCarouselControlsHidden(true)
            setCardOpen(true)
            setTimeout(() => {
              navigate("/event")
            }, 220)
          }
        }
      }}
      animate={cardOpen ? "open" : "closed"}
      variants={{
        open: { scale: 5 },
        closed: { scale: 1 },
      }}
      transition={{ scale: { type: "spring", duration: 0.5 } }}
      initial={false}
      aria-hidden={
        Math.abs(currentSlide % slidesLength) === index ? "false" : "true"
      }
    >
      <div
        style={{
          width: "100%",
          height: "calc(100% - 23px)",
          borderRadius: 20,
          backgroundColor: colors.backgroundWhite,
        }}
      >
        <ButtonBase
          disabled={Math.abs(currentSlide % slidesLength) !== index}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 20,
            boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
            opacity: cardOpen ? 0 : 1,
            transition: "200ms",
          }}
          aria-label={
            missions.length
              ? t("view_all_completed_missions")
              : `${t("all_missions_completed")} ${t("go_to_your_points")}`
          }
        >
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 20,
              backgroundColor: colors.primary,
              backgroundImage: `url(${eventMissionBackgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              paddingInline: 21,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "auto",
                height: 28,
                position: "absolute",
                top: 19.5,
                right: 21,
                gap: 10,
              }}
            >
              {/* period badge */}
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: "100%",
                  paddingInline: 8,
                  backgroundColor: colors.backgroundWhite,
                  borderRadius: 4,
                  border: "1px solid " + colors.tertiary,
                  gap: 6,
                }}
              >
                <Title fontSize={14} color={colors.tertiary}>
                  {t("event").toUpperCase()}
                </Title>
              </Stack>
              {/* points badge */}
              <PointsBadge
                points={1000}
                upTo
                style={{
                  height: "100%",
                }}
              />
            </Stack>
            <Title
              fontSize={18}
              lineHeight="21px"
              color={colors.textWhite}
              style={{
                width: "100%",
                marginTop: 63,
              }}
            >
              {t("event_description")}
            </Title>
            <div
              className="center"
              style={{
                width: "auto",
                height: 24,
                backgroundColor: "#EB5F11",
                paddingInline: 10,
                borderRadius: 5,
                alignSelf: "flex-start",
                marginTop: 14,
              }}
            >
              <Title fontSize={12} color={colors.textWhite}>
                {checkInMissions.some((mission) => !mission.satisfied)
                  ? t("contains_10_missions")
                  : t("event_missions_completed")}
              </Title>
            </div>
          </Stack>
        </ButtonBase>
      </div>
      <div
        style={{
          width: 46,
          height: 46,
          borderRadius: "100%",
          backgroundColor: checkInMissions.some((mission) => !mission.satisfied)
            ? colors.backgroundWhite
            : colors.primary,
          position: "absolute",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid " + colors.primary,
        }}
      >
        {checkInMissions.some((mission) => !mission.satisfied) ? (
          <Text
            fontSize={14}
            lineHeight="19.6px"
            fontWeight={700}
            color={colors.primary}
          >
            {checkInMissions.filter((mission) => mission.satisfied).length}/
            {checkInMissions.length}
          </Text>
        ) : (
          <img src={arrowRightWhiteIcon} style={{ width: 14 }} alt="" />
        )}
      </div>
    </motion.div>
  )
}

export default EventCardMobile
