import { ButtonBase, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { colors } from "../../services/config/colors"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import FadeFromTop from "../animations/FadeFromTop"
import infoIcon from "../../assets/icons/info-primary.svg"
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { ChallengeContext } from "../../controllers/challenge"
import AppearFromSide from "../animations/AppearFromSide"
import months from "../../services/config/months"
import ChallengeProgressBar from "./common/ChallengeProgressBar"
import AlertInfo from "../global/common/AlertInfo"
import SavingCard from "../actions/common/SavingCard"

const ChallengeMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { challenge, challengeSavings } = useContext(ChallengeContext)

  // what is a challenge alert
  const [challengeInfoAlertOpen, setChallengeInfoAlertOpen] =
    useState<boolean>(false)

  return (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 36,
        }}
      >
        {/* back button */}
        <BackButtonMobile
          style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
          onClick={() => {
            navigate("/")
          }}
        />
        {/* top background */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 170,
            minHeight: 170,
            backgroundImage: `url(${challenge?.image})`,
            backgroundSize: "cover",
            backgroundPosition: "20%",
            top: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            display: "flex",
            justifyContent: "center",
          }}
        />
        {/* header */}
        <FadeFromTop
          style={{
            width: "calc(100% - 32px)",
            marginTop: 125,
          }}
        >
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              backgroundColor: colors.backgroundWhite,
              boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
              borderRadius: 10,
              position: "relative",
              padding: 24,
              gap: 16,
            }}
          >
            <Text
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              color={colors.primary}
              component="h1"
            >
              {t("collective_challenge").toUpperCase()}
            </Text>
            <Title fontSize={20} lineHeight="25px">
              {challenge!.title}
            </Title>
            <ButtonBase
              disableRipple
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => {
                setChallengeInfoAlertOpen(true)
              }}
            >
              <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
              <Text
                fontSize={12}
                lineHeight="16px"
                fontWeight={700}
                style={{ marginLeft: 5 }}
                color={colors.primary}
              >
                {t("what_is_a_challenge")}
              </Text>
            </ButtonBase>
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 38,
                position: "relative",
              }}
            >
              <Stack style={{ gap: 4 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("starts")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(challenge!.startDate).getDate()} ${t(
                    months[new Date(challenge!.startDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    challenge!.startDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
              <Stack style={{ gap: 4, position: "absolute", right: 0 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("ends")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(challenge!.endDate).getDate()} ${t(
                    months[new Date(challenge!.endDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    challenge!.endDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              style={{ width: "100%", position: "relative" }}
            >
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
              >
                {challenge!.currentAmount} {t("missions").toLowerCase()}
              </Text>
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
                style={{ position: "absolute", right: 0 }}
              >
                {challenge!.targetAmount} {t("missions").toLowerCase()}
              </Text>
            </Stack>
            <ChallengeProgressBar
              percentage={Math.round(
                (challenge!.currentAmount / challenge!.targetAmount) * 100
              )}
              style={{ marginTop: -8 }}
            />
          </Stack>
        </FadeFromTop>
        {/* challenge body */}
        {/* description */}
        <AppearFromSide fade style={{ width: "100%", marginTop: 24 }}>
          <Stack style={{ paddingInline: 16 }}>
            <Title
              fontSize={18}
              lineHeight="25px"
              style={{ marginBottom: 16 }}
              component="h2"
            >
              {t("description")}
            </Title>
            <div
              className="html"
              dangerouslySetInnerHTML={{
                __html: challenge!.description
                  .replace(":earth_africa:", "🌍")
                  .replace(":100:", "💯")
                  .replace(":gift:", "🎁")
                  .replace("<a", "<br /><br /><a"),
              }}
              style={{
                fontSize: 16,
                fontWeight: 300,
                lineHeight: "22px",
                color: colors.text,
                overflow: "hidden",
              }}
            />
          </Stack>
        </AppearFromSide>
        {/* savings */}
        {challengeSavings &&
        (challengeSavings.co2Saving ||
          challengeSavings.waterSaving ||
          challengeSavings.energySaving) ? (
          <AppearFromSide
            fade
            delay={0.05}
            style={{ width: "100%", marginTop: 24 }}
          >
            <Stack>
              <Title
                fontSize={18}
                lineHeight="25px"
                paddingLeft={16}
                component="h2"
              >
                {t("community_savings")}
              </Title>
              <Stack
                direction="row"
                gap={1.5}
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  paddingInline: 16,
                  marginTop: 16,
                }}
              >
                {challengeSavings.co2Saving ? (
                  <SavingCard
                    metric="co2"
                    amount={challengeSavings.co2Saving}
                  />
                ) : null}
                {challengeSavings.waterSaving ? (
                  <SavingCard
                    metric="water"
                    amount={challengeSavings.waterSaving}
                  />
                ) : null}
                {challengeSavings.energySaving ? (
                  <SavingCard
                    metric="energy"
                    amount={challengeSavings.energySaving}
                  />
                ) : null}
              </Stack>
            </Stack>
          </AppearFromSide>
        ) : null}
      </div>
      {/* challenge info alert */}
      <AlertInfo
        open={challengeInfoAlertOpen}
        setOpen={setChallengeInfoAlertOpen}
        title={t("what_is_a_challenge")}
        description={t("challenges_description")}
        descriptionAlign="center"
      />
    </div>
  )
}

export default ChallengeMobile
