import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import AvatarSelectionMobile from "./AvatarSelectionMobile"

const AvatarSelectionDesktop = ({
  selectedAvatar,
  setSelectedAvatar,
  currentPage,
  setCurrentPage,
}: {
  selectedAvatar: string
  setSelectedAvatar: Dispatch<SetStateAction<string>>
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <AvatarSelectionMobile
        selectedAvatar={selectedAvatar}
        setSelectedAvatar={setSelectedAvatar}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        height="100%"
        continueButtonWidth={330}
      />
    </Stack>
  )
}

export default AvatarSelectionDesktop
