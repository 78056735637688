import { useContext } from "react"
import { MissionsContext } from "../../../controllers/missions"
import { Stack } from "@mui/material"
import trophyWhiteIcon from "../../../assets/icons/trophy-white.svg"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { t } from "i18next"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"

const TicketMissionCardMobile = ({
  index,
  slidesLength,
}: {
  index: number
  slidesLength: number
}) => {
  const { currentSlide } = useContext(MissionsContext)

  return (
    <div
      style={{
        width: 256,
        height: "100%",
        maxHeight: 340,
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
      aria-hidden={
        Math.abs(currentSlide % slidesLength) === index ? "false" : "true"
      }
    >
      <Stack
        alignItems="center"
        style={{
          width: "100%",
          height: "calc(100% - 23px)",
          borderRadius: 20,
          backgroundColor: colors.missions.act,
          paddingTop: 22,
          paddingInline: 16,
          position: "relative",
          boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
        }}
      >
        <img src={trophyWhiteIcon} style={{ width: 40 }} alt="" />
        <Title
          fontSize={18}
          lineHeight="21px"
          color={colors.textWhite}
          textAlign="center"
          style={{ marginTop: 18 }}
        >
          {t("mission_completed").toUpperCase() + "!"}
        </Title>
        <Text
          fontSize={16}
          fontWeight={500}
          lineHeight="19px"
          color={colors.textWhite}
          textAlign="center"
          style={{ width: "100%", marginTop: 8, fontStyle: "italic" }}
          paddingLeft={16}
          paddingRight={16}
        >
          {t("ticket_got")}
        </Text>
        <PointsBadge points={10} style={{ position: "absolute", bottom: 91 }} />
      </Stack>
      {/* completion dot */}
      <div
        style={{
          width: 46,
          height: 46,
          borderRadius: "100%",
          backgroundColor: colors.missions.act,
          border: "2px solid " + colors.backgroundWhite,
          position: "absolute",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={checkWhiteIcon}
          style={{ width: 24, transform: "scale(1.1)" }}
          alt={t("mission_completed")}
        />
      </div>
    </div>
  )
}

export default TicketMissionCardMobile
