import { ButtonBase, Popover, Stack } from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react"
import Text from "./Text"
import chevronDownIcon from "../../../assets/icons/chevron-down.svg"
import "../../../styles/selectBig.css"
import { colors } from "../../../services/config/colors"

const SelectBig = ({
  options,
  selectedOption,
  setSelectedOption,
  disabled = false,
  style,
  width = 92,
  height = 56,
  borderRadius = 12,
  startingIcon,
  paperMinWidth,
}: {
  options: string[]
  selectedOption: number
  setSelectedOption: Dispatch<SetStateAction<number>>
  disabled?: boolean
  style?: CSSProperties
  width?: number | string
  height?: number
  borderRadius?: number
  startingIcon?: ReactNode
  paperMinWidth?: number
}) => {
  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div style={style}>
      <ButtonBase
        disabled={disabled}
        className={disabled ? "select-big-disabled" : "select-big"}
        style={{
          width: width,
          height: height,
          paddingLeft: 12,
          borderRadius: borderRadius,
          backgroundColor: disabled ? "#FAFAFA" : colors.backgroundWhite,
        }}
        onClick={handleClick}
        aria-label={options[selectedOption]}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {startingIcon}
          <Text
            fontSize={16}
            fontWeight={500}
            color={disabled ? "#545454" : colors.text}
          >
            {options[selectedOption]}
          </Text>
          <img
            src={chevronDownIcon}
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              right: 12,
              transform: open ? "rotate(180deg)" : "none",
              transition: "150ms",
            }}
            alt=""
          />
        </Stack>
      </ButtonBase>
      <Popover
        className="select-big-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          style={{
            width: width,
            minWidth: paperMinWidth,
          }}
        >
          {options.map((option, index) => (
            <ButtonBase
              key={index}
              style={{
                width: "100%",
                height: height,
                paddingLeft: 15,
                justifyContent: "flex-start",
                backgroundColor:
                  selectedOption === index
                    ? colors.backgroundLight
                    : colors.backgroundWhite,
              }}
              onClick={() => {
                setSelectedOption(index)
                handleClose()
              }}
              aria-label={option}
            >
              <Text fontSize={16} lineHeight="18px" fontWeight={500}>
                {option}
              </Text>
            </ButtonBase>
          ))}
        </Stack>
      </Popover>
    </div>
  )
}

export default SelectBig
