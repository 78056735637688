import { motion } from "framer-motion"
import { colors } from "../../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import closeWhiteIcon from "../../../assets/icons/close-white.svg"
import MenuButtonMobile from "./MenuButtonMobile"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Title from "../../global/common/Title"
import ticketImage from "../../../assets/images/ticket.png"
import TicketModal from "../../global/common/TicketModal"
import { MainContext } from "../../../controllers/main"
import { EventStatus } from "../../../services/config/enum"
import { MarketplaceContext } from "../../../controllers/marketplace"

const MenuMobile = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { eventStatus, setViewTutorial } = useContext(MainContext)
  const { getProducts } = useContext(MarketplaceContext)

  // ticket modal
  const [ticketOpen, setTicketOpen] = useState<boolean>(false)

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={{
        open: { x: 0 },
        closed: { x: "-100%" },
      }}
      aria-hidden={open ? "false" : "true"}
      role="dialog"
      transition={{ type: "spring", damping: 20, stiffness: 200 }}
      initial={false}
      style={{
        width: "calc(100% + 40px)",
        height: "100%",
        backgroundColor: colors.backgroundWhite,
        position: "fixed",
        left: -40,
        zIndex: 15,
        overflowY: "scroll",
      }}
    >
      <Stack
        style={{
          width: "100%",
          height: "100%",
          minHeight: localStorage.getItem("fromApp") ? 830 : 900,
          position: "relative",
          paddingLeft: 56,
        }}
      >
        <ButtonBase
          disableRipple
          style={{
            position: "absolute",
            top: 24,
            right: 16,
            zIndex: 1,
          }}
          onClick={() => {
            setOpen(false)
          }}
          aria-label={t("close")}
          aria-hidden={open ? "false" : "true"}
          tabIndex={open ? 0 : -1}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <Title fontSize={16} color={colors.primary}>
              {t("menu")}
            </Title>
            <div
              className="center"
              style={{
                width: 36,
                height: 36,
                borderRadius: "100%",
                backgroundColor: colors.primary,
              }}
            >
              <img src={closeWhiteIcon} style={{ width: 10 }} alt="" />
            </div>
          </Stack>
        </ButtonBase>
        <Stack
          style={{
            width: "calc(100% - 16px)",
            marginTop: 100,
            position: "relative",
            zIndex: 1,
          }}
        >
          <MenuButtonMobile
            title={t("missions")}
            onClick={() => {
              setOpen(false)
            }}
            hidden={open ? "false" : "true"}
          />
          {eventStatus !== EventStatus.HIDDEN && (
            <MenuButtonMobile
              title={t("event")}
              onClick={() => {
                setOpen(false)
                setTimeout(() => {
                  navigate("/event")
                }, 200)
              }}
              hidden={open ? "false" : "true"}
            />
          )}
          <MenuButtonMobile
            title={t("marketplace")}
            onClick={() => {
              setOpen(false)
              getProducts()
              setTimeout(() => {
                navigate("/store")
              }, 200)
            }}
            hidden={open ? "false" : "true"}
          />
          <MenuButtonMobile
            title={t("challenge")}
            onClick={() => {
              setOpen(false)
              setTimeout(() => {
                navigate("/challenge")
              }, 200)
            }}
            hidden={open ? "false" : "true"}
          />
          <MenuButtonMobile
            title={t("performance")}
            onClick={() => {
              setOpen(false)
              setTimeout(() => {
                navigate("/performance")
              }, 200)
            }}
            hidden={open ? "false" : "true"}
          />
          <MenuButtonMobile
            title={t("tutorial")}
            onClick={() => {
              setOpen(false)
              setTimeout(() => {
                setViewTutorial(true)
              }, 200)
            }}
            hidden={open ? "false" : "true"}
          />
          <MenuButtonMobile
            title={t("about_aworld")}
            onClick={() => {
              window.open("https://aworld.org/")
            }}
            hidden={open ? "false" : "true"}
            withBorder={localStorage.getItem("fromApp") ? false : true}
          />
          {localStorage.getItem("fromApp") ? null : (
            <MenuButtonMobile
              title={t("exit")}
              withBorder={false}
              onClick={() => {
                localStorage.clear()
                navigate("/")
                window.location.reload()
              }}
              hidden={open ? "false" : "true"}
            />
          )}
        </Stack>
        {/* ticket */}
        <Stack
          gap={3}
          alignItems="center"
          style={{
            width: "calc(100% - 72px)",
            position: "absolute",
            bottom: 36,
          }}
        >
          <Title fontSize={18}>Il tuo biglietto</Title>
          <ButtonBase
            aria-label={t("open_ticket")}
            style={{ width: 300 }}
            onClick={() => {
              setTicketOpen(true)
            }}
          >
            <img src={ticketImage} style={{ width: "100%" }} alt="" />
          </ButtonBase>
        </Stack>
      </Stack>
      <TicketModal open={ticketOpen} setOpen={setTicketOpen} />
    </motion.div>
  )
}

export default MenuMobile
