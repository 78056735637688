import Carousel from "react-spring-3d-carousel"
import MissionCardMobile from "./MissionCardMobile"
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  TouchEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import CompletedMissionCardMobile from "./CompletedMissionsCardMobile"
import { MissionsContext } from "../../../controllers/missions"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../../controllers/main"
import { ButtonBase } from "@mui/material"
import { colors } from "../../../services/config/colors"
import chevronLeftCircularIcon from "../../../assets/icons/chevron-left-circular.svg"
import chevronRightCircularIcon from "../../../assets/icons/chevron-right-circular.svg"
import { useTranslation } from "react-i18next"
import { focusElement } from "../../../services/utils/utils"
import EventCardMobile from "./EventCardMobile"
import { EventStatus } from "../../../services/config/enum"
import TicketMissionCardMobile from "./TicketMissionCardMobile"

const MissionsCarouselMobile = ({
  setBottomSheetOpen,
  setPerformanceOpen,
  setComeBackAlertOpen,
  style,
}: {
  setBottomSheetOpen: Dispatch<SetStateAction<boolean>>
  setPerformanceOpen: Dispatch<SetStateAction<boolean>>
  setComeBackAlertOpen: Dispatch<SetStateAction<boolean>>
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowWidth, viewTutorial, setCurrentMission, eventStatus } =
    useContext(MainContext)
  const { currentSlide, setCurrentSlide, missions } =
    useContext(MissionsContext)

  // using another state for carousel current slide to make component change slide
  const [slide, setSlide] = useState<number>(0)

  useEffect(() => {
    setSlide(currentSlide)
  }, [currentSlide]) // eslint-disable-line react-hooks/exhaustive-deps

  // hide carousel controls (for mission cards opening animation)
  const [carouselControlsHidden, setCarouselControlsHidden] =
    useState<boolean>(false)

  // carousel slides
  const slides = useMemo(() => {
    if (
      eventStatus === EventStatus.LOCKED ||
      eventStatus === EventStatus.HIDDEN
    ) {
      return [
        ...missions.map((mission, index) => {
          return {
            key: index,
            content: (
              <MissionCardMobile
                index={index}
                slidesLength={missions.length + 1}
                color={mission.color}
                title={mission.title}
                titleColor={mission.titleColor}
                goButtonColor={mission.goButtonColor}
                image={mission.image}
                current={mission.current}
                target={mission.target}
                points={mission.points}
                satisfied={mission.satisfied}
                missionType={mission.missionType}
                missionSubType={mission.missionSubType}
                missionTypeId={mission.missionTypeId}
                period={mission.period}
                setBottomSheetOpen={setBottomSheetOpen}
                onClick={() => {
                  setCurrentMission(mission)
                  localStorage.setItem(
                    "currentMission",
                    JSON.stringify(mission)
                  )

                  if (
                    mission.navigationPath === "/performance/footprint/form"
                  ) {
                    navigate(mission.navigationPath, {
                      state: { formId: mission.missionTypeId },
                    })
                  } else {
                    navigate(mission.navigationPath)
                  }
                }}
                end={mission.end}
                currentPerDay={mission.currentPerDay}
                targetPerDay={mission.targetPerDay}
                setComeBackAlertOpen={setComeBackAlertOpen}
                setCarouselControlsHidden={setCarouselControlsHidden}
                updatedAt={mission.updatedAt}
              />
            ),
          }
        }),
        // {
        //   key: missions.length,
        //   content: (
        //     <TicketMissionCardMobile
        //       index={missions.length}
        //       slidesLength={missions.length + 3}
        //     />
        //   ),
        // },
        // {
        //   key: missions.length + 1,
        //   content: (
        //     <EventCardMobile
        //       index={missions.length + 1}
        //       slidesLength={missions.length + 3}
        //       setBottomSheetOpen={setBottomSheetOpen}
        //       setCarouselControlsHidden={setCarouselControlsHidden}
        //     />
        //   ),
        // },
        {
          key: missions.length,
          content: (
            <CompletedMissionCardMobile
              index={missions.length}
              slidesLength={missions.length + 1}
              setPerformanceOpen={setPerformanceOpen}
            />
          ),
        },
      ]
    } else {
      return [
        {
          key: 0,
          content: (
            <EventCardMobile
              index={0}
              slidesLength={missions.length + 3}
              setBottomSheetOpen={setBottomSheetOpen}
              setCarouselControlsHidden={setCarouselControlsHidden}
            />
          ),
        },
        ...missions.map((mission, index) => {
          return {
            key: index + 1,
            content: (
              <MissionCardMobile
                index={index + 1}
                slidesLength={missions.length + 3}
                color={mission.color}
                title={mission.title}
                titleColor={mission.titleColor}
                goButtonColor={mission.goButtonColor}
                image={mission.image}
                current={mission.current}
                target={mission.target}
                points={mission.points}
                satisfied={mission.satisfied}
                missionType={mission.missionType}
                missionSubType={mission.missionSubType}
                missionTypeId={mission.missionTypeId}
                period={mission.period}
                setBottomSheetOpen={setBottomSheetOpen}
                onClick={() => {
                  setCurrentMission(mission)
                  localStorage.setItem(
                    "currentMission",
                    JSON.stringify(mission)
                  )

                  if (
                    mission.navigationPath === "/performance/footprint/form"
                  ) {
                    navigate(mission.navigationPath, {
                      state: { formId: mission.missionTypeId },
                    })
                  } else {
                    navigate(mission.navigationPath)
                  }
                }}
                end={mission.end}
                currentPerDay={mission.currentPerDay}
                targetPerDay={mission.targetPerDay}
                setComeBackAlertOpen={setComeBackAlertOpen}
                setCarouselControlsHidden={setCarouselControlsHidden}
                updatedAt={mission.updatedAt}
              />
            ),
          }
        }),
        {
          key: missions.length + 1,
          content: (
            <TicketMissionCardMobile
              index={missions.length + 1}
              slidesLength={missions.length + 3}
            />
          ),
        },
        {
          key: missions.length + 2,
          content: (
            <CompletedMissionCardMobile
              index={missions.length + 2}
              slidesLength={missions.length + 3}
              setPerformanceOpen={setPerformanceOpen}
            />
          ),
        },
      ]
    }
  }, [missions, eventStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  // handle touch input
  const touchChecked = useRef<boolean>(false)
  const [xDown, setXDown] = useState<number>(0)
  const [yDown, setYDown] = useState<number>(0)

  const getTouches = (e: any) => {
    return (
      e.touches || e.originalEvent.touches // browser API
    )
  }

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const firstTouch = getTouches(e)[0]
    setXDown(firstTouch.clientX)
    setYDown(firstTouch.clientY)
  }

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    let xUp = e.touches[0].clientX
    let yUp = e.touches[0].clientY

    let xDiff = xDown - xUp
    let yDiff = yDown - yUp
    if (Math.abs(xDiff) > Math.abs(yDiff) && !touchChecked.current) {
      if (xDiff > 0) {
        // left swipe
        setCurrentSlide((current) => (current += 1))
      } else {
        // right swipe
        if (currentSlide - 1 >= 0) {
          setCurrentSlide((current) => (current -= 1))
        }
      }
      touchChecked.current = true
    }
  }

  const handleTouchEnd = () => {
    touchChecked.current = false
  }

  return (
    <div
      style={{
        width: "100%",
        height: "42vh",
        minHeight: 340,
        maxHeight: 340,
        position: "relative",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {missions.length && !viewTutorial ? (
        <ButtonBase
          disabled={currentSlide - 1 < 0}
          style={{
            width: 46,
            height: 46,
            borderRadius: "100%",
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            left: 10,
            zIndex: 2,
            boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.16)",
            opacity: carouselControlsHidden ? 0 : 1,
            transition: "opacity 100ms",
          }}
          onClick={() => {
            setCurrentSlide((current) => (current -= 1))
            focusElement("carousel-container-mobile")
          }}
          aria-label={t("previous_mission")}
        >
          <img
            src={chevronLeftCircularIcon}
            style={{
              width: "100%",
              opacity: currentSlide - 1 < 0 ? 0.3 : 1,
              transition: "150ms",
            }}
            alt=""
          />
        </ButtonBase>
      ) : null}
      <div
        id="carousel-container-mobile"
        style={{
          width: "100%",
          height: "100%",
        }}
        tabIndex={0}
      >
        <Carousel
          slides={slides}
          goToSlide={slide}
          showNavigation={false}
          offsetRadius={windowWidth < 500 ? 1 : missions.length}
        />
      </div>
      {missions.length && !viewTutorial ? (
        <ButtonBase
          style={{
            width: 46,
            height: 46,
            borderRadius: "100%",
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            right: 10,
            zIndex: 1,
            boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.16)",
            opacity: carouselControlsHidden ? 0 : 1,
            transition: "opacity 100ms",
          }}
          onClick={() => {
            setCurrentSlide((current) => (current += 1))
            focusElement("carousel-container-mobile")
          }}
          aria-label={t("next_mission")}
        >
          <img
            src={chevronRightCircularIcon}
            style={{ width: "100%" }}
            alt=""
          />
        </ButtonBase>
      ) : null}
    </div>
  )
}

export default MissionsCarouselMobile
