import itFlagImage from "../../assets/images/flags/it.svg"
import enFlagImage from "../../assets/images/flags/en.svg"
import esFlagImage from "../../assets/images/flags/es.svg"

// config
export const shoudlShowApiLogs = false
export const maximumActionsPerTime = 10
export const mobileBreakpoint = 1280
export const accessibilityHeightThreshold = 350
export const onboardingElementsMaxWidth = 600
export const availableLanguages = [
  { code: "it", label: "italian", flag: itFlagImage },
  { code: "en", label: "english", flag: enFlagImage },
  { code: "es", label: "spanish", flag: esFlagImage },
]
export const superUsers = [
  "aa281a3d-c14b-488f-8588-4f61239eec42",
  // "54c07e20-4baa-40ba-90f5-83721fbd1b2c",
  // "01e65610-73f0-40ce-bf91-c47b8211e7db",
  // "cc5ff8f4-b635-48c5-8fcf-ff42785d11cd",
  "",
]

// stories
export const numberOfRows = 43
export const numberOfColumns = 15
export const slideWidth = 360
export const slideHeight = 700

// dimensions
export const navBarHeight = 90
export const footerHeight = 106
export const desktopPadding = "8.984vw"
export const desktopMaxWidth = 1050
export const desktopActionsLogButtonWidth = "39%"
export const desktopActionsLogButtonMaxWidth = 557
export const desktopColumnsGap = 30

// data
export const italianFootprintAverage = 5.72

// utils
export const defaultBoxShadow = "0px 0px 24px rgba(17, 67, 97, 0.08)"
export const defaultBoxShadowDarker = "0px 0px 24px rgba(17, 67, 97, 0.16)"

// earth day event
export const eventStart = "2024-04-19T22:00:00.000Z"
export const eventEnd = "2024-04-20T22:00:00.000Z"
export const highlightEventCardFrom = "2024-04-16T22:00:00.000Z"

// marketplace
export const currency = "PPNT"
