import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import ProductDetailsMobile from "../../components/store/ProductDetailsMobile"
import { useNavigate, useParams } from "react-router-dom"
import { lotteryProducts } from "../../services/data/products"
import { MarketplaceContext } from "../../controllers/marketplace"

const ProductDetails = () => {
  const navigate = useNavigate()
  const { productId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { products, orders, resendEmail } = useContext(MarketplaceContext)

  // this view does not exist in desktop, so if isMobile is false, navigate to previous page
  useEffect(() => {
    if (!isMobile) {
      navigate("/store")
    }
  }, [isMobile])

  // product loading
  const [loading, setLoading] = useState<boolean>(false)

  // current product
  const product = products.some((item) => item.id === productId)
    ? products.find((item) => item.id === productId)!
    : lotteryProducts.find((item) => item.id === productId)!

  // transaction confirmation alert
  const [transactionAlertOpen, setTransactionAlertOpen] =
    useState<boolean>(false)

  // product got alert
  const [productGotAlertOpen, setProductGotAlertOpen] = useState<boolean>(false)

  // no email alert
  const [noEmailAlertOpen, setNoEmailAlertOpen] = useState<boolean>(false)

  // error alert
  const [errorAlertOpen, setErrorAlertOpen] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<string>("")

  // not enough points error alert
  const [noPointsAlertOpen, setNoPointsAlertOpen] = useState<boolean>(false)

  // if email has already been resent for this session or not
  const [emailResent, setEmailResent] = useState<boolean>(false)

  // email resend logic
  const [emailLoading, setEmailLoading] = useState<boolean>(false)

  const callEmailResend = async () => {
    if (orders.some((order) => order.products[0].product.id === productId)) {
      const currentOrder = orders.find(
        (order) => order.products[0].product.id === productId
      )!

      setEmailLoading(true)

      const result = await resendEmail({
        orderId: currentOrder.id,
        productId: currentOrder.products[0].product.id,
        itemId: currentOrder.products[0].item.itemId,
      })

      setEmailLoading(false)
      if (result) {
        setEmailResent(true)
      }

      return result
    }

    return false
  }

  return (
    <ProductDetailsMobile
      loading={loading}
      setLoading={setLoading}
      product={product}
      transactionAlertOpen={transactionAlertOpen}
      setTransactionAlertOpen={setTransactionAlertOpen}
      productGotAlertOpen={productGotAlertOpen}
      setProductGotAlertOpen={setProductGotAlertOpen}
      noEmailAlertOpen={noEmailAlertOpen}
      setNoEmailAlertOpen={setNoEmailAlertOpen}
      errorAlertOpen={errorAlertOpen}
      setErrorAlertOpen={setErrorAlertOpen}
      errorType={errorType}
      setErrorType={setErrorType}
      noPointsAlertOpen={noPointsAlertOpen}
      setNoPointsAlertOpen={setNoPointsAlertOpen}
      emailResent={emailResent}
      emailLoading={emailLoading}
      callEmailResend={callEmailResend}
    />
  )
}

export default ProductDetails
