import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import AvatarSelectionMobile from "../../components/onboarding/AvatarSelectionMobile"
import AvatarSelectionDesktop from "../../components/onboarding/AvatarSelectionDesktop"

const AvatarSelection = () => {
  const { isMobile, avatars } = useContext(MainContext)

  // selected avatar
  const [selectedAvatar, setSelectedAvatar] = useState<string>(
    avatars.length ? avatars[0].url : ""
  )

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  return isMobile ? (
    <AvatarSelectionMobile
      selectedAvatar={selectedAvatar}
      setSelectedAvatar={setSelectedAvatar}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  ) : (
    <AvatarSelectionDesktop
      selectedAvatar={selectedAvatar}
      setSelectedAvatar={setSelectedAvatar}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default AvatarSelection
