import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import { useTranslation } from "react-i18next"
import eventMissionBackgroundImage from "../../../assets/images/event-mission-background.jpg"
import arrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg"
import PointsBadge from "../../global/common/PointsBadge"
import { scrollWindowToTop } from "../../../services/utils/utils"
import FadeFromTop from "../../animations/FadeFromTop"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { MissionsContext } from "../../../controllers/missions"
import Text from "../../global/common/Text"

const EventCardDesktop = ({ forFeedback }: { forFeedback?: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { checkInMissions } = useContext(MissionsContext)

  return (
    <FadeFromTop
      className="mission-card event"
      style={{
        width: "100%",
        position: "relative",
        zoom: forFeedback ? 0.54 : 1,
      }}
      y={-40}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 330,
          height: 461,
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
        onClick={() => {
          scrollWindowToTop()
          navigate("/event")
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100% - 34px)",
            borderRadius: 20,
            backgroundColor: colors.primary,
            position: "relative",
          }}
        >
          <img
            src={eventMissionBackgroundImage}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: 20,
              objectFit: "cover",
            }}
            alt=""
          />
          <ButtonBase
            disabled={forFeedback}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 20,
              boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
              position: "relative",
            }}
            aria-label={t("go_to_specific_mission", {
              mission: t("event_description"),
            })}
          >
            <Stack
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
                paddingInline: 30,
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  position: "absolute",
                  top: 24,
                  right: 25,
                  gap: 10,
                }}
              >
                {/* period badge */}
                {!forFeedback ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "auto",
                      height: 40,
                      borderRadius: 4,
                      paddingInline: 10,
                      border: "1.5px solid " + colors.tertiary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <Title fontSize={18} color={colors.tertiary}>
                      {t("event").toUpperCase()}
                    </Title>
                  </Stack>
                ) : null}
                {/* points badge */}
                {!forFeedback && <PointsBadge points={1000} upTo />}
              </Stack>
              <Title
                fontSize={20}
                lineHeight="23.6px"
                color={colors.textWhite}
                style={{
                  marginTop: 88,
                  width: "100%",
                }}
              >
                {t("event_description")}
              </Title>
              <div
                className="center"
                style={{
                  width: "auto",
                  height: 30,
                  backgroundColor: "#EB5F11",
                  paddingInline: 14,
                  borderRadius: 8,
                  alignSelf: "flex-start",
                  marginTop: 18,
                }}
              >
                <Title fontSize={16} color={colors.textWhite}>
                  {checkInMissions.some((mission) => !mission.satisfied)
                    ? t("contains_10_missions")
                    : t("event_missions_completed")}
                </Title>
              </div>
            </Stack>
          </ButtonBase>
        </div>
        <div
          style={{
            width: 68,
            height: 68,
            borderRadius: "100%",
            backgroundColor: checkInMissions.some(
              (mission) => !mission.satisfied
            )
              ? colors.backgroundWhite
              : colors.primary,
            position: "absolute",
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3.5px solid " + colors.primary,
          }}
        >
          {checkInMissions.some((mission) => !mission.satisfied) ? (
            <Text
              fontSize={16}
              lineHeight="19.6px"
              fontWeight={700}
              color={colors.primary}
            >
              {checkInMissions.filter((mission) => mission.satisfied).length}/
              {checkInMissions.length}
            </Text>
          ) : (
            <img src={arrowRightWhiteIcon} style={{ width: 16 }} alt="" />
          )}
        </div>
      </div>
    </FadeFromTop>
  )
}

export default EventCardDesktop
