export const awPrivacyPolicyIT = `<html>
<head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  <style type="text/css">
    @import url(https://themes.googleusercontent.com/fonts/css?kit=OUdT43qE98k1fLl98sLVVgS_lLQ8UrDjKEIxfxxHeajrlU6h6snYOEP6A6XGsHFo);
    ol {
      margin: 0;
      padding: 0;
    }
    table td,
    table th {
      padding: 0;
    }
    .c1 {
      background-color: #ffffff;
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Figtree";
      font-style: normal;
    }
    .c33 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.7pt;
      padding-bottom: 0pt;
      line-height: 1.1495405435562134;
      text-align: justify;
      margin-right: 37.4pt;
    }
    .c46 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: left;
      margin-right: 5.5pt;
    }
    .c6 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495370864868164;
      text-align: left;
      margin-right: 15.9pt;
    }
    .c22 {
      margin-left: 20pt;
      padding-top: 12.7pt;
      text-indent: -0.2pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 4.1pt;
    }
    .c13 {
      margin-left: 20pt;
      padding-top: 12.7pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 0.7pt;
    }
    .c43 {
      color: #3c78d8;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 8pt;
      font-family: "Montserrat";
      font-style: normal;
    }
    .c10 {
      margin-left: 20pt;
      padding-top: 15.9pt;
      text-indent: -0.6pt;
      padding-bottom: 0pt;
      line-height: 1.1495417356491089;
      text-align: left;
      margin-right: 6.6pt;
    }
    .c36 {
      margin-left: 20pt;
      padding-top: 14.6pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: left;
      margin-right: 13pt;
    }
    .c25 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: 0.3pt;
      padding-bottom: 0pt;
      line-height: 1.1495463848114014;
      text-align: left;
      margin-right: 42.1pt;
    }
    .c35 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.8pt;
      padding-bottom: 0pt;
      line-height: 1.1495336294174194;
      text-align: left;
      margin-right: 21.8pt;
    }
    .c19 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: 0.4pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: left;
      margin-right: 8.8pt;
    }
    .c44 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: 0.3pt;
      padding-bottom: 0pt;
      line-height: 1.1495417356491089;
      text-align: left;
      margin-right: 14.4pt;
    }
    .c7 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c11 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: 0.6pt;
      padding-bottom: 0pt;
      line-height: 1.1495370864868164;
      text-align: left;
      margin-right: 27.5pt;
    }
    .c3 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Figtree";
      font-style: normal;
    }
    .c18 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 6pt;
    }
    .c37 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: -0.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: left;
      margin-right: 30.1pt;
    }
    .c40 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.2pt;
      padding-bottom: 0pt;
      line-height: 1.149538516998291;
      text-align: left;
      margin-right: 4pt;
    }
    .c29 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: 0.6pt;
      padding-bottom: 0pt;
      line-height: 1.1495410203933716;
      text-align: left;
      margin-right: 5.6pt;
    }
    .c39 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: -18.1pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 57.9pt;
    }
    .c38 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: 0.3pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 14.1pt;
    }
    .c0 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Figtree";
      font-style: normal;
    }
    .c41 {
      margin-left: 20pt;
      padding-top: 14.3pt;
      text-indent: -18.1pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 34.9pt;
    }
    .c42 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      text-indent: -17.9pt;
      padding-bottom: 0pt;
      line-height: 1.1495463848114014;
      text-align: left;
      margin-right: 4.9pt;
    }
    .c28 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: 0.6pt;
      padding-bottom: 0pt;
      line-height: 1.1495410203933716;
      text-align: left;
      margin-right: 6pt;
    }
    .c23 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      text-indent: -17.5pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: left;
      margin-right: 14.9pt;
    }
    .c8 {
      margin-left: 20pt;
      padding-top: 14.3pt;
      text-indent: 0.1pt;
      padding-bottom: 0pt;
      line-height: 1.1495441198349;
      text-align: left;
      margin-right: 29.5pt;
    }
    .c26 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      padding-bottom: 0pt;
      line-height: 1.1495400667190552;
      text-align: left;
      margin-right: 9.6pt;
    }
    .c24 {
      margin-left: 20pt;
      padding-top: 12.7pt;
      padding-bottom: 0pt;
      line-height: 1.149538516998291;
      text-align: left;
      margin-right: 0.9pt;
    }
    .c21 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      padding-bottom: 0pt;
      line-height: 1.1495379209518433;
      text-align: left;
      margin-right: 8.7pt;
    }
    .c47 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      padding-bottom: 0pt;
      line-height: 1.149534821510315;
      text-align: center;
      margin-right: 34pt;
    }
    .c31 {
      margin-left: 20pt;
      padding-bottom: 0pt;
      line-height: 1.1495394706726074;
      text-align: center;
      margin-right: 29.5pt;
    }
    .c45 {
      margin-left: 20pt;
      padding-top: 22.3pt;
      padding-bottom: 0pt;
      line-height: 1.0154284238815308;
      text-align: center;
    }
    .c32 {
      padding-top: 2.3pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: right;
      margin-right: 28.9pt;
    }
    .c48 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c16 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c9 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
      height: 11pt;
    }
    .c27 {
      margin-left: 20pt;
      padding-top: 46.3pt;
      padding-bottom: 0pt;
      line-height: 1.0154221057891846;
      text-align: center;
    }
    .c49 {
      margin-left: 20pt;
      padding-top: 27.9pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c14 {
      margin-left: 20pt;
      padding-top: 34.3pt;
      padding-bottom: 0pt;
      line-height: 1.0154221057891846;
      text-align: center;
    }
    .c15 {
      margin-left: 20pt;
      padding-top: 15.9pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c50 {
      margin-left: 20pt;
      padding-top: 14.6pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c4 {
      margin-left: 20pt;
      padding-top: 0.7pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c20 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c34 {
      margin-left: 20pt;
      padding-top: 15.9pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c5 {
      margin-left: 20pt;
      padding-top: 17.5pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c12 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c17 {
      margin-left: 20pt;
      padding-top: 2.3pt;
      padding-bottom: 0pt;
      line-height: 1;
      text-align: left;
    }
    .c30 {
      max-width: 502.8pt;
      padding: 31.5pt 70.2pt 38pt 39pt;
    }
    .c2 {
      background-color: #ffffff;
    }
    .title {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 36pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    .subtitle {
      padding-top: 18pt;
      color: #666666;
      font-size: 24pt;
      padding-bottom: 4pt;
      font-family: "Georgia";
      line-height: 1.15;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    li {
      color: #000000;
      font-size: 11pt;
      font-family: "Arial";
    }
    p {
      margin: 0;
      color: #000000;
      font-size: 11pt;
      font-family: "Arial";
    }
    h1 {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 24pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h2 {
      padding-top: 18pt;
      color: #000000;
      font-weight: 700;
      font-size: 18pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h3 {
      padding-top: 14pt;
      color: #000000;
      font-weight: 700;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h4 {
      padding-top: 12pt;
      color: #000000;
      font-weight: 700;
      font-size: 12pt;
      padding-bottom: 2pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h5 {
      padding-top: 11pt;
      color: #000000;
      font-weight: 700;
      font-size: 11pt;
      padding-bottom: 2pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h6 {
      padding-top: 10pt;
      color: #000000;
      font-weight: 700;
      font-size: 10pt;
      padding-bottom: 2pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
  </style>
</head>
<body class="c2 c30 doc-content">
  <p class="c31">
    <span class="c0 c2"
      >Informativa e richiesta di consenso ai sensi e per gli effetti degli
      artt. 13 e 14 del</span
    ><span class="c0">&nbsp;</span
    ><span class="c0 c2"
      >Regolamento (UE) 2016/679, relativo alla tutela del trattamento dei
      dati personali</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c10">
    <span class="c1"
      >Titolare del Trattamento dei Dati &egrave; AWorld Srl Societ&agrave;
      Benefit, con sede legale in Corso Regina</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Margherita n. 1/bis , 10124 &ndash; Torino, Italia, P.IVA 11867110014,
      in persona del suo legale</span
    ><span class="c3">&nbsp;</span><span class="c1">rappresentante.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c48">
    <span class="c1">Contatti: privacy@aworld.it</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1">Responsabile della Protezione dei Dati</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1"
      >Responsabile della protezione dei dati &egrave; la Dott.ssa Debora
      Marianna Pagano</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c12">
    <span class="c1">Contatti: privacy@emade.pro</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c5">
    <span class="c0 c2">Finalit&agrave; del trattamento</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1">I</span><span class="c3">&nbsp;</span
    ><span class="c1"
      >dati personali forniti dagli utenti verranno utilizzati per:</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c41">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Presentare o migliorare la Piattaforma e i servizi, inclusa
      l&rsquo;analisi delle attivit&agrave; della</span
    ><span class="c3">&nbsp;</span><span class="c1">Piattaforma;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c4">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Informare l&rsquo;Utente dei servizi e prodotti di AWorld
      disponibili;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c16">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Autorizzare l&rsquo;accesso alla Piattaforma e ai servizi;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c23">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Fornire, mantenere o espandere i servizi, le analisi sulle performance
      del business e gli</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">altri prodotti e servizi che offriamo;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c4">
    <span class="c7 c2">&#9679; </span
    ><span class="c1">Offrire e amministrare programmi;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c16">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Personalizzare l&rsquo;esperienza dell&rsquo;Utente;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c16">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Registrare la partecipazione dell&rsquo;Utente ad eventi tramite
      GPS;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c6">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Rendere visibile agli altri partecipanti la partecipazione
      dell&rsquo;Utente ad un determinato</span
    ><span class="c3">&nbsp;</span><span class="c1">evento;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c26">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Creare contenuti, promozioni, concorsi, sondaggi e altre
      funzionalit&agrave; della Piattaforma;</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Gestire la partecipazione dell&rsquo;Utente a programmi speciali,
      sondaggi, campagne,</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >concorsi e altre offerte o promozioni; e per inviare all&rsquo;Utente
      e-mail a riguardo;</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Garantire la sicurezza dei nostri servizi, inclusa
      l&rsquo;autenticazione degli utenti;</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Utilizzare i dati statistici che raccogliamo nelle modalit&agrave;
      previste per legge;</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Supportare gli utenti nell&rsquo;utilizzo della Piattaforma;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c4">
    <span class="c7 c2">&#9679; </span
    ><span class="c1">Rispettare i requisiti di legge applicabili;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c16">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Rispettare le Condizioni d&rsquo;uso e altri accordi;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c16">
    <span class="c7 c2">&#9679; </span
    ><span class="c1">Eseguire analisi sui dati;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c39">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Indagare su possibili frodi o altre violazioni delle Condizioni
      d&rsquo;uso o di questa</span
    ><span class="c3">&nbsp;</span><span class="c1">Informativa;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c4">
    <span class="c7 c2">&#9679; </span
    ><span class="c1">La risoluzione di controversie;</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c32">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Adempiere in altro modo allo scopo per le quali le informazioni sono
      state raccolte.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c8">
    <span class="c1"
      >Chiederemo il consenso dell&rsquo;Utente per raccogliere i dati
      personali per fini diversi da quelli</span
    ><span class="c3">&nbsp;</span><span class="c1">sopra elencati.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c14">
    <span class="c43"
      >AWorld Srl Societ&agrave; Benefit Corso Regina Margherita 1/bis VAT ID
      IT11867110014 10124 Torino - Italy</span
    >
  </p>
  <p class="c9"><span class="c43"></span></p>
  <p class="c50">
    <span class="c0 c2">Categorie di dati trattati</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1">I</span><span class="c3">&nbsp;</span
    ><span class="c1">dati personali trattati sono:</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c20">
    <span class="c1">A - Dati di contatto</span><span class="c3">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1">B - Immagine del profilo e foto degli eventi</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c12">
    <span class="c1">C - Localizzazione</span><span class="c3">&nbsp;</span>
  </p>
  <p class="c17">
    <span class="c1">D - Abitudini di vita e di consumo</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c5">
    <span class="c0 c2">Modalit&agrave; del trattamento</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c19">
    <span class="c1">I</span><span class="c3">&nbsp;</span
    ><span class="c1"
      >dati personali degli utenti sono trattati con le opportune misure di
      sicurezza; tali misure sono</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >utili a impedire la divulgazione, la modifica o altri utilizzi non
      autorizzati. Oltre al Titolare,</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >potrebbero avere accesso ai Dati Personali persone autorizzate dal
      Titolare.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c34">
    <span class="c0 c2">Conseguenze del rifiuto dei dati</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c38">
    <span class="c1"
      >In caso di mancata autorizzazione al trattamento dei dati previsti nei
      punti A e D l&#39;interessato</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">non potr&agrave; utilizzare la Piattaforma.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c44">
    <span class="c1"
      >In caso di mancata autorizzazione al trattamento dei dati previsti nei
      punti B e C l&#39;interessato</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">non potr&agrave; utilizzare servizi specifici.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c15">
    <span class="c0 c2">Periodo di conservazione</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c25">
    <span class="c1">I</span><span class="c3">&nbsp;</span
    ><span class="c1"
      >Dati sono trattati e conservati per il tempo richiesto dalle
      finalit&agrave; per le quali sono stati</span
    ><span class="c3">&nbsp;</span><span class="c1">raccolti. Pertanto:</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c24">
    <span class="c7 c2">&#9679; </span><span class="c1">I</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Dati Personali raccolti per scopi collegati all&rsquo;esecuzione di un
      contratto tra il Titolare e</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >l&rsquo;Utente saranno trattenuti sino a quando sia completata
      l&rsquo;esecuzione di tale contratto.</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1">I</span><span class="c3">&nbsp;</span
    ><span class="c1"
      >Dati Personali raccolti per finalit&agrave; riconducibili
      all&rsquo;interesse legittimo del Titolare</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >saranno trattenuti sino al soddisfacimento di tale interesse.
      L&rsquo;Utente pu&ograve; ottenere</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >ulteriori informazioni in merito all&rsquo;interesse legittimo
      perseguito dal Titolare nelle relative</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >sezioni di questo documento o contattando il Titolare.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c42">
    <span class="c7 c2">&#9679; </span><span class="c1">I</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >dati personali raccolti con finalit&agrave; di marketing promozionali
      saranno conservati per un</span
    ><span class="c3">&nbsp;</span><span class="c1">periodo di 24 mesi.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c35">
    <span class="c7 c2">&#9679; </span><span class="c1">Il</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Titolare potrebbe essere obbligato a conservare i Dati Personali per un
      periodo pi&ugrave;</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >lungo in ottemperanza ad un obbligo di legge o per ordine di
      un&rsquo;autorit&agrave;.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c18">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >Al termine del periodo di conservazioni i Dati Personali saranno
      cancellati. Pertanto, allo</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >spirare di tale termine il diritto di accesso, cancellazione,
      rettificazione ed il diritto alla</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >portabilit&agrave; dei Dati non potranno pi&ugrave; essere
      esercitati.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c49">
    <span class="c0 c2">Diritti dell&#39;interessato</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c37">
    <span class="c1"
      >Gli Utenti possono esercitare determinati diritti con riferimento ai
      Dati trattati dal Titolare.</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">In particolare, l&rsquo;Utente ha il diritto di:</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c13">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >revocare il consenso in ogni momento. L&rsquo;Utente pu&ograve;
      revocare il consenso al trattamento</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >dei propri Dati Personali precedentemente espresso.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c45">
    <span class="c43"
      >AWorld Srl Societ&agrave; Benefit Corso Regina Margherita 1/bis VAT ID
      IT11867110014 10124 Torino - Italy</span
    >
  </p>
  <p class="c9"><span class="c43"></span></p>
  <p class="c36">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >opporsi al trattamento dei propri Dati. L&rsquo;Utente pu&ograve;
      opporsi al trattamento dei propri</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Dati quando esso avviene su una base giuridica diversa dal consenso.
      Ulteriori dettagli</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >sul diritto di opposizione sono indicati nella sezione
      sottostante.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c21">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >accedere ai propri Dati. L&rsquo;Utente ha diritto ad ottenere
      informazioni sui Dati trattati dal</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Titolare, su determinati aspetti del trattamento ed a ricevere una
      copia dei Dati trattati.</span
    ><span class="c3">&nbsp;</span><span class="c7 c2">&#9679; </span
    ><span class="c1"
      >verificare e chiedere la rettificazione. L&rsquo;Utente pu&ograve;
      verificare la correttezza dei propri</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Dati e richiederne l&rsquo;aggiornamento o la correzione.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c47">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >ottenere la limitazione del trattamento. Quando ricorrono determinate
      condizioni,</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >l&rsquo;Utente pu&ograve; richiedere la limitazione del trattamento dei
      propri Dati. In tal caso il</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >Titolare non tratter&agrave; i Dati per alcun altro scopo se non la
      loro conservazione.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c46">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >ottenere la cancellazione o rimozione dei propri Dati Personali. Quando
      ricorrono</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >determinate condizioni, l&rsquo;Utente pu&ograve; richiedere la
      cancellazione dei propri Dati da parte</span
    ><span class="c3">&nbsp;</span><span class="c1">del Titolare.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c40">
    <span class="c7 c2">&#9679; </span
    ><span class="c1"
      >ricevere i propri Dati o farli trasferire ad altro titolare.
      L&rsquo;Utente ha diritto di ricevere i</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >propri Dati in formato strutturato, di uso comune e leggibile da
      dispositivo automatico</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
      ostacoli ad un altro</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >titolare. Questa disposizione &egrave; applicabile quando i Dati sono
      trattati con strumenti</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >automatizzati ed il trattamento &egrave; basato sul consenso
      dell&rsquo;Utente, su un contratto di cui</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >l&rsquo;Utente &egrave; parte o su misure contrattuali ad esso
      connesse.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c33">
    <span class="c2 c7">&#9679; </span
    ><span class="c1"
      >proporre reclamo. L&rsquo;Utente pu&ograve; proporre un reclamo
      all&rsquo;autorit&agrave; di controllo della</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >protezione dei dati personali competente o agire in sede giudiziale.
      Per farlo puoi</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >utilizzare un modello messo a disposizione dal Garante Privacy.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c22">
    <span class="c1"
      >Ai sensi del medesimo articolo si ha il diritto di chiedere la
      cancellazione, la trasformazione in</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >forma anonima o il blocco dei dati trattati in violazione di legge,
      nonch&eacute; di opporsi in ogni caso,</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">per motivi legittimi, al loro trattamento.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c29">
    <span class="c1"
      >L&#39;interessato ha diritto di opporsi, in tutto o in parte: per
      motivi legittimi al trattamento dei dati</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >personali che lo riguardano, ancorch&eacute; pertinenti allo scopo
      della raccolta; al trattamento di dati</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >personali che lo riguardano a fini di invio di materiale pubblicitario
      o di vendita diretta o per il</span
    ><span class="c3">&nbsp;</span
    ><span class="c1">compimento di ricerche di mercato.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c34">
    <span class="c0 c2">Come esercitare i diritti</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c11">
    <span class="c1"
      >Per esercitare i diritti dell&rsquo;Utente, gli Utenti possono
      indirizzare una richiesta agli estremi di</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >contatto del Titolare indicati in questo documento. Le richieste sono
      depositate a titolo</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >gratuito ed evase dal Titolare nel pi&ugrave; breve tempo possibile, in
      ogni caso entro un mese.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c15">
    <span class="c0 c2">Modifiche a questa privacy policy</span
    ><span class="c0">&nbsp;</span>
  </p>
  <p class="c28">
    <span class="c1">Il</span><span class="c3">&nbsp;</span
    ><span class="c1"
      >Titolare del Trattamento si riserva il diritto di apportare modifiche
      alla presente privacy policy</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >in qualunque momento dandone informazione agli Utenti su questa pagina
      e, se possibile</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >qualora tecnicamente e legalmente fattibile, inviando una notifica agli
      Utenti attraverso uno</span
    ><span class="c3">&nbsp;</span
    ><span class="c1"
      >degli estremi di contatto di cui &egrave; in possesso il
      Titolare.</span
    ><span class="c3">&nbsp;</span>
  </p>
  <p class="c27">
    <span class="c43"
      >AWorld Srl Societ&agrave; Benefit Corso Regina Margherita 1/bis VAT ID
      IT11867110014 10124 Torino - Italy</span
    >
  </p>
</body>
</html>
`

export const gdtPrivacyPolicyIT = `
<body class="lp-pom-body lp-sub-page">
<!-- lp:insertions start body:before --><!-- lp:insertions end body:before -->
<div class="lp-element lp-pom-root" id="lp-pom-root">
  <div id="lp-pom-root-color-overlay"></div>
  <div class="lp-positioned-content">
    <div
      class="lp-element lp-pom-text nlh"
      id="lp-pom-text-4"
      style="height: auto"
    >
      <p style="line-height: 34px">
        <span
          style="
            font-weight: 700;
            font-family: Raleway;
            font-size: 36px;
            color: rgb(32, 54, 103);
            font-style: normal;
          "
          >Informazioni da fornire all'interessato</span
        >
      </p>
    </div>
    <div
      class="lp-element lp-pom-text nlh"
      id="lp-pom-text-5"
      style="height: auto"
    >
      <p style="line-height: 34px">
        <span
          style="
            font-weight: 400;
            font-family: Raleway;
            font-size: 28px;
            color: rgb(32, 54, 103);
            font-style: normal;
          "
          >(ex artt. 13 e 14 GDPR 2016/679)</span
        >
      </p>
    </div>
    <div
      class="lp-element lp-pom-text nlh"
      id="lp-pom-text-7"
      style="height: auto"
    >
      <p>
        <span
          style="
            font-family: Raleway;
            font-weight: 400;
            font-style: normal;
            color: rgb(32, 54, 103);
          "
          >La seguente informativa le spiega come raccogliamo, usiamo e
          custodiamo i suoi dati personali per l’evento
          <strong>Giornata della Terra Torino 2024</strong></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Ai sensi dell’art. 13 del Regolamento (UE) 2016/679 del
                Parlamento europeo e del Consiglio del 27 aprile 2016 sulla
                protezione dei dati (di seguito “Regolamento” oppure
                “GDPR”), in relazione al trattamento dei Suoi dati personali
                la informiamo, di quanto segue.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p align="left">
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong
                  >Identità e dati di contatto del Titolare del
                  trattamento</strong
                ></span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >I <strong>co-titolari</strong> sono:</span
              ></span
            ></span
          ></span
        >
      </p>
      <ul>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  ><strong>Aworld</strong
                  ><strong>&nbsp;S.r.l. Società Benefit</strong>, di seguito
                  <strong>Aworld</strong>, con sede legale in Corso Regina
                  Margherita, n. 1 Bis, Torino – 10124 (It), Codice Fiscale
                  11867110014, nella figura del rappresentante legale,</span
                ></span
              ></span
            ></span
          >
        </li>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  ><strong>Associazione Culturale Club&nbsp;</strong
                  ><strong>Silencio</strong>, di seguito<strong
                    >&nbsp;Club&nbsp;</strong
                  ><strong>Silencio</strong><strong>,&nbsp;</strong>con sede
                  legale in C.so Massimo D’Azeglio, n. 60, Torino – 10126
                  (It), Codice Fiscale 97730030018, nella figura del
                  rappresentante legale,</span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong
                  >Finalità del trattamento e base giuridica</strong
                ></span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >I dati personali forniti dagli interessati che partecipano
                all’evento saranno trattati per le seguenti modalità:</span
              ></span
            ></span
          ></span
        >
      </p>
      <ul>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >Senza il suo consenso espresso (art. 6 GDPR) per le
                  seguenti finalità di servizio:</span
                ></span
              ></span
            ></span
          >
          <ul>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >adempiere agli obblighi previsti dalla legge, da un
                      regolamento, dalla normativa comunitaria o da un
                      ordine dell’Autorità;</span
                    ></span
                  ></span
                ></span
              >
            </li>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >esercitare i diritti del Titolare, ad esempio il
                      diritto di difesa in giudizio;</span
                    ></span
                  ></span
                ></span
              >
            </li>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >per la gestione delle richieste di informazione da
                      parte degli utenti attraverso l’invio di mail e altri
                      contatti tramite form;</span
                    ></span
                  ></span
                ></span
              >
            </li>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >per l'iscrizione e partecipazione all’evento Giornata
                      della Terra Torino 2024;</span
                    ></span
                  ></span
                ></span
              >
            </li>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >per l’iscrizione ad attività e workshop.</span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
        </li>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >Solo previo suo specifico e distinto consenso (art. 7
                  GDPR), per le seguenti finalità:</span
                ></span
              ></span
            ></span
          >
          <ul>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >inviarle via mail, newsletter comunicazioni su eventi
                      organizzati o servizi offerti dal Titolare;</span
                    ></span
                  ></span
                ></span
              >
            </li>
            <li>
              <span style="color: rgb(32, 54, 103)"
                ><span style="font-style: normal"
                  ><span style="font-weight: 400"
                    ><span style="font-family: Raleway"
                      >per la pubblicazione e uso di immagini e/o video di
                      cui l'interessato è parte sui canali di comunicazioni
                      istituzionali, a titolo esemplificativo social network
                      e sito.</span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Modalità del trattamento</strong> </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >I suoi dati personali saranno trattati in conformità alle
                disposizioni di legge applicabili in materia di trattamento
                dei dati personali, mediante strumenti sia informatici che
                manuali per la mera realizzazione delle finalità stesse e,
                comunque, in modo da garantirne la sicurezza e la
                riservatezza mediante l’impiego da parte del Titolare misure
                tecniche, tecnologiche e organizzative adeguate in relazione
                ai requisiti previsti dall’art. 32 del Regolamento.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Oltre ai Co-Titolari, potrebbero avere accesso ai Dati
                Personali soggetti autorizzati o Responsabili esterni
                formalmente designati. L’elenco è disponibile presso i
                Contitolari su richiesta.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Conseguenze del rifiuto dei dati</strong> </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Natura del conferimento dei dati e conseguenze del rifiuto
                di rispondere</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Il conferimento dei dati per le finalità di cui al punto
                2.a è obbligatorio. In loro assenza non potremo garantirle
                la partecipazione all’evento.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Il conferimento dei dati per le finalità di cui al punto
                2.b è invece facoltativo. Può quindi decidere di non
                conferire alcun dato o di negare successivamente la
                possibilità di trattare per tali finalità dati già forniti;
                in tal caso non potrà ricevere newsletter, comunicazioni
                commerciali inerenti ai servizi e prodotti offerti dal
                Titolare. Continuerà comunque ad avere diritto ai servizi di
                cui al punto 2.a</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong
                  >Trasferimento dei dati personali a un Paese terzo</strong
                ></span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Nessun dato personale raccolto ai sensi della presente
                informativa sarà trasferito e conservato in Paesi
                terzi.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Periodo di conservazione</strong></span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >I dati personali sono conservati secondo i seguenti
                criteri:</span
              ></span
            ></span
          ></span
        >
      </p>
      <ul>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >per il tempo strettamente necessario a conseguire le
                  finalità connesse allo svolgimento dell’evento per le
                  quali sono trattati;</span
                ></span
              ></span
            ></span
          >
        </li>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >per il tempo strettamente necessario all'adempimento di
                  obblighi di legge. I suoi dati personali potranno essere
                  inseriti negli archivi storici del titolare con qualsiasi
                  limite di tempo;</span
                ></span
              ></span
            ></span
          >
        </li>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >I dati personali quali immagini e video saranno
                  conservati per il tempo strettamente necessario al
                  perseguimento delle finalità indicate nella presente
                  informativa</span
                ></span
              ></span
            ></span
          >
        </li>
        <li>
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >I dati personali raccolti con finalità di marketing
                  promozionali saranno conservati per un termine di 24
                  mesi.</span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Al termine del periodo di conservazione i Dati Personali
                saranno cancellati o resi anonimi. Pertanto, allo spirare di
                tale termine il diritto di accesso, cancellazione,
                rettificazione ed il diritto alla portabilità dei Dati non
                potranno più essere esercitati.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p align="left">
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Diritti dell’interessato</strong></span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Gli interessati possono esercitare i diritti di cui agli
                artt. 15-22 del GDPR.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p align="left">
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >In particolare, l’interessato ha il diritto di:</span
              ></span
            ></span
          ></span
        >
      </p>
      <ul>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >revocare il consenso in ogni momento. L’interessato può
                  revocare il consenso al trattamento dei propri Dati
                  Personali precedentemente espresso in qualsiasi
                  momento.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >opporsi al trattamento dei propri Dati. L’interessato può
                  opporsi al trattamento dei propri Dati quando esso avviene
                  su una base giuridica diversa dal consenso.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >accedere ai propri Dati. L’interessato ha diritto ad
                  ottenere informazioni sui Dati trattati dal Titolare, su
                  determinati aspetti del trattamento ed a ricevere una
                  copia dei Dati trattati.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >verificare e chiedere la rettifica. L’interessato può
                  verificare la correttezza dei propri Dati e richiederne
                  l’aggiornamento o la correzione.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >ottenere la limitazione del trattamento. Quando ricorrono
                  determinate condizioni, L’interessato può richiedere la
                  limitazione del trattamento dei propri Dati. In tal caso
                  il Titolare non tratterà i Dati per alcun altro scopo se
                  non la loro conservazione.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >ottenere la cancellazione o rimozione dei propri Dati
                  Personali. Quando ricorrono determinate condizioni,
                  L’interessato può richiedere la cancellazione dei propri
                  Dati da parte del Titolare.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >ricevere i propri Dati o farli trasferire ad altro
                  titolare. L’interessato, ove possibile, ha diritto di
                  ricevere i propri Dati in formato strutturato, di uso
                  comune e leggibile da dispositivo automatico e, ove
                  tecnicamente fattibile, di ottenerne il trasferimento
                  senza ostacoli ad un altro titolare.</span
                ></span
              ></span
            ></span
          >
        </li>
        <li align="left">
          <span style="color: rgb(32, 54, 103)"
            ><span style="font-style: normal"
              ><span style="font-weight: 400"
                ><span style="font-family: Raleway"
                  >proporre reclamo. L’interessato può proporre un reclamo
                  all’autorità di controllo della protezione dei dati
                  personali competente o agire in sede giudiziale. Per farlo
                  puoi utilizzare un modello messo a disposizione dal
                  Garante Privacy.</span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Ai sensi del medesimo articolo si ha il diritto di chiedere
                la cancellazione, la trasformazione in forma anonima o il
                blocco dei dati trattati in violazione di legge, nonché di
                opporsi in ogni caso, per motivi legittimi, al loro
                trattamento.</span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Come esercitare i diritti</strong> </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Per esercitare i diritti dell’Utente, gli Utenti possono
                indirizzare una richiesta agli estremi di contatto del
                Titolare indicati in questo documento. Le richieste sono
                depositate a titolo gratuito ed evase dal Titolare nel più
                breve tempo possibile, in ogni caso entro un mese. </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                ><strong>Modifiche a questa privacy policy</strong> </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Il Titolare del Trattamento si riserva il diritto di
                apportare modifiche alla presente privacy policy in
                qualunque momento dandone informazione agli Utenti su questa
                pagina e, se possibile qualora tecnicamente e legalmente
                fattibile, inviando una notifica agli Utenti attraverso uno
                degli estremi di contatto di cui è in possesso il Titolare.
                Si prega dunque di consultare regolarmente questa pagina,
                facendo riferimento alla data di ultima modifica indicata in
                fondo. </span
              ></span
            ></span
          ></span
        >
      </p>
      <p>
        <span style="color: rgb(32, 54, 103)"
          ><span style="font-style: normal"
            ><span style="font-weight: 400"
              ><span style="font-family: Raleway"
                >Ultima modifica 7 Marzo 2024</span
              ></span
            ></span
          ></span
        >
      </p>
      <p><br /></p>
    </div>
  </div>
  <div class="lp-element lp-pom-block" id="lp-pom-block-1">
    <div id="lp-pom-block-1-color-overlay"></div>
    <div class="lp-pom-block-content"></div>
  </div>
</div>
<!-- lp:insertions start body:after -->
<script
  async=""
  src="//builder-assets.unbounce.com/published-js/main.bundle-4ea5d01.z.js"
  type="text/javascript"
></script>
<!-- lp:insertions end body:after -->
</body>

`
