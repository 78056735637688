export const contestRulesIT = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLU6URoVWdvrt6olE8F0BqMI5MfgddeQ9non9ijnpIvRUYa6Sib-t7rjQHe5jbTUQCA);
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c3 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: italic;
      }
      .c71 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c40 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: left;
        margin-right: -0.2pt;
      }
      .c74 {
        margin-left: 36.5pt;
        padding-top: 0pt;
        text-indent: -17.5pt;
        padding-bottom: 0pt;
        line-height: 1.0195008516311646;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c5 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c63 {
        margin-left: 0.2pt;
        padding-top: 0.6pt;
        text-indent: -0pt;
        padding-bottom: 0pt;
        line-height: 1.0169544219970703;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c66 {
        margin-left: 36.5pt;
        padding-top: 0.4pt;
        text-indent: -17.5pt;
        padding-bottom: 0pt;
        line-height: 1.0174500942230225;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c12 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.0162596702575684;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c11 {
        margin-left: 0.9pt;
        padding-top: 0.6pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c68 {
        margin-left: 36.5pt;
        padding-top: 0.4pt;
        text-indent: -17.5pt;
        padding-bottom: 0pt;
        line-height: 1.0164583921432495;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c24 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Noto Sans Symbols";
        font-style: normal;
      }
      .c65 {
        margin-left: 36.9pt;
        padding-top: 0pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0176507234573364;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c16 {
        margin-left: 0.1pt;
        padding-top: 0.4pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 1.0165200233459473;
        text-align: left;
        margin-right: 1.2pt;
      }
      .c20 {
        margin-left: 36.5pt;
        padding-top: 0.4pt;
        text-indent: -17.5pt;
        padding-bottom: 0pt;
        line-height: 1.0159825086593628;
        text-align: left;
        margin-right: 1.2pt;
      }
      .c54 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.0189441442489624;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c51 {
        margin-left: 0.9pt;
        padding-top: 0pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 1.0176507234573364;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times";
        font-style: normal;
      }
      .c8 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c52 {
        margin-left: 36.8pt;
        padding-top: 0.6pt;
        text-indent: -17.8pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: left;
        margin-right: 1.2pt;
      }
      .c9 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.5pt;
      }
      .c27 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016607403755188;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c42 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.0172990560531616;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c14 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c22 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c69 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.2pt;
      }
      .c4 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c29 {
        margin-left: 36.2pt;
        padding-top: 0.6pt;
        text-indent: -17.2pt;
        padding-bottom: 0pt;
        line-height: 1.0164684057235718;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c6 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: left;
        margin-right: 1.3pt;
      }
      .c57 {
        margin-left: 0.1pt;
        padding-top: 0.6pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0165376663208008;
        text-align: left;
        margin-right: -0.3pt;
      }
      .c18 {
        margin-left: 0.1pt;
        padding-top: 0.6pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 1.0173012018203735;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c7 {
        margin-left: 0.8pt;
        padding-top: 0.6pt;
        text-indent: 0.2pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c33 {
        padding-top: 0.6pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c55 {
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0167806148529053;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c62 {
        margin-left: 0.8pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1.0162652730941772;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c70 {
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: left;
        margin-right: 1.3pt;
      }
      .c61 {
        padding-top: 0.6pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 1.01834237575531;
        text-align: left;
        margin-right: 6pt;
      }
      .c36 {
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c47 {
        padding-top: 0.6pt;
        text-indent: -0.3pt;
        padding-bottom: 0pt;
        line-height: 1.0204237699508667;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c34 {
        padding-top: 0.6pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.4pt;
      }
      .c76 {
        padding-top: 0.6pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.3pt;
      }
      .c31 {
        padding-top: 0.6pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162585973739624;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c30 {
        padding-top: 0.6pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c46 {
        padding-top: 0.4pt;
        text-indent: -0.3pt;
        padding-bottom: 0pt;
        line-height: 1.0162596702575684;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c56 {
        padding-top: 0.5pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016258955001831;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c39 {
        padding-top: 0.6pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.020425796508789;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c53 {
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c48 {
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c50 {
        padding-top: 14.3pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c37 {
        margin-left: 0.4pt;
        padding-top: 15.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c13 {
        margin-left: 0.3pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c43 {
        margin-left: 0pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c64 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: left;
        margin-right: 29.3pt;
      }
      .c67 {
        margin-left: 0.1pt;
        padding-top: 14.5pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c49 {
        margin-left: 0.3pt;
        padding-top: 15.3pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c44 {
        margin-left: 0.4pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c60 {
        margin-left: 0.8pt;
        padding-top: 14.5pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c72 {
        margin-left: 0.4pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c41 {
        margin-left: 0.1pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c15 {
        margin-left: 0.3pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c58 {
        margin-left: 70.8pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c35 {
        margin-left: 0.8pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c17 {
        margin-left: 0.8pt;
        padding-top: 15.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c59 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1.0162609815597534;
        text-align: left;
        margin-right: 46.8pt;
      }
      .c19 {
        margin-left: 0.8pt;
        padding-top: 15.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c28 {
        padding-top: 0.7pt;
        padding-bottom: 0pt;
        line-height: 1.0162599086761475;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c25 {
        margin-left: 0.8pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c10 {
        margin-left: 0.4pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c75 {
        margin-left: 0.8pt;
        padding-top: 15pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c32 {
        padding-top: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c0 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c45 {
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c23 {
        padding-top: 0.7pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c73 {
        padding-top: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c38 {
        background-color: #ffffff;
        max-width: 485.9pt;
        padding: 70.2pt 52.4pt 86.5pt 56.7pt;
      }
      .c26 {
        margin-left: 0.5pt;
      }
      .c1 {
        margin-left: 18.9pt;
      }
      .c21 {
        margin-left: 1pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
  </head>
  <body class="c38 doc-content">
    <p class="c58">
      <span class="c4"
        >&nbsp;REGOLAMENTO ART.11 D.P.R. 26 OTTOBRE 2001, N. 430
      </span>
    </p>
    <p class="c23"><span class="c4">CONCORSO A PREMI DENOMINATO </span></p>
    <p class="c73">
      <span class="c4">&ldquo;GIORNATA DELLA TERRA&rdquo; </span>
    </p>
    <p class="c15"><span class="c4">SOGGETTO PROMOTORE </span></p>
    <p class="c21 c34">
      <span class="c5"
        >AWorld Srl Societ&agrave; Benefit con sede in Corso Regina Margherita -
        10124 Torino (TO), C.F. e P.IVA &nbsp;11867110014 (nel seguito
        &ldquo;Promotore&rdquo;).
      </span>
    </p>
    <p class="c13"><span class="c4">SOGGETTO DELEGATO </span></p>
    <p class="c26 c76">
      <span class="c5"
        >Clipper Srl, con sede in Viale Caterina da Forl&igrave; 32 &ndash;
        20146 &ndash; Milano &ndash; P. IVA 06601410159 (nel &nbsp;seguito
        &ldquo;Delegato&rdquo;).
      </span>
    </p>
    <p class="c43"><span class="c4">TIPOLOGIA E DENOMINAZIONE </span></p>
    <p class="c0 c26">
      <span class="c5"
        >Concorso a premi denominato &ldquo;Giornata della Terra&rdquo; (nel
        seguito &ldquo;Concorso&rdquo;).
      </span>
    </p>
    <p class="c67"><span class="c4">AMBITO TERRITORIALE </span></p>
    <p class="c0 c21">
      <span class="c5"
        >Il Concorso ha svolgimento sull&rsquo;intero territorio nazionale
        italiano. &nbsp;</span
      >
    </p>
    <p class="c49"><span class="c4">SCOPO DEL CONCORSO </span></p>
    <p class="c26 c39">
      <span class="c5"
        >Il Concorso viene indetto con lo scopo di incentivare il download, la
        conoscenza e l&rsquo;utilizzo &nbsp;dell&rsquo;App AWorld di
        propriet&agrave; del promotore, nonch&eacute; il completamento delle
        missioni educative &nbsp;previste (nel seguito &ldquo;App&rdquo;).
      </span>
    </p>
    <p class="c35"><span class="c4">DURATA </span></p>
    <p class="c7">
      <span class="c5"
        >La partecipazione al Concorso sar&agrave; ammessa dal giorno 30 marzo
        2024 al giorno 20 aprile 2024 &nbsp;(nel seguito &ldquo;Durata&rdquo;).
      </span>
    </p>
    <p class="c21 c45">
      <span class="c5"
        >L&rsquo;estrazione dei vincitori sar&agrave; svolta al termine del
        Concorso, entro il 3 maggio 2024.
      </span>
    </p>
    <p class="c60"><span class="c4">DESTINATARI </span></p>
    <p class="c26 c30">
      <span class="c5"
        >Il Concorso &egrave; riservato a tutti i soggetti persone fisiche,
        maggiorenni al momento della &nbsp;partecipazione, dotati di uno
        smartphone con sistema operativo &ldquo;Android&rdquo; e
        &ldquo;iOS&rdquo; che, nel corso &nbsp;della durata, abbiano scaricato
        l&rsquo;App AWorld di propriet&agrave; del Promotore, si siano
        registrati ed &nbsp;abbiano completato le missioni di volta in volta
        presenti, ottenendo il relativo punteggio (nel &nbsp;seguito
        &ldquo;Partecipanti&rdquo;).
      </span>
    </p>
    <p class="c44">
      <span class="c5">Sono esclusi dalla partecipazione al Concorso: </span>
    </p>
    <p class="c0 c1">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >tutti i dipendenti ed i collaboratori del Promotore e del Delegato;
      </span>
    </p>
    <p class="c0 c1">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >tutti i soggetti che abbiano contribuito alla realizzazione del
        Concorso;
      </span>
    </p>
    <p class="c1 c32">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >tutti i soggetti che non rientrino nella specifica definizione di
        Partecipanti.
      </span>
    </p>
    <p class="c72"><span class="c4">COMUNICAZIONE </span></p>
    <p class="c21 c28">
      <span class="c5"
        >Il Concorso sar&agrave; pubblicizzato attraverso diversi canali di
        comunicazione on line ed off line che il &nbsp;Promotore riterr&agrave;
        idonei per il raggiungimento del proprio obiettivo.
      </span>
    </p>
    <p class="c26 c70">
      <span class="c5"
        >Il regolamento integrale del concorso potr&agrave; essere consultato
        dai partecipanti nell&rsquo;apposita &nbsp;sezione all&rsquo;interno
        dell&rsquo;applicazione
      </span>
    </p>
    <p class="c41"><span class="c4">AVVERTENZE </span></p>
    <p class="c0">
      <span class="c3"
        >Si precisa che i dati dei Partecipanti saranno salvati su un server
        ubicato in Italia.</span
      >
    </p>
    <p class="c25">
      <span class="c4">MODALITA&rsquo; DI PARTECIPAZIONE </span>
    </p>
    <p class="c26 c47">
      <span class="c5"
        >Al fine di incentivare le interazioni con l&rsquo;App, il Promotore
        organizza il Concorso che avr&agrave; &nbsp;svolgimento come di seguito
        indicato e che premier&agrave;, attraverso apposita estrazione a sorte,
        n.3 &nbsp;Partecipanti.
      </span>
    </p>
    <p class="c16">
      <span class="c5"
        >In particolare, per prendere parte al Concorso, i Partecipanti, nel
        corso della Durata, dovranno &nbsp;preliminarmente effettuare il
        download dell&rsquo;App sul proprio smartphone o device mobile e
        &nbsp;completare altres&igrave; la procedura di registrazione,
        compilando tutti i dati richiesti. Il medesimo Partecipante,
        riconoscibile dai dati utilizzati, potr&agrave; completare la
        registrazione una &nbsp;sola volta: qualora si verificassero
        registrazioni multiple da parte del medesimo Partecipante, &nbsp;saranno
        automaticamente cancellate quelle effettuate successivamente alla prima.
        Tutti i Partecipanti che risultassero gi&agrave; registrati in data
        antecedente rispetto all&rsquo;inizio del &nbsp;Concorso non saranno
        tenuti a ripetere le procedure di registrazione e potranno accedere con
        le &nbsp;credenziali in loro possesso. &nbsp;</span
      >
    </p>
    <p class="c69">
      <span class="c5"
        >Una volta completata la registrazione, ciascun Partecipante
        potr&agrave; scoprire le missioni individuali &nbsp;previste e che
        saranno suddivise in:
      </span>
    </p>
    <p class="c66">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >missioni giornaliere: ogni giorno, per l&rsquo;intera Durata del
        Concorso, sar&agrave; proposta la &nbsp;missione giornaliera il cui
        completamento consentir&agrave; al singolo Partecipante di ottenere dei
        punti visibili graficamente sulla missione stessa. Il medesimo
        Partecipante potr&agrave; &nbsp;decidere se prendere parte a tutte le
        missioni giornaliere o solamente ad alcune, non &nbsp;essendo
        obbligatorio completarle integralmente. Si tratter&agrave; di missioni
        educative sul tema &nbsp;della sostenibilit&agrave;, attraverso la
        pubblicazione di contenuti formativi e richiesta di azioni, &nbsp;che i
        Partecipanti dovranno leggere e registrare per completare la missione
        stessa ed &nbsp;ottenere il relativo punteggio
      </span>
    </p>
    <p class="c68">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >missioni settimanali: ogni settimana, per l&rsquo;intera Durata del
        Concorso, sar&agrave; proposta la &nbsp;missione settimanale il cui
        completamento consentir&agrave; al singolo Partecipante di ottenere
        &nbsp;dei punti visibili graficamente sulla missione stessa. Il medesimo
        Partecipante potr&agrave; &nbsp;decidere se prendere parte a tutte le
        missioni settimanali o solamente ad alcune, non &nbsp;essendo
        obbligatorio completarle integralmente. Anche in questo caso si
        tratter&agrave; di &nbsp;missioni educative sul tema della
        sostenibilit&agrave; attraverso la pubblicazione di contenuti
        &nbsp;educativi e richiesta di azioni da registrare. Il completamento di
        ciascuna missione &nbsp;settimanale consentir&agrave; al Partecipante di
        ottenere il relativo punteggio
      </span>
    </p>
    <p class="c29">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >missioni speciali: nel corso della Durata, saranno altres&igrave;
        proposte le missioni speciali, il cui &nbsp;contenuto e la cui frequenza
        sar&agrave; dettagliatamente descritta in App. Anche per queste
        &nbsp;missioni, il relativo completamento consentir&agrave; al singolo
        Partecipante di ottenere dei punti &nbsp;visibili graficamente sulla
        missione stessa. Le missioni speciali, analogamente alle missioni
        &nbsp;giornaliere/settimanali, potranno essere completate o meno a
        scelta del Partecipante ed il &nbsp;mancato completamento delle stesse
        non impedir&agrave; comunque la partecipazione
        &nbsp;all&rsquo;estrazione (a condizione che il Partecipante abbia
        comunque ottenuto almeno n. 1 &nbsp;punto nel corso dell&rsquo;intera
        Durata). Le missioni speciali saranno strutturate in maniera
        &nbsp;analoga alle missioni giornaliere/settimanali: anche in tal caso,
        il Partecipante sar&agrave; invitato &nbsp;a leggere contenuti educativi
        in tema di sostenibilit&agrave;, e registrare azioni che dimostrino il
        &nbsp;comportamento virtuoso del Partecipante.
      </span>
    </p>
    <p class="c65">
      <span class="c5"
        >Il semplice completamento della missione permetter&agrave; al
        Partecipante di ottenere il relativo &nbsp;punteggio.
      </span>
    </p>
    <p class="c26 c56">
      <span class="c5"
        >Per ciascuna missione completata (giornaliera/settimanale/speciale), il
        punteggio di ciascun &nbsp;Partecipante si aggiorner&agrave; e
        sar&agrave; consultabile nell&rsquo;area riservata dell&rsquo;App AWorld
        nella sezione &nbsp;dedicata (classifica)-
      </span>
    </p>
    <p class="c26 c48">
      <span class="c5"
        >Il software che gestisce l&rsquo;App sar&agrave; in grado di registrare
        per ciascun partecipante le azioni &nbsp;sostenibili richieste ed il
        relativo punteggio.
      </span>
    </p>
    <p class="c26 c46">
      <span class="c5"
        >Al termine del Concorso, sar&agrave; predisposto il tabulato generale
        di tutti i Partecipati da utilizzare per &nbsp;l&rsquo;estrazione dei
        premi: ciascun Partecipante sar&agrave; inserito all&rsquo;interno del
        tabulato tante volte quanti &nbsp;saranno i punti progressivamente
        cumulati nel corso dell&rsquo;intera Durata, avendo pertanto maggiori
        &nbsp;probabilit&agrave; di essere estratto quanti pi&ugrave; punti
        siano stati conseguiti.
      </span>
    </p>
    <p class="c35"><span class="c4">ESTRAZIONE </span></p>
    <p class="c63">
      <span class="c5"
        >Al termine del Concorso ed entro il 3 maggio 2024 sar&agrave; svolta
        l&rsquo;estrazione utilizzando il relativo &nbsp;file, riportante tutti
        gli aventi diritto, eventualmente ripetuti in funzione di quanto
        descritto. Dal file saranno estratti n. 3 vincitori e n. 10 riserve che
        subentreranno in caso di necessit&agrave; &nbsp;nell&rsquo;esatto ordine
        di estrazione.
      </span>
    </p>
    <p class="c26 c31">
      <span class="c5"
        >Il verbale di estrazione sar&agrave; predisposto a Milano, presso la
        sede del Delegato o altra sede da &nbsp;concordare, alla presenza di un
        Pubblico Ufficiale (Notaio o Funzionario della Camera di
        &nbsp;Commercio) competente per territorio.
      </span>
    </p>
    <p class="c9">
      <span class="c5"
        >Il medesimo Partecipante potr&agrave; aggiudicarsi al massimo un unico
        premio per l&rsquo;intero Concorso &nbsp;anche qualora avesse preso
        parte all&rsquo;estrazione pi&ugrave; volte.
      </span>
    </p>
    <p class="c10"><span class="c4">COMUNICAZIONE DI VINCITA </span></p>
    <p class="c57">
      <span class="c5"
        >Tutti i vincitori saranno personalmente avvisati mediante comunicazione
        e-mail inviata all&rsquo;indirizzo &nbsp;indicato in sede di adesione al
        concorso: i Partecipanti sono, quindi, invitati a controllare la propria
        &nbsp;casella di posta elettronica e la corrispondenza in arrivo, anche
        nella casella degli spam. Alla comunicazione di vincita sar&agrave;
        allegato il modulo di accettazione che il vincitore dovr&agrave;
        &nbsp;compilare con tutti i dati richiesti e restituire entro 7 giorni
        di calendario dalla data di ricezione &nbsp;della comunicazione di
        vincita e con le modalit&agrave; che saranno dettagliate. &nbsp;</span
      >
    </p>
    <p class="c40">
      <span class="c5"
        >La mancata risposta del vincitore entro tale termine determiner&agrave;
        l&rsquo;impossibilit&agrave; di assegnare il &nbsp;premio ed il subentro
        della riserve in ordine di graduatoria.
      </span>
    </p>
    <p class="c21 c59">
      <span class="c5"
        >Per le riserve saranno adottate le medesime tempistiche e
        modalit&agrave; di avviso della vincita. Il Promotore non assume alcuna
        responsabilit&agrave; qualora:
      </span>
    </p>
    <p class="c45 c1">
      <span class="c2">&#9679; </span
      ><span class="c5">la mailbox risulti piena </span>
    </p>
    <p class="c0 c1">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >l&rsquo;email indicata in fase di registrazione fosse errata o
        incompleta
      </span>
    </p>
    <p class="c1 c64">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >non vi fosse risposta dall&rsquo;host computer dopo l&rsquo;invio
        dell&rsquo;e-mail di notifica della vincita </span
      ><span class="c2">&#9679; </span
      ><span class="c5">la mailbox risulti disabilitata; </span>
    </p>
    <p class="c45 c1">
      <span class="c2">&#9679; </span
      ><span class="c5"
        >l&rsquo;email indicata in fase di registrazione fosse inserita in una
        black-list.
      </span>
    </p>
    <p class="c26 c61">
      <span class="c5"
        >A seguito dell&rsquo;accettazione, i vincitori riceveranno tutte le
        informazioni necessarie per poter fruire &nbsp;del premio.
      </span>
    </p>
    <p class="c17"><span class="c4">PREMI </span></p>
    <p class="c52">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >1&deg; estratto: n 1 e-Cargo bike C1 Cargo Light Nilox del valore
        indicativo di &euro; 1.700,00 iva &nbsp;inclusa (&euro; 1.393,44 + iva)
      </span>
    </p>
    <p class="c20">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >2&deg; estratto: n. 1 e- bike J5 Plus Nilox del valore indicativo di
        &euro; 1.000,00 iva inclusa (&euro; &nbsp;819,67 + iva)</span
      >
    </p>
    <p class="c74">
      <span class="c24">&#11162; </span
      ><span class="c5"
        >3&deg; estratto: n. 1 coppia di biglietti per assistere alla finale del
        torneo di tennis ATP di Roma, &nbsp;del valore indicativo di &euro;
        850,00 + IVA (a coppia). Il premio include esclusivamente i biglietti
        &nbsp;per assistere all&rsquo;evento, con esclusione delle spese di
        trasferimento, vitto ed alloggio che &nbsp;saranno a carico del
        vincitore.
      </span>
    </p>
    <p class="c75"><span class="c4">MONTEPREMI E CAUZIONE </span></p>
    <p class="c0 c21">
      <span class="c5"
        >Il montepremi complessivo ammonta a &euro; 3.063,11 + IVA. &nbsp;</span
      >
    </p>
    <p class="c26 c33">
      <span class="c5"
        >Ai sensi dell&rsquo;art. 7 del D.P.R. n. 430 del 26 ottobre 2001,
        &egrave; stata versata una cauzione a copertura &nbsp;del 100% del
        valore complessivo del montepremi a favore del Ministero delle Imprese e
        del Made &nbsp;in Italy.
      </span>
    </p>
    <p class="c37"><span class="c4">ONLUS BENEFICIARIA </span></p>
    <p class="c18">
      <span class="c5"
        >I premi non assegnati o non richiesti, diversamente da quelli
        rifiutati, saranno devoluti ai sensi di &nbsp;legge alla seguente Onlus:
        Fondazione Paideia Ente Filantropico con sede in Via Moncalvo 1, 10131
        &nbsp;Torino - C.F. 97552690014.
      </span>
    </p>
    <p class="c19"><span class="c4">RINUNCIA ALLA RIVALSA </span></p>
    <p class="c8">
      <span class="c5"
        >Il Promotore dichiara di rinunciare ad esercitare la facolt&agrave; di
        rivalsa a favore dei vincitori, ex art. 30 &nbsp;del D.P.R. n. 600 del
        29/09/1973.
      </span>
    </p>
    <p class="c41"><span class="c4">ADEMPIMENTI E GARANZIE </span></p>
    <p class="c11">
      <span class="c5"
        >La partecipazione al Concorso implica l&rsquo;accettazione integrale
        del presente regolamento e delle &nbsp;relative condizioni da parte dei
        Partecipanti.
      </span>
    </p>
    <p class="c26 c36">
      <span class="c5"
        >Eventuali modifiche che dovessero essere apportate al regolamento nel
        corso della Durata &nbsp;saranno preventivamente comunicate ai
        Partecipanti con le medesime modalit&agrave; di comunicazione
        &nbsp;riservate al presente regolamento, nel rispetto dei diritti
        acquisiti.
      </span>
    </p>
    <p class="c27">
      <span class="c5"
        >I premi in palio non potranno essere commutati in denaro o in gettoni
        d&rsquo;oro. In caso di impossibilit&agrave; sopravvenuta, il Promotore
        si riserva di eventualmente sostituire i premi &nbsp;previsti con beni
        differenti, possibilmente della stessa natura e di valore uguale o
        superiore. I premi saranno resi disponibili entro 180 giorni dalla data
        della verbalizzazione e, comunque, in &nbsp;tempo utile per poterne
        fruire.
      </span>
    </p>
    <p class="c14">
      <span class="c5"
        >Il Promotore non esercita l&rsquo;attivit&agrave; di Internet provider
        e non trae alcun beneficio dalla &nbsp;connessione alla rete Internet.
      </span>
    </p>
    <p class="c22">
      <span class="c5"
        >La partecipazione attraverso l&rsquo;App risulter&agrave; gratuita
        senza costi aggiuntivi differenti rispetto a &nbsp;quelli del
        collegamento alla rete Internet che il singolo partecipante ha
        concordato con il proprio &nbsp;provider.
      </span>
    </p>
    <p class="c26 c55">
      <span class="c5"
        >Il Promotore non si assume alcuna responsabilit&agrave; per eventi ad
        esso non imputabili, quali a titolo &nbsp;esemplificativo e non
        esaustivo: l&rsquo;accesso, l&rsquo;impedimento, la disfunzione o
        difficolt&agrave; riguardanti gli &nbsp;strumenti tecnici, il computer,
        i cavi, l&rsquo;elettronica, il software e l&rsquo;hardware, la
        trasmissione e la &nbsp;connessione, la linea telefonica che possano
        impedire all&rsquo;utente di partecipare alla presente &nbsp;iniziativa.
      </span>
    </p>
    <p class="c26 c53">
      <span class="c5"
        >Il Promotore non si assume alcuna responsabilit&agrave; per eventuali
        contaminazioni di virus informatici &nbsp;o per intrusioni di terzi al
        device dei Partecipanti.
      </span>
    </p>
    <p class="c54">
      <span class="c5"
        >I Partecipanti che, secondo il giudizio insindacabile del Promotore o
        di terze parti incaricate dallo &nbsp;stesso alla gestione del Concorso,
        abbiano utilizzato strumenti giudicati in maniera sospetta,
        &nbsp;fraudolenta, o in violazione del normale svolgimento del Concorso
        stesso, saranno esclusi dalla &nbsp;partecipazione e non sar&agrave;
        considerato valido il punteggio maturato in tale modo.</span
      >
    </p>
    <p class="c51">
      <span class="c5"
        >Tutti i tentativi di uso fraudolento e di forzatura esterna
        dell&rsquo;App potrebbero integrare illeciti &nbsp;perseguibili
        penalmente.
      </span>
    </p>
    <p class="c26 c50">
      <span class="c5"
        >Il Promotore si riserva di effettuare tutte le verifiche relative alla
        corretta partecipazione al &nbsp;Concorso.
      </span>
    </p>
    <p class="c71">
      <span class="c5"
        >In caso di accertata irregolarit&agrave; della partecipazione, il
        premio, qualora fosse stato richiesto, non &nbsp;potr&agrave; essere
        riconosciuto.
      </span>
    </p>
    <p class="c6">
      <span class="c5"
        >Inoltre, qualora fossero riscontrate anomalie circa i punteggi
        acquisiti, il Promotore effettuer&agrave; &nbsp;tutte le verifiche
        necessarie, eventualmente correggendo l&rsquo;anomalia prima di svolgere
        l&rsquo;estrazione. Il Promotore non pu&ograve; in nessun caso essere
        ritenuto responsabile di eventuali problematiche sorte &nbsp;durante
        l&rsquo;utilizzo dei premi in palio e non pu&ograve; essere ritenuto
        responsabile dell&rsquo;uso improprio dei &nbsp;premi da parte dei
        vincitori.
      </span>
    </p>
    <p class="c42">
      <span class="c5"
        >Inoltre, con la partecipazione al Concorso, i Partecipanti manlevano e
        dichiarano il Promotore non &nbsp;responsabile di eventuali
        problematiche o danni e/o infortuni, a cose e/o persone sorti durante la
        &nbsp;fruizione dei premi.
      </span>
    </p>
    <p class="c62">
      <span class="c4"
        >INFORMATIVA SUL TRATTAMENTO DEI DATI AI SENSI del CODICE IN MATERIA DI
        PROTEZIONE &nbsp;DEI DATI PERSONALI E DEL REGOLAMENTO EU N 2016/679
      </span>
    </p>
    <p class="c12">
      <span class="c5"
        >Il Promotore, in qualit&agrave; di titolare autonomo del trattamento,
        si impegna in ordine alle &nbsp;informazioni ed ai dati che
        acquisir&agrave; durante tutto il corso del Concorso al rispetto delle
        norme e &nbsp;degli obblighi imposti dalla vigente normativa in materia
        di protezione dei dati personali &nbsp;(Regolamento Ue 2016/679, D.lgs.
        196/2003 e successive modifiche ed integrazioni).
      </span>
    </p>
  </body>
</html>
`
