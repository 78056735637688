import { ButtonBase, Stack } from "@mui/material"
import { currency, defaultBoxShadow } from "../../../services/config/constants"
import Product from "../../../models/product"
import PointsBadge from "../../global/common/PointsBadge"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { colors } from "../../../services/config/colors"
import { scrollWindowToTop } from "../../../services/utils/utils"
import { EventStatus } from "../../../services/config/enum"

const ProductCard = ({
  product,
  customOnClick,
}: {
  product: Product
  customOnClick?: () => void
}) => {
  const navigate = useNavigate()
  const { isMobile, eventStatus } = useContext(MainContext)

  return (
    <ButtonBase
      aria-label={t("go_to_prize", { title: product.document.title })}
      style={{ width: "100%", borderRadius: isMobile ? 10 : 15 }}
      onClick={
        customOnClick
          ? customOnClick
          : () => {
              scrollWindowToTop()
              navigate(`/store/product/${product.id}`)
            }
      }
    >
      <Stack
        style={{
          width: "100%",
          height: isMobile ? 257 : 350,
          boxShadow: defaultBoxShadow,
          borderRadius: isMobile ? 10 : 15,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <PointsBadge
          points={
            product.prices.find((price) => price.currency === currency)!.amount
          }
          style={{
            position: "absolute",
            top: isMobile ? 14 : 20,
            right: isMobile ? 14 : 20,
          }}
        />
        {!product.boughtCount ? (
          <div
            className="center"
            style={{
              width: "auto",
              height: isMobile ? 24 : 30,
              backgroundColor: "#EB5F11",
              paddingInline: isMobile ? 12 : 16,
              borderRadius: 18,
              position: "absolute",
              top: isMobile ? 85 : 130,
              left: isMobile ? 14 : 20,
            }}
          >
            {product.id === "2608e769-933b-4440-9d8c-fd761703e43b" &&
            (eventStatus === EventStatus.LOCKED ||
              eventStatus === EventStatus.HIGHLIGHTED) ? (
              <Text
                fontSize={isMobile ? 11 : 16}
                fontWeight={500}
                color={colors.textWhite}
              >
                disponibile da sabato
              </Text>
            ) : !product.inventoryCount ? (
              <Text
                fontSize={isMobile ? 11 : 16}
                fontWeight={500}
                color={colors.textWhite}
              >
                {t("not_available")}
              </Text>
            ) : (
              <Text
                fontSize={isMobile ? 11 : 16}
                fontWeight={500}
                color={colors.textWhite}
              >
                {product.inventoryCount ?? 0}{" "}
                {product.inventoryCount === 1
                  ? t("available_singular")
                  : t("available_plural")}
              </Text>
            )}
          </div>
        ) : null}
        <img
          src={product.image}
          style={{
            width: "100%",
            height: isMobile ? 123 : 180,
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
        />
        <Title
          fontSize={isMobile ? 16 : 20}
          lineHeight={isMobile ? "22px" : "28px"}
          paddingLeft={isMobile ? 14 : 20}
          paddingRight={isMobile ? 14 : 20}
          style={{
            marginTop: isMobile ? 9 : 13,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {product.document.title}
        </Title>
        <Text
          fontSize={isMobile ? 14 : 18}
          lineHeight={isMobile ? "20px" : "25px"}
          fontWeight={400}
          paddingLeft={isMobile ? 14 : 20}
          paddingRight={isMobile ? 14 : 20}
          style={{ marginTop: isMobile ? 7 : 10 }}
        >
          By {product.sponsoredBy}
        </Text>
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 30,
            paddingInline: isMobile ? 14 : 20,
            marginTop: isMobile ? 11 : 18,
            position: "relative",
          }}
        >
          {(product.available || product.boughtCount) &&
          (product.id !== "2608e769-933b-4440-9d8c-fd761703e43b" ||
            (eventStatus !== EventStatus.LOCKED &&
              eventStatus !== EventStatus.HIGHLIGHTED)) ? (
            <div
              className="center"
              style={{
                width: "auto",
                height: "100%",
                paddingInline: isMobile ? 12 : 16,
                backgroundColor:
                  product.boughtCount > 0 ? "#A6AFC2" : colors.primary,
                borderRadius: isMobile ? 8 : 10,
              }}
            >
              <Text
                fontSize={isMobile ? 14 : 16}
                fontWeight={500}
                color={colors.textWhite}
              >
                {product.boughtCount > 0 ? t("retrieved") : t("retrieve")}
              </Text>
            </div>
          ) : null}
          <Title
            fontSize={isMobile ? 12 : 16}
            fontWeight={isMobile ? 600 : 700}
            color={colors.primary}
            style={{
              position: "absolute",
              right: isMobile ? 14 : 20,
              textDecoration: "underline",
            }}
          >
            {t("see")}
          </Title>
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default ProductCard
