import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { MainContext } from "./main"
import { logger } from "../services/utils/utils"
import Product from "../models/product"
import { useLazyQuery, useMutation } from "@apollo/client"
import { ordersList, productsList } from "../services/graphql/queries"
import {
  orderConfirmEmailResend,
  orderCreate,
} from "../services/graphql/mutations"
import { currency } from "../services/config/constants"
import Order from "../models/order"

interface MarketplaceContextInterface {
  productsLoading: boolean
  ordersLoading: boolean
  productsError: boolean
  ordersError: boolean
  products: Product[]
  orders: Order[]
  getProducts: () => void
  createOrder: (productId: string, price: number) => Promise<true | string>
  getOrders: () => Promise<boolean>
  resendEmail: (input: {
    orderId: string
    productId: string
    itemId: string
  }) => Promise<boolean>
}

const MarketplaceContext = createContext<MarketplaceContextInterface>({
  productsLoading: true,
  ordersLoading: true,
  productsError: false,
  ordersError: false,
  products: [],
  orders: [],
  getProducts: () => {},
  createOrder: async () => true,
  getOrders: async () => true,
  resendEmail: async () => true,
})

const MarketplaceController = ({ children }: { children: ReactNode }) => {
  const { lang, user, setUser } = useContext(MainContext)

  // loadings
  const [productsLoading, setProductsLoading] = useState<boolean>(true)
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false)

  // errors
  const [productsError, setProductsError] = useState<boolean>(false)
  const [ordersError, setOrdersError] = useState<boolean>(false)

  // states
  const [products, setProducts] = useState<Product[]>([]) // all products list
  const [orders, setOrders] = useState<Order[]>([]) // all orders list

  // queries
  const [productsListQuery] = useLazyQuery(productsList)
  const [ordersListQuery] = useLazyQuery(ordersList)

  // mutations
  const [orderCreateMutation] = useMutation(orderCreate)
  const [orderConfirmEmailResendMutation] = useMutation(orderConfirmEmailResend)

  // get products list
  const getProducts = async () => {
    setProductsError(false)

    try {
      const { data } = await productsListQuery({
        variables: {
          input: {
            lang: lang,
            limit: 20,
            projectId: process.env.REACT_APP_PROJECT_ID,
          },
        },
        fetchPolicy: "no-cache",
      })

      logger("products list", data.productsList.items)

      setProducts(data.productsList.items)
      setProductsLoading(false)
    } catch (e) {
      logger("products list error", e)
      setProductsLoading(false)
      setProductsError(true)
    }
  }

  // get orders list
  const getOrders = async () => {
    setOrdersError(false)

    try {
      const { data } = await ordersListQuery({
        variables: {
          input: {
            lang: lang,
            limit: 50,
            projectId: process.env.REACT_APP_PROJECT_ID,
          },
        },
        fetchPolicy: "no-cache",
      })

      logger("orders list", data.ordersList.items)

      setOrders(data.ordersList.items)
      setOrdersLoading(false)

      return true
    } catch (e) {
      logger("orders list error", e)
      setOrdersLoading(false)
      setOrdersError(true)

      return false
    }
  }

  // create order
  const createOrder = async (productId: string, price: number) => {
    try {
      await orderCreateMutation({
        variables: {
          input: {
            currency: currency,
            products: [
              {
                amount: 1,
                productId: productId,
              },
            ],
            projectId: process.env.REACT_APP_PROJECT_ID,
          },
        },
      })

      // update products list locally
      const productToEdit = products.find(
        (product) => product.id === productId
      )!
      productToEdit.boughtCount += 1
      productToEdit.canBuy = false
      if (productToEdit.inventoryCount) {
        productToEdit.inventoryCount -= 1
      }

      setProducts([...products])

      // update products from server
      setTimeout(() => {
        getProducts()
      }, 8000)

      // update user points locally
      if (user) {
        user.points -= price
        setUser({ ...user })
      }

      // update orders from server
      await getOrders()

      return true
    } catch (e) {
      logger("order create error", e)

      // get products list to show user new updates
      getProducts()

      if (
        e instanceof Error &&
        (e.message ===
          "Insufficient product quantity available for the requested amount" ||
          e.message === "Product is not available")
      ) {
        return "insufficient_quantity"
      }

      return "generic"
    }
  }

  // resend email
  const resendEmail = async (input: {
    orderId: string
    productId: string
    itemId: string
  }) => {
    try {
      await orderConfirmEmailResendMutation({
        variables: {
          input: input,
        },
      })

      return true
    } catch (e) {
      logger("order email resend error", e)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getProducts()
    getOrders()
  }, [])

  return (
    <MarketplaceContext.Provider
      value={{
        productsLoading,
        ordersLoading,
        productsError,
        ordersError,
        products,
        orders,
        getProducts,
        createOrder,
        getOrders,
        resendEmail,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  )
}
export { MarketplaceController, MarketplaceContext }
