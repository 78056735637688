import { ButtonBase, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useRef } from "react"
import { MainContext } from "../../controllers/main"
import { colors } from "../../services/config/colors"
import FadeFromTop from "../animations/FadeFromTop"
import Title from "../global/common/Title"
import { t } from "i18next"
import Text from "../global/common/Text"
import partnerLogoAlt from "../../assets/images/partner-logo.svg"
import Button from "../global/common/Button"
import OtpInput from "react-otp-input"
import Alert from "../global/common/Alert"
import FadeFromBottom from "../animations/FadeFromBottom"

const AmplifySignInMobile = ({
  loading,
  setLoading,
  code,
  setCode,
  errorAlertOpen,
  setErrorAlertOpen,
  callConfirmCode,
  height,
  itemsWidth,
  continueButtonWidth,
}: {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  code: string
  setCode: Dispatch<SetStateAction<string>>
  errorAlertOpen: boolean
  setErrorAlertOpen: Dispatch<SetStateAction<boolean>>
  callConfirmCode: () => void
  height?: number | string
  itemsWidth?: number
  continueButtonWidth?: number
}) => {
  const { windowHeight, isMobile, sendCode, email } = useContext(MainContext)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      id="signin-container"
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
        paddingTop: 26,
        paddingInline: isMobile ? 16 : 60,
        paddingBottom: 200,
      }}
    >
      {/* header */}
      <FadeFromTop>
        <img
          src={partnerLogoAlt}
          style={{ height: 35 }}
          alt={t("partner_logo_alt")}
        />
      </FadeFromTop>
      <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
        <Title fontSize={isMobile ? 26 : 28} textAlign="center" component="h1">
          {t("check_your_email")}
        </Title>
      </FadeFromTop>
      <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
        <Text
          fontSize={isMobile ? 18 : 20}
          textAlign="center"
          fontWeight={500}
          component="h2"
        >
          {t("insert_code", { email: email })}
        </Text>
      </FadeFromTop>
      {/* content */}
      <FadeFromTop delay={0.15} style={{ width: "100%", marginTop: 60 }}>
        <Stack alignItems="center">
          <Text fontSize={16} fontWeight={400}>
            {t("verification_code")}
          </Text>
          <div
            style={{
              width: "100%",
              marginTop: 24,
              maxWidth: itemsWidth,
            }}
          >
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={6}
              renderSeparator={<span style={{ minWidth: 16 }} />}
              renderInput={(props) => (
                <input
                  {...props}
                  disabled={loading}
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              )}
              inputType="number"
              shouldAutoFocus
              inputStyle={loading ? "otp-input-disabled" : "otp-input"}
              containerStyle={{
                width: "100%",
              }}
            />
          </div>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            style={{ marginTop: 25 }}
          >
            <Text fontSize={16} fontWeight={400}>
              {t("did_not_receive_the_code")}
            </Text>
            <ButtonBase
              aria-label={t("send_again")}
              disableRipple
              disabled={loading}
              onClick={async () => {
                setLoading(true)

                await sendCode(email)

                setLoading(false)
              }}
            >
              <Text
                fontSize={16}
                fontWeight={700}
                color={colors.primary}
                style={{ opacity: loading ? 0.4 : 1, transition: "200ms" }}
              >
                {t("send_again")}
              </Text>
            </ButtonBase>
          </Stack>
        </Stack>
      </FadeFromTop>
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Stack gap={1.5} style={{ width: "100%" }}>
          <Button
            loading={loading}
            title={t("get_ticket")}
            width="100%"
            onClick={async () => {
              callConfirmCode()
            }}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
            disabled={code.length < 6}
          >
            {t("get_ticket")}
          </Button>
        </Stack>
      </FadeFromBottom>
      {/* alerts */}
      <Alert
        open={errorAlertOpen}
        title={t("error_title")}
        description={t("incorrect_code")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setErrorAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default AmplifySignInMobile
