import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import Title from "../global/common/Title"
import { Dispatch, SetStateAction, useContext, useRef } from "react"
import AppearFromSide from "../animations/AppearFromSide"
import Button from "../global/common/Button"
import { ActionsContext } from "../../controllers/actions"
import ActionCardHorizontal from "./common/ActionCardHorizontal"
import { MainContext } from "../../controllers/main"
import Alert from "../global/common/Alert"
import { TagsContext } from "../../controllers/tags"
import CategoryFilterBadge from "./common/CategoryFilterBadge"
import Action from "../../models/action"
import { useTranslation } from "react-i18next"
import {
  accessibilityHeightThreshold,
  maximumActionsPerTime,
} from "../../services/config/constants"
import ActionLoggedFeedback from "../feedbacks/common/ActionLoggedFeedback"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"
import actMissionIconWhite from "../../assets/icons/missions/missionsTypes/act-white.svg"
import { MissionPeriod, MissionType } from "../../services/config/enum"
import PointsBadge from "../global/common/PointsBadge"

const ActionsExploreMobile = ({
  selectedCategory,
  filteredActionsList,
  noActionsAlertOpen,
  setNoActionsAlertOpen,
  hideFilters,
  logFeedbackOpen,
  missionFeedbackOpen,
}: {
  selectedCategory: string
  filteredActionsList: Action[]
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
  hideFilters: boolean
  logFeedbackOpen: boolean
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight, currentMission, updatingMissions } =
    useContext(MainContext)
  const { categories } = useContext(TagsContext)
  const {
    selectedActions,
    logActions,
    setSelectedCategory,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // debounce for log button
  const canClickLogButton = useRef<boolean>(true)

  return !currentMission ? null : (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        position: "relative",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("act_mission")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* mission */}
      <AppearFromSide fade style={{ marginTop: 14, paddingInline: 16 }}>
        <Stack direction="row" gap={0.75}>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              backgroundColor: colors.missions.act,
            }}
          >
            <img src={actMissionIconWhite} style={{ width: 19 }} alt="" />
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 8,
                marginBottom: 2,
              }}
            >
              {t("act")}
            </Title>
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 14,
                marginBottom: 2,
              }}
            >
              {currentMission.current}/{currentMission.target}
            </Title>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              border: "1px solid " + colors.tertiary,
              backgroundColor: colors.backgroundWhite,
            }}
          >
            <Title fontSize={14} color={colors.tertiary}>
              {currentMission.period === MissionPeriod.DAILY
                ? t("today")
                : currentMission.period === MissionPeriod.WEEKLY
                ? t("week")
                : t("special_singular")}
            </Title>
          </Stack>
          <PointsBadge points={currentMission.points} />
        </Stack>
      </AppearFromSide>
      <AppearFromSide
        fade
        delay={0.05}
        style={{ marginTop: 20, paddingInline: 16 }}
      >
        <Title fontSize={20}>{currentMission.title}</Title>
      </AppearFromSide>
      {/* filters row */}
      {!hideFilters && (
        <AppearFromSide
          fade
          style={{
            marginTop: 20,
            height: 32,
            overflow: "hidden",
            transition: "height 100ms, margin-top 100ms",
          }}
        >
          <Stack
            role="radiogroup"
            aria-label={t("filters")}
            direction="row"
            alignItems="center"
            style={{
              width: "100%",
              maxWidth: "100%",
              overflowX: "scroll",
              gap: 6,
              paddingInline: 16,
            }}
          >
            <CategoryFilterBadge
              category={{
                id: "catDefault",
                name: t("featured"),
                backColor: colors.primary,
                backTagColor: colors.primary,
                foreColor: colors.textWhite,
              }}
              selected={selectedCategory === "catDefault"}
              onClick={() => {
                setSelectedCategory("catDefault")
              }}
            />
            {categories.map((category, index) => (
              <CategoryFilterBadge
                key={category.id}
                category={category}
                selected={selectedCategory === category.id}
                onClick={() => {
                  setSelectedCategory(category.id)
                }}
              />
            ))}
          </Stack>
        </AppearFromSide>
      )}
      {/* actions list */}
      <Stack
        style={{
          width: "100%",
          paddingInline: 16,
          paddingTop: hideFilters ? 20 : 12,
          paddingBottom:
            windowHeight <= accessibilityHeightThreshold ? 25 : 100,
          gap: 12,
        }}
      >
        {/* number of items for accessibility */}
        <AppearFromSide fade>
          <Title
            key={selectedCategory}
            fontSize={12}
            color={colors.primary}
            ariaLive="polite"
            style={{ textDecoration: "underline" }}
          >
            {`${filteredActionsList.length} ${
              filteredActionsList.length === 1
                ? t("action").toLowerCase()
                : t("actions").toLowerCase()
            }`}
          </Title>
        </AppearFromSide>
        {filteredActionsList.map((action, index) => (
          <AppearFromSide
            key={action.id + selectedCategory}
            fade
            delay={0.025 * (index + 1)}
          >
            <ActionCardHorizontal
              width="100%"
              action={action}
              showCategoryAndCo2
            />
          </AppearFromSide>
        ))}
        {/* log button for accessibility */}
        {windowHeight <= accessibilityHeightThreshold && (
          <Button
            width="100%"
            loading={updatingMissions}
            style={{ minHeight: 56, marginTop: 13 }}
            onClick={() => {
              if (!selectedActions.length) {
                setNoActionsAlertOpen(true)
                return
              }

              if (canClickLogButton.current) {
                logActions()

                canClickLogButton.current = false
                setTimeout(() => {
                  canClickLogButton.current = true
                }, 800)
              }
            }}
            title={
              updatingMissions
                ? t("log")
                : selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })
            }
          >
            {updatingMissions
              ? t("log")
              : selectedActions.length === 0
              ? t("log")
              : selectedActions.length === 1
              ? t("log_one_action")
              : t("log_count_actions", { count: selectedActions.length })}
          </Button>
        )}
      </Stack>
      {/* log button */}
      {windowHeight > accessibilityHeightThreshold && (
        <div
          style={{
            width: "100%",
            paddingTop: 16,
            paddingBottom: 16,
            paddingInline: 16,
            position: "fixed",
            bottom: 0,
            boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: colors.backgroundWhite,
          }}
        >
          <Button
            width="100%"
            loading={updatingMissions}
            onClick={() => {
              if (!selectedActions.length) {
                setNoActionsAlertOpen(true)
                return
              }

              if (canClickLogButton.current) {
                logActions()

                canClickLogButton.current = false
                setTimeout(() => {
                  canClickLogButton.current = true
                }, 800)
              }
            }}
            title={
              updatingMissions
                ? t("log")
                : selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })
            }
          >
            {updatingMissions
              ? t("log")
              : selectedActions.length === 0
              ? t("log")
              : selectedActions.length === 1
              ? t("log_one_action")
              : t("log_count_actions", { count: selectedActions.length })}
          </Button>
        </div>
      )}
      {/* alerts and feedbacks */}
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
      <ActionLoggedFeedback open={logFeedbackOpen} plural />
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.ACT}
      />
    </Stack>
  )
}

export default ActionsExploreMobile
