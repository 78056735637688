import { ButtonBase, Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import infoIcon from "../../assets/icons/info-primary.svg"
import sortIcon from "../../assets/icons/sort.svg"
import vechainLogo from "../../assets/images/vechain-logo.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import {
  currency,
  defaultBoxShadow,
  defaultBoxShadowDarker,
  eventEnd,
} from "../../services/config/constants"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import SelectBig from "../global/common/SelectBig"
import { lotteryProducts } from "../../services/data/products"
import ProductCard from "./common/ProductCard"
import FadeFromTop from "../animations/FadeFromTop"
import AppearFromSide from "../animations/AppearFromSide"
import Appear from "../animations/Appear"
import LotteryProductCard from "./common/LotteryProductCard"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import Alert from "../global/common/Alert"
import { MarketplaceContext } from "../../controllers/marketplace"

const StoreMobile = ({
  productsOrderOption,
  setProductsOrderOption,
  storeAlertOpen,
  setStoreAlertOpen,
  lotteryProductsAlertOpen,
  setLotteryProductsAlertOpen,
}: {
  productsOrderOption: number
  setProductsOrderOption: Dispatch<SetStateAction<number>>
  storeAlertOpen: boolean
  setStoreAlertOpen: Dispatch<SetStateAction<boolean>>
  lotteryProductsAlertOpen: boolean
  setLotteryProductsAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { products } = useContext(MarketplaceContext)

  // current lottery products page
  const [currentLotteryPage, setCurrentLotteryPage] = useState<number>(0)

  // can click lottery products carousel controls
  const canClick = useRef<boolean>(true)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        paddingBottom: 36,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("marketplace")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      <ButtonBase
        disableRipple
        style={{
          width: "fit-content",
          height: 22,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 8,
        }}
        aria-label={t("how_does_store_work")}
        onClick={() => {
          setStoreAlertOpen(true)
        }}
      >
        <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
        <Text
          fontSize={14}
          lineHeight="16px"
          fontWeight={700}
          style={{ marginLeft: 5 }}
          color={colors.primary}
        >
          {t("how_does_store_work")}
        </Text>
      </ButtonBase>
      {/* points card */}
      <FadeFromTop style={{ width: "calc(100% - 32px)", marginTop: 22 }}>
        <Stack
          style={{
            width: "100%",
            height: 132,
            boxShadow: defaultBoxShadow,
            borderRadius: 10,
            padding: 16,
            position: "relative",
          }}
        >
          <img
            src={vechainLogo}
            style={{ width: 94, position: "absolute", top: 10, right: 10 }}
            alt="Vechain"
          />
          <Stack direction="row" alignItems="center" style={{ gap: 14 }}>
            <img
              src={user?.profileImage}
              style={{ width: 60, height: 60, borderRadius: "100%" }}
            />
            <Stack style={{ gap: 3 }}>
              <Text
                fontSize={12}
                fontWeight={500}
                lineHeight="14px"
                color={colors.textSecondary}
              >
                {t("points_extended").toUpperCase()}
              </Text>
              <Title fontSize={22} lineHeight="26px" color={colors.primary}>
                {user?.points}
              </Title>
            </Stack>
          </Stack>
          {/* <Text
            fontSize={12}
            lineHeight="14px"
            fontWeight={500}
            color={colors.textSecondary}
            style={{ marginTop: 11 }}
          >
            {t("to_be_used_by")}
          </Text>
          <Countdown
            date={new Date(eventEnd)}
            renderer={(data) => {
              return CountdownRenderedMobile({
                days: data.days,
                hours: data.hours,
                minutes: data.minutes,
                seconds: data.seconds,
                style: { position: "absolute", left: 16, bottom: -20 },
                color: "primary",
              })
            }}
          /> */}
          <ButtonBase
            disableRipple
            style={{ position: "absolute", right: 16, bottom: 19 }}
            aria-label={t("go_to_your_wallet")}
            onClick={() => {
              navigate("/store/wallet")
            }}
          >
            <Title
              fontSize={14}
              lineHeight="16px"
              color={colors.primary}
              style={{ textDecoration: "underline" }}
            >
              {t("go_to_your_wallet")}
            </Title>
          </ButtonBase>
        </Stack>
      </FadeFromTop>
      {/* products list */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 40,
          marginTop: 30,
          position: "relative",
          paddingInline: 16,
        }}
      >
        <AppearFromSide fade delay={0.05}>
          <Title fontSize={18} component="h2">
            {t("all_prizes")}
          </Title>
        </AppearFromSide>
        <Appear delay={0.05} fade style={{ position: "absolute", right: 17 }}>
          <SelectBig
            options={[t("descending"), t("ascending")]}
            selectedOption={productsOrderOption}
            setSelectedOption={setProductsOrderOption}
            width={180}
            height={40}
            borderRadius={8}
            startingIcon={
              <img
                src={sortIcon}
                style={{ width: 22, marginRight: 10 }}
                alt=""
              />
            }
          />
        </Appear>
      </Stack>
      <Grid
        container
        spacing="16px"
        style={{ paddingInline: 16, marginTop: 4 }}
      >
        {products
          .sort((a, b) => {
            if (productsOrderOption) {
              if (
                a.prices.find((price) => price.currency === currency)!.amount <
                b.prices.find((price) => price.currency === currency)!.amount
              ) {
                return -1
              } else if (
                a.prices.find((price) => price.currency === currency)!.amount >
                b.prices.find((price) => price.currency === currency)!.amount
              ) {
                return 1
              }
            } else {
              if (
                a.prices.find((price) => price.currency === currency)!.amount >
                b.prices.find((price) => price.currency === currency)!.amount
              ) {
                return -1
              } else if (
                a.prices.find((price) => price.currency === currency)!.amount <
                b.prices.find((price) => price.currency === currency)!.amount
              ) {
                return 1
              }
            }
            return 0
          })
          .map((product, index) => (
            <Grid key={index} item xs={6} md={4}>
              <Appear delay={0.05 * (index + 1)} fade>
                <ProductCard product={product} />
              </Appear>
            </Grid>
          ))}
      </Grid>
      {/* lottery products */}
      <Stack style={{ width: "100%", paddingInline: 16, marginTop: 30 }}>
        <AppearFromSide fade delay={0.15} style={{ width: "100%" }}>
          <Title fontSize={18} component="h2">
            {t("lottery_prizes")}
          </Title>
        </AppearFromSide>
        <ButtonBase
          disableRipple
          style={{
            width: "fit-content",
            height: 22,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 22,
            alignSelf: "center",
          }}
          aria-label={t("how_does_lottery_prizes_work")}
          onClick={() => {
            setLotteryProductsAlertOpen(true)
          }}
        >
          <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
          <Text
            fontSize={14}
            lineHeight="16px"
            fontWeight={700}
            style={{ marginLeft: 5 }}
            color={colors.primary}
          >
            {t("how_does_lottery_prizes_work")}
          </Text>
        </ButtonBase>
      </Stack>
      <Appear fade delay={0.2} style={{ width: "100%", marginTop: 20 }}>
        <Stack
          id="lottery-products-container"
          direction="row"
          alignItems="center"
          gap={4}
          style={{
            width: "100%",
            maxWidth: "100%",
            height: 250,
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            paddingInline: 16,
            paddingBottom: 20,
          }}
          onScroll={() => {
            const container = document.getElementById(
              "lottery-products-container"
            )!
            setCurrentLotteryPage(
              Math.round(container.scrollLeft / window.innerWidth)
            )
          }}
        >
          {lotteryProducts.map((product, index) => (
            <LotteryProductCard
              key={index}
              index={index}
              currentLotteryPage={currentLotteryPage}
              product={product}
            />
          ))}
        </Stack>
      </Appear>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: "100%", height: 36, gap: 6 }}
      >
        <ButtonBase
          style={{
            width: 36,
            height: 36,
            borderRadius: "100%",
            boxShadow: defaultBoxShadowDarker,
            marginRight: 12,
          }}
          aria-label={t("previous_prize")}
          aria-disabled={currentLotteryPage === 0 ? "true" : "false"}
          onClick={() => {
            if (canClick.current) {
              canClick.current = false

              const container = document.getElementById(
                "lottery-products-container"
              )!
              container.scrollTo({
                left: window.innerWidth * (currentLotteryPage - 1),
                behavior: "smooth",
              })

              setTimeout(() => {
                canClick.current = true
              }, 400)
            }
          }}
        >
          <img src={chevronLeftCircularIcon} style={{ width: "100%" }} alt="" />
        </ButtonBase>
        {lotteryProducts.map((product, index) => (
          <div
            className="center"
            key={index}
            style={{
              width: currentLotteryPage === index ? 15 : 10,
              height: currentLotteryPage === index ? 12 : 8,
              borderRadius: 3.5,
              backgroundColor:
                currentLotteryPage === index
                  ? colors.primary
                  : colors.background,
              transition: "150ms",
            }}
          >
            <Title
              fontSize={12}
              lineHeight="12px"
              color={colors.textWhite}
              style={{
                opacity: currentLotteryPage === index ? 1 : 0,
                transition: "150ms",
              }}
            >
              {index + 1}
            </Title>
          </div>
        ))}
        <ButtonBase
          style={{
            width: 36,
            height: 36,
            borderRadius: "100%",
            boxShadow: defaultBoxShadowDarker,
            marginLeft: 12,
          }}
          aria-label={t("next_prize")}
          aria-disabled={
            currentLotteryPage === lotteryProducts.length - 1 ? "true" : "false"
          }
          onClick={() => {
            if (canClick.current) {
              canClick.current = false

              const container = document.getElementById(
                "lottery-products-container"
              )!
              container.scrollTo({
                left: window.innerWidth * (currentLotteryPage + 1),
                behavior: "smooth",
              })

              setTimeout(() => {
                canClick.current = true
              }, 400)
            }
          }}
        >
          <img
            src={chevronRightCircularIcon}
            style={{ width: "100%" }}
            alt=""
          />
        </ButtonBase>
      </Stack>
      {/* alerts */}
      <Alert
        open={storeAlertOpen}
        title={t("how_does_store_work")}
        description={t("how_does_store_work_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setStoreAlertOpen(false)
        }}
      />
      <Alert
        open={lotteryProductsAlertOpen}
        title={t("how_do_lottery_prizes_work")}
        description={t("how_do_lottery_prizes_work_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setLotteryProductsAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default StoreMobile
