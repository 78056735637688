import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import awLogoCentered from "../../assets/images/aw-logo-centered.svg"
import csLogoBlack from "../../assets/images/cs-logo-black.png"
import partnerLogoAlt from "../../assets/images/partner-logo.svg"
import { useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import introImage from "../../assets/images/onboarding/intro.png"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import Alert from "../global/common/Alert"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import AlertFullScreen from "../global/common/AlertFullScreen"
import {
  awPrivacyPolicyIT,
  gdtPrivacyPolicyIT,
} from "../../services/data/privacyPolicy"
import {
  focusElement,
  getAge,
  isEmailValid,
  scrollWindowToTop,
} from "../../services/utils/utils"
import {
  defaultBoxShadow,
  onboardingElementsMaxWidth,
} from "../../services/config/constants"
import Input from "../global/common/Input"
import SelectBig from "../global/common/SelectBig"
import ConsentCard from "./common/ConsentCard"
import Switch from "../global/common/Switch"
import SelectBigComplex from "../global/common/SelectBigComplex"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import AlertInfo from "../global/common/AlertInfo"
import { termsAndConditionsIT } from "../../services/data/termsAndConditions"
import { contestRulesIT } from "../../services/data/contestRules"
import MetaPixelLead from "../global/common/MetaPixelLead"

const OnboardingMobile = ({
  height,
  continueButtonWidth,
}: {
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const {
    windowHeight,
    signIn,
    isMobile,
    authType,
    setViewOnboarding,
    sendCode,
    consents,
    setConsents,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    emailError,
    setEmailError,
    birthDate,
    setBirthDate,
    birthDateError,
    setBirthDateError,
    eventSlots,
    timeSlotOption,
    setTimeSlotOption,
    minorTicketsOption,
    setMinorTicketsOption,
  } = useContext(MainContext)

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // loading for signup
  const [loading, setLoading] = useState<boolean>(false)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  // terms and privacy alerts
  const [termsAlertOpen, setTermsAlertOpen] = useState<boolean>(false)
  const [gtdPrivacyAlertOpen, setGdtPrivacyAlertOpen] = useState<boolean>(false)
  const [awPrivacyAlertOpen, setAwPrivacyAlertOpen] = useState<boolean>(false)
  const [contestAlertOpen, setContestAlertOpen] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // alerts
  const [timeSlotsAlertOpen, setTimeSlotsAlertOpen] = useState<boolean>(false)
  const [minorAlertOpen, setMinorAlertOpen] = useState<boolean>(false)

  // policies see more
  const [seeMore, setSeeMore] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ])

  // if user should signin or get a ticket
  const [shouldSignIn, setShouldSignIn] = useState<boolean>(false)

  // not signed in alert
  const [signInErrorAlertOpen, setSignInErrorAlertOpen] =
    useState<boolean>(false)

  const continueButton = (
    <FadeFromBottom
      style={{
        width: continueButtonWidth ?? "calc(100% - 32px)",
        position: "absolute",
        bottom: currentPage === 0 && localStorage.getItem("fromApp") ? 22 : 86,
        display: "flex",
        justifyContent: "center",
        transition: "bottom 200ms",
        marginInline: "auto",
        left: 0,
        right: 0,
      }}
    >
      <Button
        loading={loading}
        title={currentPage === 0 ? t("get_ticket") : t("continue")}
        width="100%"
        onClick={async () => {
          if (canClick.current) {
            if (!currentPage) {
              scrollWindowToTop()

              const container = document.getElementById("onboarding-container")
              container?.scrollTo({
                left: window.innerWidth,
                behavior: "auto",
              })
              setCurrentPage(1)

              if (screenReaderEnabled.current) {
                focusElement("onboarding-container")
              }

              canClick.current = false
              setTimeout(() => {
                canClick.current = true
              }, 500)
            } else {
              if (
                timeSlotOption.id !== "select" &&
                consents[0] &&
                consents[1] &&
                consents[2] &&
                getAge(birthDate) >= 18 &&
                firstName &&
                lastName &&
                email &&
                isEmailValid(email)
              ) {
                if (authType === "normal") {
                  setLoading(true)
                  signIn()
                } else {
                  setLoading(true)

                  await sendCode(email)

                  setViewOnboarding(false)
                }
              } else if (shouldSignIn && email && isEmailValid(email)) {
                if (authType === "normal") {
                  setLoading(true)
                  signIn()
                } else {
                  setLoading(true)

                  const result = await sendCode(email, true)

                  if (!result) {
                    setLoading(false)
                    setSignInErrorAlertOpen(true)
                    return
                  }

                  setViewOnboarding(false)
                }
              } else {
                setMissingAlertOpen(true)
              }
            }
          }
        }}
        onFocusVisible={() => {
          screenReaderEnabled.current = true
        }}
        onBlur={() => {
          screenReaderEnabled.current = false
        }}
      >
        {currentPage === 0 ? t("get_ticket") : t("continue")}
      </Button>
    </FadeFromBottom>
  )

  const goBackButton = (
    <FadeFromBottom
      style={{
        width: continueButtonWidth ?? "calc(100% - 32px)",
        position: "absolute",
        bottom: currentPage === 0 && localStorage.getItem("fromApp") ? -56 : 22,
        display: "flex",
        justifyContent: "center",
        transition: "bottom 200ms",
        marginInline: "auto",
        left: 0,
        right: 0,
      }}
    >
      <Button
        disabled={loading}
        title={currentPage === 0 ? t("i_already_have_a_ticket") : t("go_back")}
        outlined
        width="100%"
        onClick={() => {
          if (canClick.current) {
            scrollWindowToTop()

            const container = document.getElementById("onboarding-container")

            if (currentPage === 0) {
              setShouldSignIn(true)
              container?.scrollTo({
                left: window.innerWidth,
                behavior: "auto",
              })
              setCurrentPage(1)
            } else {
              setShouldSignIn(false)
              container?.scrollTo({
                left: 0,
                behavior: "auto",
              })
              setCurrentPage(0)
            }

            if (screenReaderEnabled.current) {
              focusElement("onboarding-container")
            }

            canClick.current = false
            setTimeout(() => {
              canClick.current = true
            }, 500)
          }
        }}
        onFocusVisible={() => {
          screenReaderEnabled.current = true
        }}
        onBlur={() => {
          screenReaderEnabled.current = false
        }}
      >
        {currentPage === 0 ? t("i_already_have_a_ticket") : t("go_back")}
      </Button>
    </FadeFromBottom>
  )

  return (
    <Stack
      id="main-container"
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        overflowY: "scroll",
      }}
    >
      <MetaPixelLead />
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          minHeight:
            currentPage === 0 && localStorage.getItem("fromApp")
              ? 740
              : currentPage === 0
              ? 800
              : shouldSignIn
              ? 520
              : seeMore.some((item) => item)
              ? 1640
              : 1574,
          overflow: "hidden",
          paddingTop: 26,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 0 ? "false" : "true"}
        >
          {/* header */}
          <FadeFromTop>
            <Stack direction="row" alignItems="center" spacing={3}>
              <img
                src={awLogoCentered}
                style={{ height: 35 }}
                alt="Logo AWorld"
              />
              <img
                src={partnerLogoAlt}
                style={{ height: 35 }}
                alt="Logo Giornata della Terra"
              />
              <img
                src={csLogoBlack}
                style={{ height: 45 }}
                alt="Logo Club Silencio"
              />
            </Stack>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {t("onboarding_welcome")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={400}
              component="h2"
              style={{ maxWidth: onboardingElementsMaxWidth }}
            >
              {t("onboarding_subtitle")}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 24 }}>
            <img
              src={introImage}
              style={{ height: 208 }}
              alt={t("onboarding_image_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.2} style={{ marginTop: 52 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              style={{ maxWidth: onboardingElementsMaxWidth }}
            >
              <Trans i18nKey="onboarding_description">
                <strong style={{ fontWeight: 600 }}>
                  Sabato 20 aprile, 09:00 - 00:00
                </strong>
                <br />
                <strong style={{ fontWeight: 600 }}>
                  Giardini Reali e Musei Reali, Torino
                </strong>
                <br />
                <br />
                <strong style={{ fontWeight: 600 }}>Ottieni</strong> qui il
                biglietto gratuito,
                <br />
                <strong style={{ fontWeight: 600 }}>partecipa</strong> alla
                challenge,
                <br />
                <strong style={{ fontWeight: 600 }}>completa</strong> le
                missioni e vinci.
              </Trans>
            </Text>
          </FadeFromTop>
          {/* continue and go back buttons */}
          {continueButton}
          {goBackButton}
        </Stack>
        {/* second page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 16,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 1 ? "false" : "true"}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <img
              src={awLogoCentered}
              style={{ height: 35 }}
              alt="Logo AWorld"
            />
            <img
              src={partnerLogoAlt}
              style={{ height: 35 }}
              alt="Logo Giornata della Terra"
            />
            <img
              src={csLogoBlack}
              style={{ height: 45 }}
              alt="Logo Club Silencio"
            />
          </Stack>
          <Title
            fontSize={isMobile ? 26 : 28}
            textAlign="center"
            style={{ marginTop: 30 }}
            component="h1"
          >
            {t("terms_and_conditions")}
          </Title>
          <Text
            fontSize={isMobile ? 18 : 20}
            textAlign="center"
            fontWeight={400}
            style={{ marginTop: 12 }}
            component="h2"
          >
            <Trans i18nKey="terms_and_conditions_description">
              Inserisci i dati, ottieni il tuo biglietto e accedi all’esperienza
              <strong style={{ fontWeight: 600 }}>Giornata della Terra</strong>
            </Trans>
          </Text>
          <div style={{ width: "100%", maxWidth: onboardingElementsMaxWidth }}>
            <Title fontSize={14} style={{ width: "100%", marginTop: 20 }}>
              *{t("mandatory")}
            </Title>
          </div>
          {shouldSignIn && (
            <Stack
              style={{
                width: "100%",
                maxWidth: onboardingElementsMaxWidth,
                position: "relative",
                marginTop: 20,
                gap: 14,
              }}
            >
              <Input
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value)

                  // error check
                  if (isEmailValid(e.target.value) || !e.target.value) {
                    setEmailError(false)
                  }
                }}
                placeholder={t("email") + "*"}
                disabled={loading}
                error={emailError}
                errorText={t("insert_a_valid_email")}
                onBlur={() => {
                  if (isEmailValid(email) || !email) {
                    setEmailError(false)
                  } else {
                    setEmailError(true)
                  }
                }}
              />
            </Stack>
          )}
          {!shouldSignIn && (
            <Stack
              style={{
                width: "100%",
                maxWidth: onboardingElementsMaxWidth,
                position: "relative",
                marginTop: 20,
                gap: 14,
              }}
            >
              <Input
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                placeholder={t("first_name") + "*"}
                disabled={loading}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("last-name-input")?.focus()
                  }
                }}
              />
              <Input
                id="last-name-input"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
                placeholder={t("last_name") + "*"}
                disabled={loading}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("email-input")?.focus()
                  }
                }}
              />
              <Input
                id="email-input"
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value)

                  // error check
                  if (isEmailValid(e.target.value) || !e.target.value) {
                    setEmailError(false)
                  }
                }}
                placeholder={t("email") + "*"}
                disabled={loading}
                error={emailError}
                errorText={t("insert_a_valid_email")}
                onBlur={() => {
                  if (isEmailValid(email) || !email) {
                    setEmailError(false)
                  } else {
                    setEmailError(true)
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("birth-date-input")?.focus()
                  }
                }}
              />
              <Input
                id="birth-date-input"
                value={birthDate}
                onChange={(e) => {
                  const value = e.target.value

                  if (value.length === 8 && !value.includes("/")) {
                    setBirthDate(
                      value.slice(0, 2) +
                        "/" +
                        value.slice(2, 4) +
                        "/" +
                        value.slice(4)
                    )

                    if (
                      getAge(
                        value.slice(0, 2) +
                          "/" +
                          value.slice(2, 4) +
                          "/" +
                          value.slice(4)
                      ) >= 18
                    ) {
                      setBirthDateError(false)
                    }
                  } else if (value.length <= 10) {
                    setBirthDate(value)

                    if (getAge(value) >= 18) {
                      setBirthDateError(false)
                    }
                  }

                  // error check
                  if (!value) {
                    setBirthDateError(false)
                  }
                }}
                placeholder={t("year_of_birth") + " (GG/MM/AAAA)" + "*"}
                disabled={loading}
                error={birthDateError}
                errorText={t("insert_a_valid_birthdate")}
                onBlur={() => {
                  if (!birthDate || getAge(birthDate) >= 18) {
                    setBirthDateError(false)
                  } else {
                    setBirthDateError(true)
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("birth-date-input")?.blur()
                  }
                }}
              />
              <Text
                fontSize={16}
                lineHeight="22px"
                fontWeight={400}
                style={{ marginTop: 16 }}
              >
                {t("select_time_slot")}*
              </Text>
              <SelectBigComplex
                options={[
                  {
                    id: "select",
                    label: t("select"),
                    disabled: false,
                  },
                  ...eventSlots
                    .filter((item) => item.privata === "0")
                    .map((item) => {
                      return {
                        id: item.id,
                        label:
                          item.ora_inizio.slice(0, 5) +
                          " - " +
                          item.ora_fine.slice(0, 5) +
                          " | " +
                          item.descrizione,
                        disabled:
                          item.abilitato === "1" &&
                          item.capienza >= 0 &&
                          item.privata === "0"
                            ? false
                            : true,
                      }
                    }),
                ]}
                selectedOption={timeSlotOption}
                setSelectedOption={setTimeSlotOption}
                width="100%"
                paperMinWidth={330}
                disabled={loading}
              />
              <ButtonBase
                disabled={loading}
                disableRipple
                style={{
                  width: "fit-content",
                  height: 22,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                aria-label={t("why_do_i_have_slots")}
                onClick={() => {
                  setTimeSlotsAlertOpen(true)
                }}
              >
                <img
                  src={infoIconPrimary}
                  style={{ width: 16, height: 16 }}
                  alt=""
                />
                <Text
                  fontSize={14}
                  lineHeight="16px"
                  fontWeight={700}
                  style={{ marginLeft: 5 }}
                  color={colors.primary}
                >
                  {t("why_do_i_have_slots")}
                </Text>
              </ButtonBase>
              <Text
                fontSize={16}
                lineHeight="22px"
                fontWeight={400}
                style={{ marginTop: 10 }}
              >
                {t("select_minor_tickets")}
              </Text>
              <SelectBig
                options={["0", "1", "2", "3", "4"]}
                selectedOption={minorTicketsOption}
                setSelectedOption={setMinorTicketsOption}
                width="100%"
                paperMinWidth={144}
                disabled={loading}
              />
              <ButtonBase
                disabled={loading}
                disableRipple
                style={{
                  width: "fit-content",
                  height: 22,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                aria-label={t("why_we_ask")}
                onClick={() => {
                  setMinorAlertOpen(true)
                }}
              >
                <img
                  src={infoIconPrimary}
                  style={{ width: 16, height: 16 }}
                  alt=""
                />
                <Text
                  fontSize={14}
                  lineHeight="16px"
                  fontWeight={700}
                  style={{ marginLeft: 5 }}
                  color={colors.primary}
                >
                  {t("why_we_ask")}
                </Text>
              </ButtonBase>
            </Stack>
          )}
          {/* consents */}
          {!shouldSignIn && (
            <Stack
              style={{
                width: "100%",
                maxWidth: onboardingElementsMaxWidth,
                gap: 12,
                marginTop: 24,
              }}
            >
              <ConsentCard
                title="Termini e condizioni*"
                description={
                  <span>
                    Dichiaro di aver letto e compreso i{" "}
                    <strong
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setTermsAlertOpen(true)
                      }}
                      style={{
                        color: colors.primary,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      termini e condizioni
                    </strong>
                  </span>
                }
                checked={consents[0]}
                onChange={() => {
                  consents[0] = !consents[0]
                  setConsents([...consents])
                }}
                disabled={loading}
              />
              <ConsentCard
                title={t("privacy_policy_heading") + "*"}
                description={
                  <Trans i18nKey="privacy_policy_read">
                    Dichiaro di aver letto e compreso l’
                    <strong
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setGdtPrivacyAlertOpen(true)
                      }}
                      style={{
                        color: colors.primary,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      informativa privacy
                    </strong>
                  </Trans>
                }
                checked={consents[1]}
                onChange={() => {
                  consents[1] = !consents[1]
                  setConsents([...consents])
                }}
                disabled={loading}
              />
              <ConsentCard
                title="Regolamento concorso*"
                description={
                  <span>
                    Dichiaro di aver letto e compreso il{" "}
                    <strong
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setContestAlertOpen(true)
                      }}
                      style={{
                        color: colors.primary,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      regolamento del concorso
                    </strong>{" "}
                    e che la partecipazione allo stesso prevede la creazione di
                    un account AWorld e la presa visione dell'
                    <strong
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setAwPrivacyAlertOpen(true)
                      }}
                      style={{
                        color: colors.primary,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      informativa privacy
                    </strong>
                  </span>
                }
                checked={consents[2]}
                onChange={() => {
                  consents[2] = !consents[2]
                  setConsents([...consents])
                }}
                disabled={loading}
              />
              {/* newsletter */}
              <Stack
                style={{
                  width: "100%",
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingInline: 10,
                  borderRadius: 16,
                  boxShadow: defaultBoxShadow,
                }}
              >
                <Title fontSize={18} lineHeight="25px" fontWeight={600}>
                  {t("newsletter")}
                </Title>
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "100%",
                    minHeight: 36,
                    marginTop: 8,
                    position: "relative",
                  }}
                >
                  <Title
                    fontSize={14}
                    lineHeight="18px"
                    color={colors.primary}
                    style={{ width: "calc(100% - 100px)" }}
                  >
                    {t("aworld_authorization")}
                  </Title>
                  <Switch
                    checked={consents[3]}
                    onChange={() => {
                      consents[3] = !consents[3]
                      setConsents([...consents])
                    }}
                    disabled={loading}
                    style={{ position: "absolute", right: 6 }}
                  />
                </Stack>
                {seeMore[0] && (
                  <Text
                    fontSize={12}
                    lineHeight="14px"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    Autorizzo al trattamento dei miei dati per ricevere
                    Newsletter e comunicazioni di{" "}
                    <strong>Aworld S.r.l. Società Benefit</strong> e ricevere
                    informazioni di attività ed eventi organizzati dalla stessa.
                  </Text>
                )}
                <ButtonBase
                  style={{ width: "fit-content", marginTop: 10 }}
                  disableRipple
                  onClick={() => {
                    if (seeMore[0]) {
                      setSeeMore([false, false, false, false])
                    } else {
                      setSeeMore([true, false, false, false])
                    }
                  }}
                >
                  <Text
                    fontSize={12}
                    lineHeight="14px"
                    fontWeight={400}
                    style={{ textDecoration: "underline" }}
                  >
                    {seeMore[0] ? t("see_less") : t("see_more")}
                  </Text>
                </ButtonBase>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#F2F3F4",
                    marginTop: 12,
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "100%",
                    minHeight: 36,
                    marginTop: 12,
                    position: "relative",
                  }}
                >
                  <Title
                    fontSize={14}
                    lineHeight="18px"
                    color={colors.primary}
                    style={{ width: "calc(100% - 100px)" }}
                  >
                    {t("partner_authorization")}
                  </Title>
                  <Switch
                    checked={consents[4]}
                    onChange={() => {
                      consents[4] = !consents[4]
                      setConsents([...consents])
                    }}
                    disabled={loading}
                    style={{ position: "absolute", right: 6 }}
                  />
                </Stack>
                {seeMore[1] && (
                  <Text
                    fontSize={12}
                    lineHeight="14px"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    Autorizzo al trattamento dei miei dati per ricevere
                    Newsletter e comunicazioni di{" "}
                    <strong>Associazione Culturale Club Silencio</strong> e
                    ricevere informazioni di attività ed eventi organizzati
                    dalla stessa.
                  </Text>
                )}
                <ButtonBase
                  style={{ width: "fit-content", marginTop: 10 }}
                  disableRipple
                  onClick={() => {
                    if (seeMore[1]) {
                      setSeeMore([false, false, false, false])
                    } else {
                      setSeeMore([false, true, false, false])
                    }
                  }}
                >
                  <Text
                    fontSize={12}
                    lineHeight="14px"
                    fontWeight={400}
                    style={{ textDecoration: "underline" }}
                  >
                    {seeMore[1] ? t("see_less") : t("see_more")}
                  </Text>
                </ButtonBase>
              </Stack>
            </Stack>
          )}
          {/* continue and go back buttons */}
          {continueButton}
          {goBackButton}
        </Stack>
      </Stack>
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("something_is_missing")}
        description={t("consents_warning")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
      <Alert
        open={signInErrorAlertOpen}
        title={t("warning")}
        description={t("sign_in_warning")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setSignInErrorAlertOpen(false)
        }}
      />
      {/* full screen alerts for terms and privacy */}
      <AlertFullScreen
        open={termsAlertOpen}
        setOpen={setTermsAlertOpen}
        content={termsAndConditionsIT}
      />
      <AlertFullScreen
        open={gtdPrivacyAlertOpen}
        setOpen={setGdtPrivacyAlertOpen}
        content={gdtPrivacyPolicyIT}
      />
      <AlertFullScreen
        open={awPrivacyAlertOpen}
        setOpen={setAwPrivacyAlertOpen}
        content={awPrivacyPolicyIT}
      />
      <AlertFullScreen
        open={contestAlertOpen}
        setOpen={setContestAlertOpen}
        content={contestRulesIT}
      />
      <AlertInfo
        open={timeSlotsAlertOpen}
        setOpen={setTimeSlotsAlertOpen}
        title={t("why_do_i_have_slots")}
        description={t("time_slots_description")}
        descriptionAlign="center"
      />
      <AlertInfo
        open={minorAlertOpen}
        setOpen={setMinorAlertOpen}
        title={t("why_we_ask")}
        description={t("minor_user_alert_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default OnboardingMobile
